import {
  createColumnHelper,
  FilterFns,
  SortingFn,
} from '@tanstack/react-table';
import React from 'react';
import paths from 'routes/paths';

import { BookingStatus, ICenter, ITableBooking, ITest } from '../../../types';
import { Content, Details, Status } from '../DataGrid.styled';
import { getClassnameByStatus } from './Booking.helpers';
import { sortByName } from '../DataGrid.helpers';
import { IUserInfo } from '../Users/Users.types';

const columnHelper = createColumnHelper<ITableBooking>();

export const columns = (
  openDialog: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    bookingID: string,
    status: BookingStatus,
  ) => void,
) => [
  columnHelper.accessor('code', {
    header: 'Internal ID',
    cell: props => <Content>{props.getValue()}</Content>,
    filterFn: 'withCode' as keyof FilterFns,
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: props => {
      const value = props.getValue() as BookingStatus;
      const classname = getClassnameByStatus(value);
      const isClickable =
        value === BookingStatus.Pending || value === BookingStatus.ToCheck;
      return isClickable ? (
        <Status
          label={value}
          className={classname}
          onClick={e => openDialog(e, props.row.original.bookingID, value)}
        />
      ) : (
        <Status label={value} className={classname} />
      );
    },
    filterFn: 'withStatus' as keyof FilterFns,
    sortingFn: 'sortByStatus' as keyof SortingFn<ITableBooking>,
  }),

  columnHelper.accessor('bookingDate', {
    header: 'Data prenotazione',
    cell: props => (
      <Content className="AltFontFamily white-space">
        {props.getValue()}
      </Content>
    ),
    filterFn: 'includesString',
    sortingFn: 'datetime',
  }),

  columnHelper.accessor('bookingID', {
    header: 'Booking ID',
    cell: props => {
      return (
        <Details
          to={`/${paths({ id: props.getValue() }).bookingID}`}
          state={{ ...props.row.original, initial: 0 }}
        >
          {`...${props.getValue().slice(16)}`}
        </Details>
      );
    },
    filterFn: 'includesString',
  }),

  columnHelper.accessor('user', {
    header: 'Email',
    cell: props => <Content>{props.getValue().email}</Content>,
    filterFn: 'withEmail' as keyof FilterFns,
  }),

  columnHelper.accessor('test', {
    header: 'Prestazione',
    cell: props => <Content>{props.getValue().name}</Content>,
    filterFn: 'withAppointment' as keyof FilterFns,
    sortingFn: 'sortByTest' as keyof SortingFn<ITest>,
  }),

  columnHelper.accessor('patient', {
    header: 'Paziente',
    cell: props => {
      const patientId = props.getValue().id;
      const patientName = `${props.getValue().firstName} ${
        props.getValue().lastName
      }`;
      return (
        <Details
          to={`/${paths({ id: patientId }).patient}`}
          state={{ ...props.row.original, initial: 1 }}
        >
          {patientName}
        </Details>
      );
    },
    filterFn: 'withPatient' as keyof FilterFns,
    sortingFn: sortByName('patient') as keyof SortingFn<IUserInfo>,
  }),

  columnHelper.accessor('reservationDate', {
    header: 'Data prestazione',
    cell: props => (
      <Content className="AltFontFamily">{props.getValue()}</Content>
    ),
    filterFn: 'includesString',
    sortingFn: 'datetime',
  }),

  columnHelper.accessor('center', {
    header: 'Centro medico',
    cell: props => (
      <Content className="AltFontFamily">{props.getValue().name}</Content>
    ),
    filterFn: 'withCenter' as keyof FilterFns,
    sortingFn: 'sortByCenter' as keyof SortingFn<ICenter>,
  }),
];
