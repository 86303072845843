import gql from 'graphql-tag';

const FIND_TESTS = gql`
  query FindTests($search: String) {
    findTests(search: $search) {
      id
      name
      question
      answers {
        title
        service
      }
      prescription
    }
  }
`;

const FIND_MOST_POPULAR_TESTS = gql`
  query FindMostPopularTests {
    findMostPopularTests {
      id
      name
      question
      answers {
        title
        service
      }
      prescription
    }
  }
`;

const FIND_CITIES = gql`
  query findCities($name: String!) {
    findCities(name: $name) {
      id
      name
    }
  }
`;

const FIND_LOGOS = gql`
  query FindChains {
    findChains {
      id
      name
      label
      logo
      logoBig
      enabled
    }
  }
`;

const FIND_TEST_BY_ID = gql`
  query findTestById($id: ID!) {
    findTestById(id: $id) {
      id
      name
    }
  }
`;

const GET_STAFF_FILTER_ENABLED_TESTS = gql`
  query FindSeoTypologyServices($typology: String!) {
    findSeoTypologyServices(typology: $typology) {
      id
    }
  }
`;

const schemas = {
  FIND_TESTS,
  FIND_MOST_POPULAR_TESTS,
  FIND_CITIES,
  FIND_LOGOS,
  FIND_TEST_BY_ID,
  GET_STAFF_FILTER_ENABLED_TESTS,
};

export default schemas;
