import { Skeleton } from '@mui/material';
import React from 'react';

export const DataGridSkeletons = () => {
  return (
    <>
      <Skeleton height={80} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
      <Skeleton height={40} width="100%" />
    </>
  );
};
