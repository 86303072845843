import { Grid, Typography } from '@mui/material';
import React from 'react';

import { DetailsSkeletons } from '../../../components/DetailsSkeletons/DetailsSkeletons.component';
import { ReactComponent as Chevron } from '../../../icons/link-chevron.svg';
import paths from '../../../routes/paths';
import { getFullName } from '../../../utils/getFullName';
import { StyledLink } from '../../BookingDetails/BookingDetails.styled';
import { usePatient } from '../Patient.helpers';
import Feature from '../../../components/Feature';

export const PatientInfo = () => {
  const { loading, patient, id } = usePatient();

  if (loading || !patient) {
    return <DetailsSkeletons />;
  }

  return (
    <Grid container>
      <Grid item xs={6} sx={{ pb: '32px' }}>
        <Typography variant="h3" sx={{ mb: '18px' }}>
          Info utente
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          ID
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {id}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Nome e Cognome
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {`${patient.firstName} ${patient.lastName}`}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Data di nascita
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.birthDate}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Codice Fiscale
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {!!patient.fiscalCode ? patient.fiscalCode : '---'}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Genere
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.gender}
        </Typography>

        <Feature on={false}>
          <Typography variant="subtitle1" sx={{ mb: '6px' }}>
            Utente associato
          </Typography>
          <Typography variant="body1" sx={{ mb: '18px' }}>
            {!!patient.user
              ? getFullName(patient.user.firstName, patient.user.lastName)
              : '---'}
          </Typography>
        </Feature>

        <Feature on={false}>
          {!!patient.user && (
            <Grid item xs={12} sx={{ pt: '32px' }}>
              <Typography variant="h3" sx={{ mb: '8px' }}>
                Dettagli utente associato
              </Typography>
              <StyledLink to={`/${paths({ id: patient.user.id }).user}`}>
                Vedi scheda completa in anagrafica utente
                <Chevron style={{ marginLeft: '7px' }} />
              </StyledLink>
            </Grid>
          )}
        </Feature>
      </Grid>
    </Grid>
  );
};
