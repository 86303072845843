export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'OC2_ACCESS_TOKEN',
  REFRESH_TOKEN: 'OC2_REFRESH_TOKEN',
  CONFIRMATION: 'CONFIRMATION_MODAL',
};

export const PRIMA_POSSIBILE_DATE = 'Prima possibile';

export const THRESHOLDS = { morning: 1259, afternoon: 1759, evening: 2100 };

export const CDI_CHAIN_LABEL = 'centro-diagnostico-italiano';
