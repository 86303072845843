import React, { useEffect } from 'react';
import { ArrowDropDown, Close } from '@mui/icons-material';

import { TableTransparentButton } from './DataGrid.styled';
import {
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { ReactComponent as Checked } from '../../icons/checkboxes/checked.svg';
import { ReactComponent as Unchecked } from '../../icons/checkboxes/not-checked.svg';
import { Column } from '@tanstack/react-table';
import { DropdownFilterBox } from './DropdownFilter.styled';
import { BookingStatus } from '../../types';

interface DropdownFilterProps<T, OptionsType> {
  title: string;
  options: OptionsType[];
  columnInstance: Column<T>;
}

enum ColumnInstanceIDs {
  STATUS = 'STATUS',
  CENTER = 'CENTER',
}

function getPreselectedOptions<T, OptionsType>(
  options: OptionsType[],
  columnInstance: Column<T>,
) {
  const { id } = columnInstance;

  if (id.toLowerCase() === ColumnInstanceIDs.CENTER.toLowerCase()) return [];

  return options.filter(
    (option: OptionsType) => option !== BookingStatus.PaymentFailed,
  );
}

export function DropdownFilter<T, OptionsType>({
  title,
  options,
  columnInstance,
}: DropdownFilterProps<T, OptionsType>) {
  const [selectedOptions, setSelectedOptions] = React.useState<OptionsType[]>(
    getPreselectedOptions<T, OptionsType>(options, columnInstance),
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function closeMenu() {
    setAnchorEl(null);
  }
  function resetOptions() {
    setSelectedOptions([]);
  }
  function onMenuItemClick(option: OptionsType) {
    setSelectedOptions(prev => {
      return prev.includes(option)
        ? prev.filter(el => el !== option)
        : [...prev, option];
    });
  }

  useEffect(() => {
    columnInstance.setFilterValue(selectedOptions.join(','));
  }, [selectedOptions]);

  return (
    <DropdownFilterBox>
      <TableTransparentButton
        onClick={openMenu}
        endIcon={<ArrowDropDown />}
        variant="outlined"
        disabled={!options.length}
        isSmall
        isJustifyBetween
        fullWidth
      >
        {title}
      </TableTransparentButton>
      {!!selectedOptions.length && (
        <Tooltip title={`Reset ${title.toLowerCase()}`}>
          <IconButton size="small" onClick={resetOptions}>
            <Close color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {!!options.length && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={closeMenu}
          PaperProps={{ style: { maxHeight: '400px' } }}
        >
          {options.map(option => {
            return (
              <MenuItem
                onClick={() => onMenuItemClick(option)}
                dense
                key={option as string}
              >
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  disableRipple
                  icon={<Unchecked />}
                  checkedIcon={<Checked />}
                />
                <ListItemText primary={<>{option}</>} />
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </DropdownFilterBox>
  );
}
