import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const PatientID = styled(Link, { label: 'PatientID' })`
  display: block;
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  color: #00838f;
`;
