import { gql } from '@apollo/client';
import {
  CHAIN_FRAGMENT_GQL,
  PATIENT_FRAGMENT_GQL,
  TEST_FRAGMENT_GQL,
  USER_FRAGMENT_GQL,
} from '../../graphql/fragments';

export const FIND_PATIENT_BY_ID = gql`
  ${PATIENT_FRAGMENT_GQL}
  ${USER_FRAGMENT_GQL}
  query FindPatientById($id: ID!) {
    findPatientById(id: $id) {
      ...PatientFragment
      user {
        ...UserFragment
      }
    }
  }
`;

export const FIND_PATIENT_BOOKINGS = gql`
  ${PATIENT_FRAGMENT_GQL}
  ${USER_FRAGMENT_GQL}
  ${CHAIN_FRAGMENT_GQL}
  ${TEST_FRAGMENT_GQL}
  query FindPatientBookings($patient: ID!) {
    findPatientBookings(patient: $patient) {
      id
      code
      patient {
        ...PatientFragment
      }
      patientId
      user {
        ...UserFragment
      }
      chainId
      chain {
        ...ChainFragment
      }
      date
      reservationDate
      time
      doctor
      centerId
      center {
        id
        chain {
          ...ChainFragment
        }
        name
        address
        enabled
        region {
          id
          name
          label
        }
        rating
        label
        location {
          coordinates
        }
      }
      testId
      test {
        ...TestFragment
      }
      status
      price
      feedback
      paymentType
      paymentDate
    }
  }
`;
