import { Skeleton } from '@mui/material';
import React from 'react';

import { DetailSkeletonsBox } from './DetailsSkeletons.styled';

export const DetailsSkeletons = () => {
  return (
    <DetailSkeletonsBox>
      <Skeleton height={300} />
      <Skeleton height={300} />
      <Skeleton height={300} />
      <Skeleton height={300} />
    </DetailSkeletonsBox>
  );
};
