import { styled } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

export const MuiDivider = styled(Divider)({
  height: 'auto',
  background: '#cfd8dc',
  '&.with-margin': {
    margin: '0 24px',
  },
});

export const MuiStack = {
  Primary: styled((props: StackProps) => (
    <Stack
      {...props}
      spacing={0}
      divider={<MuiDivider className={'with-margin'} />}
    >
      {props.children}
    </Stack>
  ))(({ theme }) => ({
    display: 'block !important',
  })),
};
