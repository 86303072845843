import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const BookingCardsBox = styled('div', { label: 'BookingCardsBox' })``;
export const BookingCardsTitle = styled('h6', { label: 'BookingCardsTitle' })`
  font-weight: 800;
  font-size: 14px;
  color: #263238;
  margin: 36px 0 12px;
`;
export const BookingCardBox = styled('div', { label: 'BookingCardBox' })`
  background-color: #f4f8fb;
  border-radius: 20px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  max-width: 730px;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;
export const BookingCardInfo = styled('div', { label: 'BookingCardInfo' })`
  display: flex;
  flex-direction: column;
`;
export const BookingCardCanceled = styled('p', {
  label: 'BookingCardCanceled',
})`
  font-weight: 600;
  font-size: 12px;
  color: #eab308;
`;
export const BookingCardTest = styled('p', { label: 'BookingCardTest' })<{
  isPast?: boolean;
  isCanceled?: boolean;
}>`
  font-weight: 800;
  font-size: 16px;
  color: ${({ isPast }) => (isPast ? '#78909C' : '#263238')};
  margin: 0;
  ${({ isCanceled }) =>
    isCanceled &&
    css`
      text-decoration: line-through;
    `}
`;
export const BookingCardPlace = styled('div', { label: 'BookingCardPlace' })<{
  isPast?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 12px 0 9px;
  color: ${({ isPast }) => (isPast ? '#78909C' : '#263238')};
`;
export const BookingCardTime = styled('div', { label: 'BookingCardTime' })<{
  isPast?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ isPast }) => (isPast ? '#78909C' : '#263238')};
`;
export const BookingCardDoctor = styled('div', { label: 'BookingCardDoctor' })<{
  isPast?: boolean;
}>`
  color: ${({ isPast }) => (isPast ? '#78909C' : '#263238')};
`;
export const BookingCardLink = styled(Link, { label: 'BookingCardLink' })`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: auto;
  font-weight: 800;
  color: #00838f;
  max-width: max-content;
  align-self: flex-end;
`;
