import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Chip as MuiChip,
  Typography,
  TextField,
  InputAdornment,
  Autocomplete,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import { Link } from 'react-router-dom';

import { ReactComponent as Cross } from 'icons/adornments/cross.svg';
import { ReactComponent as Calendar } from 'icons/adornments/calendar.svg';
import { ReactComponent as Clear } from 'icons/clear.svg';

export const DetailDivider = styled('div', { label: 'DetailDivider' })`
  width: 50%;
  height: 1px;
  background-color: #598298ab;
`;
export const Control = styled(Button, { label: 'Control' })<{
  fullWidth?: boolean;
}>`
  padding: 10px 18px;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.5;
  text-transform: none;
  color: #fff;
  background-color: #d81b60;
  border-radius: 16px;
  transition: 0.3s;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  &:hover {
    background-color: #d81b60;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: inherit;
    color: #ffffff;
    background-color: #90a4ae;
  }

  &.Inverse {
    padding: 10px 32px;
    color: #121a26;
    background-color: #fff;
    border: 1px solid #b0bec5;

    &:hover {
      background-color: #e7e7e7;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: inherit;
      color: #ffffff;
      background-color: #90a4ae;
    }
  }

  &.save-changes-btn {
    margin-top: auto;
    width: 276px;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #00838f;
  max-width: max-content;
`;

export const Flexed = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
});

export const Message = styled(Typography)({
  fontFamily: "'Roboto Condensed', sans-serif",
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: 1.4,
  letterSpacing: '0.3px',
  color: '#00838F',
});

export const Chip = styled(MuiChip)({
  height: '20px',
  '& > .MuiChip-label': {
    paddingLeft: '10px',
    paddingRight: '10px',
    fontFamily: "'Lato', sans-serif",
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: 1.7,
    color: '#000000',
    textTransform: 'capitalize',
  },
  '&.success': {
    background: '#A2FFC4',
  },
  '&.error': {
    background: '#ff8a8a',
  },
});

export const ModalContent = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '526px',
  height: '578px',

  padding: '24px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',

  background: '#ffffff',
  borderRadius: '20px',
});

export const ModalTitle = styled(Typography)({
  marginBottom: '56px',

  fontWeight: 800,
  fontSize: '18px',
  lineHeight: 1.35,
  letterSpacing: '0.2px',
  color: '#263238',
});

export const ModalCloseControl = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <Cross />
  </IconButton>
))({
  position: 'absolute',
  top: '16px',
  right: '16px',
});

export const MuiStyledInput = styled(TextField)({
  width: '100%',

  '& .MuiOutlinedInput-root': {
    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#263238',
    border: '1px solid #263238',
    borderRadius: '16px',
    '& .MuiOutlinedInput-input': {
      padding: '13px 5px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

  '&.without-autocomplete .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-input': {
      padding: '22px 14px',
    },
  },

  '&.mb-36': {
    marginBottom: '36px',
  },
});

export const ClearInputValueButton = styled((props: IconButtonProps) => (
  <IconButton {...props}>
    <Clear />
  </IconButton>
))({
  width: '24px',
  height: '24px',
  background: '#CFD8DC',
  '& > svg': {
    flexShrink: 0,
  },
});

export const BookingDetailsAutocomplete = styled(Autocomplete)({
  marginBottom: '36px',
  '& .MuiOutlinedInput-root': {
    paddingTop: '14px',
    paddingBottom: '14px',

    fontFamily: 'inherit',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#263238',
    border: '1px solid #263238',
    borderRadius: '16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}) as typeof Autocomplete;

export const BookingDetailsOptionsWrapper = styled((props: PaperProps) => (
  <Paper {...props} />
))({
  border: '1px solid #121A26',
  borderRadius: '20px',
});

export const BookingDetailsOptionsPopper = styled((props: PopperProps) => (
  <Popper
    {...props}
    modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
  />
))({});

export const BookingDateControl = styled((props: any) => (
  <MuiStyledInput
    {...props}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Calendar />
        </InputAdornment>
      ),
      ...(props.value?.length > 0
        ? {
            endAdornment: (
              <InputAdornment position="end">
                <ClearInputValueButton onClick={() => props.clearValue?.()} />
              </InputAdornment>
            ),
          }
        : {}),
    }}
  />
))({});

export const BookingDetailsOptionButton = styled(ListItemButton)({});

export const BookingDetailsOptionText = styled(ListItemText)({
  '& .MuiTypography-root': {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#263238',
  },
});

export const BookingDetailsOptionIcon = styled(ListItemIcon)({
  minWidth: 'auto',
});

export const BookingDetailsOptionsDivider = styled(Divider)({
  marginLeft: '10px',
  marginRight: '10px',
  background: '#CFD8DC',
});
