import { Grid, Typography } from '@mui/material';
import React from 'react';

import { DetailsSkeletons } from '../../../components/DetailsSkeletons/DetailsSkeletons.component';
import { ReactComponent as Chevron } from '../../../icons/link-chevron.svg';
import paths from '../../../routes/paths';
import { useBooking } from '../BookingDetails.helpers';
import { DetailDivider, StyledLink } from '../BookingDetails.styled';

export const Patient = () => {
  const { loading, booking } = useBooking();
  const patient = booking?.patient;

  if (loading || !patient) {
    return <DetailsSkeletons />;
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ pb: '32px' }}>
        <Typography variant="h3" sx={{ mb: '18px' }}>
          Dati anagrafici
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          ID
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.id}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Nome
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.firstName}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Cognome
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.lastName}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Data di nascita
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.birthDate}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Indirizzo di residenza
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.residenceAddress}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Codice Fiscale
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.fiscalCode ?? '---'}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Città di nascita
        </Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {patient.birthPlace ?? '---'}
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: '6px' }}>
          Genere
        </Typography>
        <Typography variant="body1">{patient.gender}</Typography>
      </Grid>

      <DetailDivider />

      <Grid item xs={12} sx={{ pt: '32px' }}>
        <Typography variant="h3" sx={{ mb: '8px' }}>
          Dettagli e Prenotazioni
        </Typography>
        <StyledLink to={`/${paths({ id: patient.id }).patient}`}>
          Vedi scheda completa in anagrafica paziente
          <Chevron style={{ marginLeft: '7px' }} />
        </StyledLink>
      </Grid>
    </Grid>
  );
};
