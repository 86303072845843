import React, { useEffect } from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import Styled from 'components/Styled';
import Logo from 'components/Logo';
import User from 'components/User';
import Tabs from 'components/Tabs';

import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { authOperations } from '../redux/slices/auth/operations';
import { authSelectors } from '../redux/slices/auth/selectors';
import { userOperations } from '../redux/slices/user/operations';

import paths from '../routes/paths';

const Aside = styled(Drawer)({
  '& .MuiPaper-root': {
    position: 'static',
    width: '300px',
    paddingTop: '24px',
    paddingBottom: '24px',
    background: '#F4F8FB',
    borderRight: '1px solid #B0BEC5',
    height: '125%',
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
});

const Wrapper = styled(Box)({
  paddingLeft: '24px',
  paddingRight: '24px',
});

const ResponsiveWrapper = styled(Wrapper)({
  marginTop: '26px',
  marginBottom: 'auto',
});

const FullWidthLink = styled(Styled.Link)({ display: 'block', width: '100%' });

const Flexed = styled(Box)({
  minHeight: '125vh',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'stretch',
});

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useAppSelector(authSelectors.selectIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(userOperations.getUser());
  }, [dispatch, isLoggedIn]);

  async function logout() {
    const { payload: result } = await dispatch(authOperations.logout());
    if (result) navigate('/auth');
  }

  return (
    <>
      <Flexed>
        <Aside variant="permanent">
          <Wrapper sx={{ mb: '30px' }}>
            <Link to={paths().search}>
              <img src="/personal-doctor-logo.png" alt="" width="200" />
            </Link>
          </Wrapper>
          <Tabs />
          <Wrapper>
            <Divider sx={{ mt: '22px', mb: '46px' }} />
          </Wrapper>
          <Wrapper>
            <User />
          </Wrapper>
          <ResponsiveWrapper>
            <Styled.Link component="button" onClick={logout}>
              Logout
            </Styled.Link>
          </ResponsiveWrapper>
          <Wrapper sx={{ mt: '26px', mb: '26px' }}>
            <Logo />
          </Wrapper>
          <Wrapper>
            <List>
              <ListItem sx={{ mb: '26px' }}>
                <FullWidthLink href="#">Aiuto e FAQ</FullWidthLink>
              </ListItem>
              <ListItem>
                <FullWidthLink href="#">Termini e condizioni</FullWidthLink>
              </ListItem>
            </List>
          </Wrapper>
        </Aside>
        <Outlet />
      </Flexed>
    </>
  );
};

export default Sidebar;
