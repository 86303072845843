import { Skeleton } from '@mui/material';
import React from 'react';

export const BookingCardsSkeletons = () => {
  return (
    <>
      <Skeleton width="20%" height={40} />
      <Skeleton width="80%" height={125} />
      <Skeleton width="80%" height={125} />
      <Skeleton width="20%" height={40} />
      <Skeleton width="80%" height={125} />
      <Skeleton width="80%" height={125} />
    </>
  );
};
