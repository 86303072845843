import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import MuiContainer from '@mui/material/Container';
import MuiBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { BookNow } from '../components/BookNow/BookNow';
import { useBookNowModalMobile } from '../components/BookNowModalMobile/useBookNowModalMobile';

const Search = () => {
  const {
    bookNowModal,
    setBookNowModal,
    test,
    setTest,
    testID,
    setTestID,
    answers,
    setAnswers,
    date,
    setDate,
    suggested,
    setSuggested,
    place,
    setPlace,
    hasPrescription,
    setHasPrescription,
  } = useBookNowModalMobile();

  const bookNowRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Helmet>
        <title>Nuova ricerca</title>
      </Helmet>
      <MuiContainer maxWidth={'lg'}>
        <MuiBox sx={{ pt: '34px', pb: '34px' }}>
          <Typography variant="h1">
            Prenota un nuovo esame, visita o trattamento
          </Typography>
        </MuiBox>
        <BookNow
          ref={bookNowRef}
          modal={bookNowModal}
          setModal={setBookNowModal}
          testID={testID}
          setTestID={setTestID}
          answers={answers}
          setAnswers={setAnswers}
          suggested={suggested}
          setSuggested={setSuggested}
          test={test}
          setTest={setTest}
          place={place}
          setPlace={setPlace}
          date={date}
          setDate={setDate}
          withTitleAndBg={false}
          hasPrescription={hasPrescription}
          setHasPrescription={setHasPrescription}
        />
      </MuiContainer>
    </>
  );
};

export default Search;
