import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';

import opener from '../../utils/opener';
import { IProps } from './Redirect.types';

const Unavailable = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  width: '327px',
  padding: '24px',
  background: '#FFFFFF',
  border: '1px solid #B0BEC5',
  borderRadius: '20px',
});

const Title = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 800,
  fontSize: '18px',
  lineHeight: 1.4,
  letterSpacing: '0.2px',
  color: '#263238',
});

const Body1 = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  color: '#607D8B',
});

const Actions = styled(List)({ margin: 0, padding: 0, display: 'block' });
const Action = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    margin: 0,
  },
  padding: 0,
  '&:first-of-type': {
    marginBottom: '12px',
  },
}));

const Link = styled(Button)({
  width: '100%',
  justifyContent: 'center',
  paddingTop: '16px',
  paddingBottom: '16px',
  fontFamily: "'Manrope', sans-serif",
  fontWeight: 800,
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  textTransform: 'none',
  color: '#FFFFFF',
  background: '#D81B60',
  borderRadius: '16px',
  '&:hover': {
    background: '#D81B60',
  },
});

const Close = styled(Link)({
  color: '#121A26',
  background: '#FFFFFF',
  border: '1px solid #B0BEC5',
  '&:hover': {
    background: '#FFFFFF',
  },
});

const Redirect = (props: IProps) => {
  const { open, onClose, href = '#' } = props;
  return (
    // <Modal open={open} onClose={onClose}>
    <Unavailable>
      <Title>{'Questo Centro non è ancora disponibile su Onecheck'}</Title>
      <Body1>
        {'Puoi continuare la prenotazione direttamente sul sito del centro'}
      </Body1>
      <Actions>
        <Action>
          <Link
            onClick={() => {
              opener(href, 'center');
            }}
          >
            {'Prosegui su sito centro'}
          </Link>
        </Action>
        <Action>
          <Close onClick={onClose}>{'Torna indietro'}</Close>
        </Action>
      </Actions>
    </Unavailable>
    // </Modal>
  );
};

export default Redirect;
