import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const MuiBox = styled(Box)({
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#E0F7FA',
  borderRadius: '20px',
});

export const MuiTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#263238',
  textAlign: 'center',
  '&.no-centers': {
    marginBottom: '10px',
  },
});

// FIXME: use CssBaseline or a global theme override
export const MuiList = styled(List)({ margin: 0, padding: 0 });
export const MuiListItem = styled(ListItem)({
  margin: 0,
  padding: 0,
  '&.not-soonest': {
    marginBottom: '10px',
  },
});

export const MuiButton = styled(Button)({
  paddingTop: '7px',
  paddingBottom: '7px',
  fontFamily: 'inherit',
  fontWeight: 800,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#00838F',
  textTransform: 'none',
  background: 'transparent',
  border: '1px solid #B0BEC5',
  borderRadius: '8px',
  '&.full-width': {
    width: '100%',
  },
});
