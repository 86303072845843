const COUNTRY = 'it';
const TYPES: string[] = ['route'];

export const requestOptions = {
  types: TYPES,
  componentRestrictions: { country: COUNTRY },
};

export const DEBOUNCE = 300;
export const OK = 'OK';
export const EMPTY = 'ZERO_RESULTS';

export const ID = 'input-type-search';

export const FIELD_NAME = 'residenceAddress';

export const ALT_STYLES_PAGES = ['/profile-update', '/patient'];
