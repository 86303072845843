import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactComponent as OneCheck } from 'icons/one-check.svg';

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
});

const Pro = styled(Typography)({
  marginLeft: '10px',
  fontWeight: 800,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  color: '#000000',
  textTransform: 'uppercase',
});

const Logo = ({ styles = {} }: { styles?: {} }) => {
  return (
    <Wrapper sx={styles}>
      <OneCheck />
      <Pro>pro</Pro>
    </Wrapper>
  );
};

export default Logo;
