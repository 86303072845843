import { useState } from 'react';

// import { IModalState } from "../../../pages/StaticPages/__helpers/global.types";
import { useAppSelector } from '../../redux/hooks';

export const useBookNowModalMobile = () => {
  const selected = {
    test: useAppSelector((state: any) => state.query.test),
    testID: useAppSelector((state: any) => state.query.testID),
    answers: useAppSelector((state: any) => state.query.answers),
    city: useAppSelector((state: any) => state.query.destination),
    place: useAppSelector((state: any) => state.query.place),
    date: useAppSelector((state: any) => state.query.date),
    hasPrescription: useAppSelector(
      (state: any) => state.query.hasPrescription,
    ),
  };

  const [test, setTest] = useState(selected.test);
  const [testID, setTestID] = useState<string>(selected.testID);
  const [answers, setAnswers] = useState<string[]>(selected.answers);
  const [date, setDate] = useState(selected.date);
  const [suggested, setSuggested] = useState<any>(null);
  const [place, setPlace] = useState<any>(selected.place);
  const [bookNowModal, setBookNowModal] = useState<any>({
    isOpen: false,
    content: null,
  });
  const [hasPrescription, setHasPrescription] = useState<boolean>(
    selected.hasPrescription,
  );

  return {
    bookNowModal,
    setBookNowModal,
    test,
    setTest,
    testID,
    setTestID,
    answers,
    setAnswers,
    date,
    setDate,
    suggested,
    setSuggested,
    place,
    setPlace,
    hasPrescription,
    setHasPrescription,
  };
};
