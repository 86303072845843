import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import {
  addDays,
  areIntervalsOverlapping,
  format,
  parse,
  startOfToday,
} from 'date-fns';
import { it } from 'date-fns/locale';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { SocketContext } from '../App';
import { DayPicker } from '../components/DatePickers';
import { SlotsUnavailable } from '../components/Unavailable';
import Feature from '../components/Feature';
import { MuiButton } from '../components/MuiStyledComponents';
import { PRIMA_POSSIBILE_DATE } from '../constants';
import usePartialSlotsRender from '../hooks/usePartialSlotsRender';
import { updateAppointment } from '../redux/appointment/appointmentSlice';
import { createEntry, resetUnpaidReservation } from '../redux/entry/entry';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  updateDate,
  // setReservationWithinPublicSystemPayloadPartial,
} from '../redux/query/querySlice';
import { getQueryPlaceCoords, getQueryTestID } from '../redux/query/selectors';
import limit from '../utils/limit';
import classNames from 'classnames';

import { EModalContent } from './_Results';
import paths from 'routes/paths';

interface IPickerHeaderProps {
  date: Date | null;
  styles?: object;
  sizeReduced?: boolean;
}

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.Calendar': {
              padding: 0,
              width: '61px',
              height: '61px',
              background: '#F4F8FB',
              borderRadius: '18px',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#263238',

            '&.Title': {
              marginBottom: '28px',
              fontWeight: 800,
              fontSize: '16px',
              lineHeight: 1.5,
              letterSpacing: '0.2px',
              textAlign: 'center',
              [theme.breakpoints.up('lg')]: {
                fontSize: '18px',
                lineHeight: 1.35,
              },
            },
            '&.Destination': {
              paddingTop: '3px',
              fontSize: '14px',
              fontWeight: 800,
              lineHeight: 1.2,
              color: '#212121;',
            },
            '&.Address': {
              fontSize: '12px',
              lineHeight: 1.5,
              color: '#607D8B',
              letterSpacing: '0.4px',
              textTransform: 'capitalize',
              [theme.breakpoints.up('lg')]: {
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '0.2px',
                color: '#263238',
              },
            },
            '&.Session': {
              fontWeight: 800,
              fontSize: '14px',
              lineHeight: 1.2,
              color: '#263238',
            },
            '&.Price': {
              fontFamily: 'inherit',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
            },
            '&.Time': {
              fontWeight: 800,
              fontSize: '14px',
              lineHeight: 1.2,
              color: '#263238',
              [theme.breakpoints.up('lg')]: {
                fontSize: '16px',
                lineHeight: 1.5,
                letterSpacing: '0.2px',
              },
            },
            '&.Meta': {
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#607D8B',
              textTransform: 'capitalize',
              [theme.breakpoints.up('lg')]: {
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '0.2px',
              },
            },
            '&.WeekDay': {
              fontWeight: 500,
              fontSize: '14px',
              color: '#607d8b',
              lineHeight: 1.5,
              letterSpacing: '0.2px',
              textTransform: 'capitalize',
              '&.SizeReduced': {
                [theme.breakpoints.up('lg')]: {
                  fontSize: '12px',
                },
              },
            },
            '&.Timeframe': {
              fontWeight: 800,
              fontSize: '14px',
              lineHeight: 1.2,
              color: '#607D8B',
            },
            '&.Test': {
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: 1.5,
              letterSpacing: '0.2px',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '20px 16px',
            justifyContent: 'space-between',
            background: '#F4F8FB',
            borderRadius: '16px',
            '&:hover': {
              background: '#F4F8FB',
            },
            '&.Appointment': {
              padding: '10px 16px',
              [theme.breakpoints.up('lg')]: {
                padding: '21px 16px',
              },
            },
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            height: '100vh',
            background: '#fff',
            overflow: 'scroll',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-around',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
            width: 'auto',
            marginLeft: '10px',
            marginRight: '15px',
            [theme.breakpoints.up('lg')]: {
              marginLeft: '25px',
              marginRight: '30px',
            },
            '&.MarginReduced': {
              marginLeft: '12px',
              marginRight: '21px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            marginTop: '12px',
            width: '100%',
            padding: '21px 16px',
            justifyContent: 'start',
            fontFamily: 'Manrope, sans-serif',
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            textAlign: 'start',
            textTransform: 'none',
            color: '#00838F',
            border: '1px solid #CFD8DC',
            borderRadius: '16px',
          },
          endIcon: {
            marginLeft: 'auto',
          },
        },
      },
    },
  });
};

export const getDays = (date: Date | null): any => {
  if (!date) return [];
  const day = Number(format(date, 'e')) - 1;

  switch (day) {
    case 1:
      return [{ start: date, end: addDays(date, 4) }];
    case 2:
      // return [{ start: subDays(date, 1), end: addDays(date, 3) }];
      return [
        { start: date, end: addDays(date, 3) },
        { start: addDays(date, 6), end: addDays(date, 6) },
      ];
    case 3:
      // return [{ start: subDays(date, 2), end: addDays(date, 2) }];
      return [
        { start: date, end: addDays(date, 2) },
        { start: addDays(date, 5), end: addDays(date, 6) },
      ];
    case 4:
      // return [{ start: subDays(date, 3), end: addDays(date, 1) }];
      return [
        { start: date, end: addDays(date, 1) },
        { start: addDays(date, 4), end: addDays(date, 6) },
      ];
    case 5:
      // return [{ start: subDays(date, 4), end: date }];
      return [
        { start: date, end: date },
        { start: addDays(date, 3), end: addDays(date, 6) },
      ];
    default:
      return [];
  }
};

const getWeekDays = (date: Date | null): any => {
  if (!date) return [];
  const day = Number(format(date, 'e')) - 1;

  switch (day) {
    case 1:
      return ['lun', 'mar', 'mer', 'gio', 'ven'];
    case 2:
      return ['mar', 'mer', 'gio', 'ven', 'lun'];
    case 3:
      return ['mer', 'gio', 'ven', 'lun', 'mar'];
    case 4:
      return ['gio', 'ven', 'lun', 'mar', 'mer'];
    case 5:
      return ['ven', 'lun', 'mar', 'mer', 'gio'];
    default:
      return [];
  }
};

// const required = ['lun', 'mar', 'mer', 'gio', 'ven'];

const getSelectedDate = (date: any) => {
  if (!date || date === PRIMA_POSSIBILE_DATE) {
    return format(new Date(), 'yyyy-MM-dd');
  }
  return format(date, 'yyyy-MM-dd');
};

export const PickerHeader = (props: IPickerHeaderProps) => {
  const weekDays = getWeekDays(props.date);
  const isDesktop = useMediaQuery('(min-width:1200px)');

  const { sizeReduced } = props;

  return (
    <List
      sx={
        isDesktop
          ? {
              mb: '10px',
              ml: '5px',
              padding: 0,
              display: 'flex',
              alignItems: 'center',
            }
          : {}
      }
    >
      {weekDays.map((weekDay: string) => (
        <ListItem
          sx={{
            ...props?.styles,
            // ...(sizeReduced ? { ml: '12px', mr: '21px' } : {}),
            ...(sizeReduced ? { ml: '10px', mr: '19px' } : {}),
          }}
        >
          <Typography
            className={sizeReduced ? 'WeekDay SizeReduced' : 'WeekDay'}
            sx={{
              ...(sizeReduced ? { fontSize: '12px' } : {}),
            }}
          >
            {weekDay}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export const dictionary: {
  [key: string]: {
    start: string;
    end: string;
    title?: string;
    startInMinutes?: number;
  };
} = {
  morning: {
    start: '06:00',
    end: '12:59',
    title: 'Mattina',
    startInMinutes: 360,
  },
  afternoon: {
    start: '13:00',
    end: '17:59',
    title: 'Pomeriggio',
    startInMinutes: 780,
  },
  evening: {
    start: '18:00',
    end: '21:00',
    title: 'Sera',
    startInMinutes: 1080,
  },
  morningTillEvening: { start: '06:00', end: '21:00' },
};

const Wrappers = {
  Part: styled(Box)({ margin: '32px 0px 10px' }),
  Info: styled(Box)({ textAlign: 'right' }),
};

// const Wrapper = styled(Box)({ margin: '32px 0px 10px' });

const Label = styled(Typography)({
  marginBottom: '12px',
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: 1.2,
  color: '#607D8B',
  textTransform: 'capitalize',
});

export const Part = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Wrappers.Part>
      <Label>{label}</Label>
      {children}
    </Wrappers.Part>
  );
};

const Appointment = styled(MenuItem)(({ theme }) => ({
  padding: '10px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F4F8FB',
  borderRadius: '16px',
  [theme.breakpoints.up('lg')]: {
    padding: '12px 16px',
  },
}));

const Time = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: 1.2,
  color: '#263238',
  [theme.breakpoints.up('lg')]: {
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
  },
}));

const Price = styled(Typography)({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#263238',
  [theme.breakpoints.up('lg')]: {
    fontSize: '14px',
    letterSpacing: '0.2px',
    color: '#607D8B',
  },
});

const Staff = styled(Price)(props => ({
  // @ts-ignore
  textTransform: props.capitalized ? 'capitalize' : 'none',
}));

export const Slot = (slot: {
  id: string;
  time: string;
  price: number;
  doctor: string;
  onClick: any;
  cost: number;
  center: any;
}): JSX.Element => {
  // TODO: pass onClick, rework key (id)
  console.log(slot, 'Slot comp.');
  const { id, time, price, doctor, cost, onClick } = slot;
  const [start] = time.split('-');
  const fixed = price?.toFixed(2);
  const value = fixed ? fixed : cost?.toFixed(2);

  const lowercased = doctor.toLowerCase();
  const unknown = lowercased === 'medico di staff';
  const staff = unknown ? 'Medico dello Staff' : `${lowercased}`;

  return (
    <Appointment
      sx={{ minHeight: { xs: '56px', lg: '66px' } }}
      key={id}
      // onClick={() => onClick({ time: start, price: `${value}€`, staff })}
      onClick={() =>
        onClick({ ...slot, apptStart: start, apptCost: `${value}€`, staff })
      }
    >
      <Time>{start}</Time>
      <Wrappers.Info>
        {!!Number(value) && <Price>{value}&euro;</Price>}
        {/* @ts-ignore */}
        <Staff capitalized={!unknown}>{staff}</Staff>
      </Wrappers.Info>
    </Appointment>
  );
};

export const ShowMore = styled(Button)({
  width: '100%',
  padding: '20px 16px',
  justifyContent: 'start',
  fontFamily: 'Manrope, sans-serif',
  fontSize: '14px',
  lineHeight: 1.2,
  fontWeight: 800,
  letterSpacing: '0.2px',
  textAlign: 'start',
  textTransform: 'none',
  color: '#00838F',
  border: '1px solid #CFD8DC',
  borderRadius: '16px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '16px',
    lineHeight: 1.5,
  },
});

const QueryDetailsWrapper = styled(Box)({
  marginBottom: '32px',
  '&.with-inline-datepicker': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// const URL_PARAM_WITH_TRACKING_ENABLED = 'ecografia-addome-completo-milano';

const Slots = (props: {
  onModalClose?: () => void;
  soonestDate?: string;
  setExternal?: any;
  withinPublicSystemSlots?: any;
  withinPublicSystemDateSelected?: string | null;
  shouldTreatSlotAsPublicSystemOne?: boolean;
  setWithinPublicSystemDateSelected?: (dateSelected: string) => void;
}): JSX.Element => {
  const {
    onModalClose,
    soonestDate,
    setExternal,
    withinPublicSystemSlots,
    withinPublicSystemDateSelected,
    shouldTreatSlotAsPublicSystemOne,
    setWithinPublicSystemDateSelected,
  } = props;

  const { info } = useParams<{ info: string }>();

  const isQueryWithinPublicSystem = false;

  const test = useAppSelector(state => state.query.test);
  let date = useAppSelector(state => state.query.date);
  date = date === PRIMA_POSSIBILE_DATE ? (soonestDate as string) : date;

  const query = useAppSelector(state => state.query);
  const appointment = useAppSelector(state => state.appointment);
  useEffect(() => {
    console.log('query', query);
    console.log('appointment', appointment);
  }, [query, appointment]);

  const price = useAppSelector(state => state.appointment.price);
  const [fixed] = price?.split('.');
  const cost = Number(fixed);

  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('session');
  const [selected, setSelected] = useState<any>(() => {
    if (!date || date === PRIMA_POSSIBILE_DATE) {
      return startOfToday();
    }
    const [, cut] = date.split(' ');
    return parse(cut, 'dd/MM/yy', new Date());
  });
  const socket = useContext(SocketContext);
  const [mock, setMock] = useState<any>(null);
  const [slots, setSlots] = useState<any>(null);

  // const category = query.testID;
  // const answers = query.answers;
  const formatted = getSelectedDate(selected);
  const center = useAppSelector(state => state.appointment.center);
  const centerID = useAppSelector(state => state.appointment.centerID);
  const address = useAppSelector(state => state.appointment.address);
  const destination = useAppSelector(state => state.query.destination);

  const testID = useAppSelector(getQueryTestID);
  const coords = useAppSelector(getQueryPlaceCoords);

  const sockets: string[] = [];

  useEffect(() => {
    if (
      sockets.includes('slots') ||
      isQueryWithinPublicSystem ||
      shouldTreatSlotAsPublicSystemOne
    )
      return;
    sockets.push('slots');

    setIsLoading(true);
    setMock(null);

    socket.emit(
      'find.slots.center',
      { location: coords, test: testID, center: centerID, date: formatted },
      ({ slot: data }: any) => {
        setIsLoading(false);

        const slotsExtended = (!!data ? limit(data) : data)?.slots.map(
          (slot: any) => ({
            ...slot,
            center: data?.center,
          }),
        );
        setMock(slotsExtended);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, coords, testID, selected, formatted, centerID]);

  useEffect(() => {
    if (isQueryWithinPublicSystem || shouldTreatSlotAsPublicSystemOne) {
      return;
    }

    if (!mock || mock.length === 0) {
      return setSlots([{ time: 'N/A - N/A', id: 'N/A' }]);
    }

    console.log(mock, 'MOCK');
    // const { slots: values } = mock;
    setSlots(
      mock?.slice().sort((a: any, b: any) => {
        return (
          Number(a.time.split('-')[0].split(':').join('')) -
          Number(b.time.split('-')[0].split(':').join(''))
        );
      }),
    );
  }, [isQueryWithinPublicSystem, shouldTreatSlotAsPublicSystemOne, mock]);

  useEffect(() => {
    if (
      (!isQueryWithinPublicSystem && !shouldTreatSlotAsPublicSystemOne) ||
      !withinPublicSystemSlots
    ) {
      return;
    }
    setSlots(withinPublicSystemSlots);
  }, [
    isQueryWithinPublicSystem,
    shouldTreatSlotAsPublicSystemOne,
    withinPublicSystemSlots,
  ]);

  console.log({ slots }, 'SLOTS_SLOTS_SLOTS');

  // const setNewDate = (value: string) => {

  //   if (value.length === 0 || value === 'Prima possibile') {
  //     return setSelected(parse('20/07/22', 'dd/MM/yy', new Date()));
  //   }

  //   const [, cut] = value.split(' ');
  //   const parsed = parse(cut, 'dd/MM/yy', new Date());
  //   return setSelected(parsed);
  // };

  // console.log(mock, 'mock');
  // console.log(slots, 'slots');

  let navigate = useNavigate();
  const getBack = () => {
    if (content !== 'session') {
      setContent('session');
      return;
    }
    return navigate(-1);
  };

  console.log(slots, 'slots');

  const dispatch = useAppDispatch();

  const { search } = useLocation();

  const onAppointmentClick = (appt: any) => {
    // FIXME: Think of a case when the same slot's with payment rejected chosen
    dispatch(resetUnpaidReservation());
    if (isQueryWithinPublicSystem) {
      // dispatch(
      //   setReservationWithinPublicSystemPayloadPartial({
      //     test: appt.publicTest.test,
      //     date: appt.date,
      //     time: appt.time,
      //     centerDataAccordingToPS: appt.centerDataAccordingToPS,
      //     reservationData: appt.bookData,
      //     testName: appt.publicTest.name,
      //   }),
      // );
      // return navigate('/details');
    }

    const { apptStart, staff, apptCost, center, doctor, date, time } = appt;

    if (center.hasOwnProperty('apiType') && center['apiType'] === 'redirect') {
      return setExternal({
        open: true,
        href: center['apiUrl'],
        content: EModalContent.Redirect,
      });
    }

    const parsed = parse(date, 'yyyy-MM-dd', new Date());
    const formatted = `${format(parsed, 'EEEE', {
      locale: it,
    })} ${format(parsed, 'dd/MM/yy')}`;

    const { coordinates } = center;
    const centerCoords = coordinates
      .map((coord: number) => coord.toString())
      .join(',');

    dispatch(
      updateAppointment({
        time: apptStart,
        staff,
        price: apptCost,
        date: formatted,
        centerCoords,
      }),
    );

    dispatch(
      createEntry({
        api: center.api,
        chain: center.chain,
        chainLabel: center.chainLabel,
        test: center.test,
        date: appt.date,
        time: appt.time,
        center: center.id,
        doctor,
        price: appt.price || appt.cost,
        data: appt.bookData || {},
        centerApiType: center.apiType,
      }),
    );

    sessionStorage.setItem('search', search);

    navigate(`/${paths().confirmation}`);
  };

  const [canBeMarkedAsUnavailable, setCanBeMarkedAsUnavailable] =
    useState(false);

  const handleDateChange = (date: any) => {
    if (date.length === 0 || date === PRIMA_POSSIBILE_DATE) {
      const today = startOfToday();
      const formatted = `${format(today, 'EEEE')} ${format(today, 'dd/MM/yy')}`;
      dispatch(updateDate(formatted));
      return setSelected(today);
    }

    const result = `${format(date, 'EEEE')} ${format(date, 'dd/MM/yy')}`;
    // dispatch(updateDate(result));
    setSelected(date);

    if (shouldTreatSlotAsPublicSystemOne) {
      setCanBeMarkedAsUnavailable(true);
      setWithinPublicSystemDateSelected?.(format(date, 'EEEE dd/MM/yy'));
    }

    if (sockets.includes('slots')) {
      const idx = sockets.findIndex((el: string) => el === 'slots');
      sockets.splice(idx, 1);
    }
  };

  const sorted = useMemo(
    () =>
      slots?.reduce(
        (acc: any, slot: any) => {
          const [start, end] = slot?.time?.split(' - ') ?? [];
          if (!start || !end || start === 'N/A' || end === 'N/A') {
            return acc;
          }
          const date = slot?.date;
          const overlapsMorning = areIntervalsOverlapping(
            {
              start: parse(`${date}/${start}`, 'yyyy-MM-dd/HH:mm', new Date()),
              end: parse(`${date}/${end}`, 'yyyy-MM-dd/HH:mm', new Date()),
            },
            {
              start: parse(
                `${date}/${dictionary['morning'].start}`,
                'yyyy-MM-dd/HH:mm',
                new Date(),
              ),
              end: parse(
                `${date}/${dictionary['morning'].end}`,
                'yyyy-MM-dd/HH:mm',
                new Date(),
              ),
            },
          );
          // console.log(overlapsMorning, 'morning');
          const overlapsAfternoon = areIntervalsOverlapping(
            {
              start: parse(`${date}/${start}`, 'yyyy-MM-dd/HH:mm', new Date()),
              end: parse(`${date}/${end}`, 'yyyy-MM-dd/HH:mm', new Date()),
            },
            {
              start: parse(
                `${date}/${dictionary['afternoon'].start}`,
                'yyyy-MM-dd/HH:mm',
                new Date(),
              ),
              end: parse(
                `${date}/${dictionary['afternoon'].end}`,
                'yyyy-MM-dd/HH:mm',
                new Date(),
              ),
            },
          );
          if (overlapsMorning) {
            return { ...acc, m: [...acc['m'], slot] };
          } else if (overlapsAfternoon) {
            return { ...acc, a: [...acc['a'], slot] };
          } else {
            return { ...acc, e: [...acc['e'], slot] };
          }
        },
        { m: [], a: [], e: [] },
      ),
    [slots],
  );

  // console.log(sorted, 'sorted');
  const divided = useMemo(() => {
    if (!sorted) {
      return [];
    }
    const obj: any = {};
    const keys = Object.keys(sorted);

    for (const key of keys) {
      const avals = sorted[key];
      if (avals.length === 0) {
        obj[key] = [[], []];
        continue;
      }

      if (avals.length <= 3) {
        obj[key] = [avals, []];
        continue;
      }
      const visible = avals.slice(0, 3);
      console.log(visible, 'visible');
      const hidden = avals.slice(3);
      obj[key] = [visible, hidden];
    }
    return obj;
  }, [sorted]);

  console.log(divided, 'divided');

  const isDesktop = useMediaQuery('(min-width:1200px)');
  console.log(sorted, 'sorted');
  console.log(slots, 'slots');

  const { Partial: Morning } = usePartialSlotsRender({
    label: 'Mattina',
    slots:
      (isQueryWithinPublicSystem || shouldTreatSlotAsPublicSystemOne
        ? slots
        : mock) && divided
        ? divided['m']
        : [[], []],
    onClick: onAppointmentClick,
    cost,
  });

  const { Partial: Afternoon } = usePartialSlotsRender({
    label: 'Pomeriggio',
    slots:
      (isQueryWithinPublicSystem || shouldTreatSlotAsPublicSystemOne
        ? slots
        : mock) && divided
        ? divided['a']
        : [[], []],
    onClick: onAppointmentClick,
    cost,
  });

  const { Partial: Evening } = usePartialSlotsRender({
    label: 'Sera',
    slots:
      (isQueryWithinPublicSystem || shouldTreatSlotAsPublicSystemOne
        ? slots
        : mock) && divided
        ? divided['e']
        : [[], []],
    onClick: onAppointmentClick,
    cost,
  });

  // const unavailable = slots && slots.length === 1 && slots[0]['time'] === 'N/A - N/A';
  const unavailable = useMemo(() => {
    if (shouldTreatSlotAsPublicSystemOne) {
      return canBeMarkedAsUnavailable && !withinPublicSystemSlots.length;
    }
    return (
      !mock && slots && slots.length === 1 && slots[0]['time'] === 'N/A - N/A'
    );
  }, [
    mock,
    slots,
    shouldTreatSlotAsPublicSystemOne,
    withinPublicSystemSlots,
    canBeMarkedAsUnavailable,
  ]);

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Container
        sx={{ overflowY: 'auto', background: '#FFFFFF' }}
        className={isDesktop ? 'bare' : ''}
      >
        <Box
          sx={{ padding: isDesktop ? 0 : '16px 0px', background: '#FFFFFF' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <MuiButton.Back onClick={() => onModalClose?.()} />
            <Box sx={{ width: '100%' }}>
              <Typography className="Title">Seleziona disponibilità</Typography>
            </Box>
          </Box>

          <QueryDetailsWrapper
            className={classNames({
              'with-inline-datepicker': isQueryWithinPublicSystem,
            })}
          >
            <Box>
              <Typography className={isDesktop ? 'Test' : 'Destination'}>
                {isDesktop ? test : center}
              </Typography>
              {isDesktop && (
                <Typography className="Address">{center}</Typography>
              )}
              <Typography className="Address">{`${address}${
                !!address ? ' - ' : ''
              }${destination}`}</Typography>

              <Feature
                on={
                  isQueryWithinPublicSystem &&
                  withinPublicSystemDateSelected !== PRIMA_POSSIBILE_DATE
                }
              >
                <Typography className="Address">
                  {withinPublicSystemDateSelected}
                </Typography>
              </Feature>
            </Box>

            <Feature on={isQueryWithinPublicSystem}>
              <MuiButton.Calendar
                onClick={() =>
                  setExternal({
                    open: true,
                    href: '#',
                    content: EModalContent.DatePicker,
                  })
                }
              />
            </Feature>
          </QueryDetailsWrapper>

          <Box>
            <Feature on={!isQueryWithinPublicSystem}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DayPicker
                  classes={['slots']}
                  selectedDate={selected}
                  handleDateChange={handleDateChange}
                />
              </Box>
            </Feature>
            {isLoading ? (
              <Box
                sx={{ mt: '30px', display: 'flex', justifyContent: 'center' }}
              >
                <CircularProgress style={{ color: '#00838F' }} />
              </Box>
            ) : unavailable ? (
              <SlotsUnavailable />
            ) : (
              <>
                <Morning />
                <Afternoon />
                <Evening />
              </>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Slots;
