export enum EDatePickerType {
  Avails = 'AVAILS',
  Birthdate = 'BIRTHDATE',
}

export interface IProps {
  type?: EDatePickerType;
  defaultValue?: string;
  passFormattedDate?: (formattedDate: string) => void;
  onDatePickerClose: () => void;
}

export enum EError {
  InvalidDate = 'InvalidDate',
  BeforeTomorrow = 'BeforeTomorrow',
  FarOff = 'FarOff',
  Weekend = 'Weekend',
  InvalidBirthdate = 'InvalidBirthdate',
}

export interface IValidity {
  valid: boolean;
  error: EError | null;
}
