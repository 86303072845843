import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { IProps } from 'components/Views/Views.types';

const IconWrapper = styled(Box)({
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#E1E9F0',
  borderRadius: '50%',
});

const View = styled(Tab)({
  '&:not(:last-of-type)': {
    marginRight: '56px',
  },
  padding: '18px 12px 18px 0px',
  fontWeight: 800,
  fontSize: '13px',
  lineHeight: 1.5,
  letterSpacing: '1px',
  color: '#8298AB',
  '&.Mui-selected': {
    color: '#304659',
  },
  '& > .MuiTab-iconWrapper': {
    marginRight: '20px',
  },
});

const Views = styled(Tabs)({
  padding: '0px 20px',
  borderBottom: '1px solid #8298AB59',
  '& .MuiTabs-indicator': {
    height: '3px',
    background: '#3B5266',
  },
});

const Panels = ({ initial = 0, views, liftView }: IProps): JSX.Element => {
  const [view, setView] = useState(initial);

  const handleViewChange = (event: React.SyntheticEvent, value: number) => {
    setView(value);
    if (liftView) {
      liftView(value);
    }
  };

  return (
    <>
      <Views value={view} onChange={handleViewChange}>
        {views?.map(({ label, Icon }, index: number) => {
          return (
            <View
              key={index}
              icon={
                <IconWrapper>
                  <Icon />
                </IconWrapper>
              }
              iconPosition="start"
              label={label}
            />
          );
        })}
      </Views>
    </>
  );
};

export default Panels;
