import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const MuiTypography = {
  // FIXME: Remove !important property when having fixed Theme in parent comp.
  Title1: styled(Typography)({
    fontWeight: 700,
    fontSize: '18px !important',
    lineHeight: '1.4 !important',
    letterSpacing: '0.2px',
    color: '#263238',
    '&.loader-message': {
      fontSize: '14px !important',
      color: '#323F4B',
    },
  }),
  // FIXME: Remove !important property when having fixed Theme in parent comp.
  Title2: styled(Typography)({
    fontWeight: 800,
    fontSize: '16px !important',
    lineHeight: '1.5 !important',
    letterSpacing: '0.2px',
    color: '#263238',
  }),
  // FIXME: Remove fontWeight and !important properties when having fixed Theme in parent comp.
  Caption: styled(Typography)({
    fontWeight: 400,
    fontSize: '16px !important',
    lineHeight: '1.5 !important',
    letterSpacing: '0.3px',
    color: '#78909C',
    '&.retrieve-reservation': {
      width: 'fit-content',
      margin: '24px auto 0',
      '& > a': {
        fontWeight: 800,
        color: '#D81B60',
      },
    },
    '&.reservation-regime': {
      margin: '28px 0 0',
    },
    '&.regime': {
      margin: '20px 0 42px',
    },
  }),
  PublicSystemCaption: styled(Typography)({
    fontSize: '12px',
    lineHeight: 1.5,
    letterSpacing: '0.4px',
    color: '#607D8B',
    '&.bold': {
      fontWeight: 700,
    },
  }),
};
