import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import padStart from 'utils/padStart';

const Wrapper = styled(Box)({
  marginBottom: '47px',
  width: '100%',
  padding: '12px 17px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  background: '#E0F7FA',
  borderRadius: '20px',
});

const Time = styled(Typography)({
  width: '60px',
  flexShrink: 0,
  fontWeight: 800,
  fontSize: '20px',
  lineHeight: 1.4,
  letterSpacing: '0.2px',
  color: '#263238',
});

const Meta = styled(Typography)({
  marginLeft: '30px',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#263238',
});

const tick = (time: string, changeTime: (newValue: string) => void) => {
  const [minutes, seconds] = time.split(':');
  const m = Number(minutes);
  const s = Number(seconds);
  let timeLeft;

  if (s === 0 && m === 0) {
    timeLeft = '00:00';
    return changeTime(timeLeft);
  }

  if (s === 0) {
    timeLeft = padStart(m - 1, 2, '0') + ':59';
    return changeTime(timeLeft);
  }

  timeLeft = padStart(m, 2, '0') + ':' + padStart(s - 1, 2, '0');
  return changeTime(timeLeft);
};

const Countdown = (): JSX.Element => {
  const [left, setLeft] = useState('09:00');

  useEffect(() => {
    const timerID = setInterval(() => {
      tick(left, setLeft);
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, [left]);

  return (
    <Wrapper>
      <Time>{left}</Time>
      <Meta>
        Tempo rimanente per concludere la prenotazione. Alla scadenza altri
        utenti potranno prenotare questo blocco orario.
      </Meta>
    </Wrapper>
  );
};

export default Countdown;
