import { createAsyncThunk } from '@reduxjs/toolkit';

import { STORAGE_KEYS } from '../../../constants';
import { api, removeTokensGlobal } from '../../../api';
import { notify } from '../../../utils';

const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }: { username: string; password: string }) => {
    try {
      const response = await api.post('/login/b2b', { username, password });
      localStorage.setItem(
        STORAGE_KEYS.ACCESS_TOKEN,
        response.data.access_token,
      );
      localStorage.setItem(
        STORAGE_KEYS.REFRESH_TOKEN,
        response.data.refresh_token,
      );
      return true;
    } catch (e) {
      notify('Email e/o password errate', 'error');
      return false;
    }
  },
);

const logout = createAsyncThunk('auth/logout', async () => {
  try {
    await api.get('/logout');
    await removeTokensGlobal();
    return true;
  } catch (e) {
    return false;
  }
});

export const authOperations = { login, logout };
