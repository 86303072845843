import { IBooking } from '../../types';
import dateHelpers from '../../utils/date';

const EMPTY_USER = {
  email: '',
  firstName: '',
  id: '',
  lastName: '',
};
export function mapBookingsToTableBookings(bookings: IBooking[]) {
  return bookings.map((entry: IBooking) => {
    const bookingDate = dateHelpers.formatUTCDate(
      entry.date,
      'dd/MM/yy - HH:mm',
    );
    const reservationDate = !!entry.reservationDate
      ? dateHelpers.formatUTCDate(entry.reservationDate, 'dd/MM/yy - HH:mm')
      : '';
    const { __typename: __tt, ...restTest } = entry.test;
    const { __typename: __tp, ...restPatient } = entry.patient;
    const { __typename: __tc, ...restCenter } = entry.center;

    return {
      status: entry.status,
      bookingDate: bookingDate,
      bookingID: entry.id,
      code: entry.code,
      test: restTest,
      patient: restPatient,
      reservationDate: reservationDate,
      center: restCenter,
      user: entry.user ?? EMPTY_USER,
      payment: entry.payments,
    };
  });
}
