import React, { useEffect } from 'react';
import { Avatar, Skeleton, Typography, Box, List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import { ReactComponent as Icon } from 'icons/user.svg';

import { authSelectors } from '../redux/slices/auth/selectors';
import { userOperations } from '../redux/slices/user/operations';
import { userSelectors } from '../redux/slices/user/selectors';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
});

const UserList = styled(List)({
  marginLeft: 24,
  width: '100%',
});

const Name = styled(Typography)({
  marginBottom: '4px',
  fontWeight: 800,
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  color: '#263238',
});
const Mail = styled(Typography)({
  fontSize: '14px',
  lineHeight: 1.4,
  letterSpacing: '0.3px',
  color: '#607D8B',
});

const User = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(authSelectors.selectIsLoggedIn);
  const user = useAppSelector(userSelectors.selectUser);

  useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(userOperations.getUser());
  }, [dispatch, isLoggedIn]);

  console.log(user, 'user');

  return (
    <Wrapper>
      {user.loading ? (
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      ) : (
        <Icon />
      )}
      <UserList>
        <ListItem>
          {user.loading ? (
            <Skeleton height={30} width="100%" />
          ) : (
            <Name>{`${user.info?.firstName || 'Admin'} ${
              user.info?.lastName || 'User'
            }`}</Name>
          )}
        </ListItem>
        <ListItem>
          {user.loading ? (
            <Skeleton height={20} width="100%" />
          ) : (
            <Mail>{user.info?.email}</Mail>
          )}
        </ListItem>
      </UserList>
    </Wrapper>
  );
};

export default User;
