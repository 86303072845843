import React from 'react';

import { ChevronDown, ChevronUp } from '../../../icons-components/Icons';
import { ShowMoreButton } from './ShowMoreButton.styled';

interface ToggleShowMoreButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  isFullList: boolean;
  toggleShowMore: () => void;
  label?: string;
  centered?: boolean;
  isMedicalServiceInCity?: boolean;
  withBlackIcon?: boolean;
}
export const ToggleShowMoreButton: React.FC<ToggleShowMoreButtonProps> = ({
  isFullList,
  toggleShowMore,
  label,
  centered,
  isMedicalServiceInCity,
  withBlackIcon,
  ...rest
}) => {
  return (
    <ShowMoreButton
      centered={centered}
      isMedicalServiceInCity={isMedicalServiceInCity}
      onClick={toggleShowMore}
      {...rest}
    >
      {isFullList ? (
        <>
          {'Vedi meno'}
          <ChevronUp withBlackIcon={withBlackIcon} />
        </>
      ) : (
        <>
          {label ? label : 'Mostra di più'}
          <ChevronDown withBlackIcon={withBlackIcon} />
        </>
      )}
    </ShowMoreButton>
  );
};
