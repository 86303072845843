import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

const Masked = forwardRef<HTMLElement, any>(function Masked(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="### ### **#** #***#"
      definitions={{
        '#': /[a-zA-Z]/,
        '*': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(val: string) => {
        onChange(val.toUpperCase());
      }}
      overwrite
    />
  );
});

export default Masked;
