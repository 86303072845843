import { ThemeProvider, useMediaQuery } from '@mui/material';
// import { Container } from 'pages/StaticPages/__helpers/global.styled';
import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { PRIMA_POSSIBILE_DATE } from '../../constants';
// import useAnalyticsEventTracker, {
//   eventsTracked,
// } from '../../../hooks/useAnalyticsEventTracker';
// import { IModalState } from '../../../pages/StaticPages/__helpers/global.types';
import { useAppDispatch } from '../../redux/hooks';
import { createQuery } from '../../redux/query/querySlice';
import paths from '../../routes/paths';
import dateHelpers from '../../utils/date';
import Search from '../Searchbar';
// import { HomeForm } from '../../Forms/Home';
import { BookNowBlock, ExamsHeader, getMuiTheme } from './BookNow.styled';

interface BookNowProps {
  modal: any;
  setModal: React.Dispatch<React.SetStateAction<any>>;
  setTestID: React.Dispatch<React.SetStateAction<string>>;
  setAnswers: React.Dispatch<React.SetStateAction<string[]>>;
  setSuggested: React.Dispatch<any>;
  setTest: React.Dispatch<string>;
  setPlace: React.Dispatch<any>;
  date: any;
  setDate: React.Dispatch<any>;
  test: string;
  testID: string;
  answers: any;
  suggested: any;
  place: any;
  withTitleAndBg?: boolean;
  hasPrescription: boolean;
  setHasPrescription: React.Dispatch<boolean>;
}
export const BookNow = React.forwardRef<HTMLDivElement, BookNowProps>(
  (
    {
      modal,
      setModal,
      setTestID,
      setAnswers,
      setSuggested,
      setTest,
      setPlace,
      date,
      setDate,
      test,
      testID,
      answers,
      suggested,
      place,
      withTitleAndBg = true,
      hasPrescription,
      setHasPrescription,
    },
    ref,
  ) => {
    const isDesktop = useMediaQuery('(min-width:1200px)');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSubmit = (): void => {
      const payload = {
        test,
        testID,
        destination: place.address,
        place,
        date,
        answers,
        suggested,
        hasPrescription,
      };
      dispatch(createQuery(payload));
      const { id: placeID, coords, address: dest } = place;

      const [lat, lng] = coords;

      let stamp = null;

      if (date !== PRIMA_POSSIBILE_DATE) {
        stamp = dateHelpers.getYYMMDD(dateHelpers.getParsedDate(date));
      }

      navigate({
        pathname: paths().results,
        search: `${createSearchParams({
          testID,
          placeID,
          lat,
          lng,
          dest,
          ...(stamp ? { stamp } : {}),
          sort: 'default',
        })}`,
      });
    };

    return (
      <BookNowBlock
        style={{ display: 'block' }}
        ref={ref}
        withTitleAndBg={withTitleAndBg}
      >
        {/* <Container> */}
        {withTitleAndBg && (
          <ExamsHeader style={isDesktop ? {} : { justifyContent: 'center' }}>
            Prenota ora
          </ExamsHeader>
        )}
        <ThemeProvider theme={getMuiTheme()}>
          <Search
            test={test}
            onTestChange={setTest}
            passTestID={setTestID}
            passAnswers={setAnswers}
            passSuggested={setSuggested}
            city={place?.address}
            onCityChange={setPlace}
            date={date}
            onDateChange={setDate}
            onQuerySubmit={onSubmit}
            passPrescription={setHasPrescription}
          />
        </ThemeProvider>
        {/* {isDesktop ? (
          <ThemeProvider theme={getMuiTheme()}>
            <Search
              test={test}
              onTestChange={setTest}
              passTestID={setTestID}
              passAnswers={setAnswers}
              passSuggested={setSuggested}
              city={place?.address}
              onCityChange={setPlace}
              date={date}
              onDateChange={setDate}
              onQuerySubmit={onSubmit}
              passPrescription={setHasPrescription}
            />
          </ThemeProvider>
        ) : (
          <HomeForm
            test={test}
            testID={testID}
            place={place}
            city={place?.address}
            onCityChange={setPlace}
            date={date}
            suggested={suggested}
            answers={answers}
            setModal={setModal}
            onTestChange={setTest}
            onDateChange={setDate}
            hasPrescription={hasPrescription}
          />
        )} */}
        {/* </Container> */}
      </BookNowBlock>
    );
  },
);
