import { styled } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { MenuArrow } from '../../icons-components/Icons';

export const MuiListItemButton = styled((props: any) => (
  <>
    <ListItemButton {...props}>
      <ListItemText>{props.children}</ListItemText>
      <ListItemIcon>
        <MenuArrow />
      </ListItemIcon>
    </ListItemButton>
    {props.withDivider && !props.last && (
      // background: '#607D8B !important'
      <Divider sx={{ marginInline: '16px', height: 'auto !important' }} />
    )}
  </>
))({
  padding: '22px 24px',
  '& .MuiTypography-root': {
    margin: 0,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#263238',
  },
  '& > .MuiListItemIcon-root': {
    minWidth: 'auto',
  },
});
