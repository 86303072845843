import { createHttpLink, fromPromise, concat } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { STORAGE_KEYS } from '../constants';
import { refreshGlobal } from '../api';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_API_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
  return {
    headers: { ...headers, authorization: token ? `Bearer ${token}` : '' },
  };
});

let isRefreshing = false;
let pendingRequests: any[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            let forward$;
            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                refreshGlobal()
                  .then(accessToken => {
                    resolvePendingRequests();
                    return accessToken;
                  })
                  .catch((error: Error) => {
                    pendingRequests = [];
                    return;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  }),
              ).filter(value => Boolean(value));
            } else {
              forward$ = fromPromise(
                new Promise(resolve => {
                  pendingRequests.push(() => resolve(true));
                }),
              );
            }

            return forward$.flatMap(() => forward(operation));
        }
      }
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  },
);

export const apolloLinks = concat(errorLink, concat(authLink, httpLink));
