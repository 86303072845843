import { State } from '../types';

export const getQueryTestID = (state: any) => state.query.testID;
export const getQueryTestName = (state: any) => state.query.test;
export const getQueryPlaceCoords = (state: any) => state.query.place.coords;
export const getQueryPlaceAddress = (state: any) => state.query.place.address;
export const getQueryFallbackDestination = (state: any) =>
  state.query.destination;
export const getQueryDate = (state: any) => state.query.date;
export const getQueryPlace = (state: any) => state.query.place;
export const getHasPrescription = (state: any) => state.query.hasPrescription;

export const getIsQuerySpecific = (state: State) =>
  state.query.test.toLowerCase().includes('visita specialistica');
