import { IBooking } from '../../types';

export enum ViewsTypes {
  Booking,
  // User,
  Patient,
  // Payment,
  Logs,
}

export enum LogsLevels {
  INFO = 'info',
  ERROR = 'error',
}

export interface ILog {
  level: LogsLevels;
  message: string;
  time: string;
}

interface IBookingDetails extends IBooking {
  logs: ILog[];
}

export interface FindBookingByIdResult {
  findBookById: IBookingDetails;
}
