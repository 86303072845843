import { gql } from '@apollo/client';

export const CHANGE_BOOKING_STATUS = gql`
  mutation ChangeBookingStatus($id: String!, $status: String!) {
    changeBookingStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
