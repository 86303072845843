import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { FIND_PATIENT_BY_ID } from './Patient.query';
import { FindPatientByIdResult } from './Patient.types';

export const usePatient = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, data } = useQuery<FindPatientByIdResult>(
    FIND_PATIENT_BY_ID,
    {
      variables: { id },
    },
  );
  const patient = data?.findPatientById;

  return { loading, patient, id };
};
