import styled from '@emotion/styled';
import { Box, Table } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Styled from 'components/Styled';
import CsvDownloadButton from 'react-json-to-csv';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

export const TableSettings = styled(List)`
  margin-left: auto;
  margin-bottom: 22px;
  max-width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

export const TableSetting = styled(ListItem)({
  width: 'auto',
});

export const ColumnsVisible = styled(IconButton)({
  padding: '7px 15px',
  background: 'transparent',
  border: '1px solid #B0BEC5',
  borderRadius: '12px',
  '& .MuiTouchRipple-root .MuiTouchRipple-child': {
    borderRadius: 'inherit',
  },
});

export const ColumnHeaderBox = styled(Box, { label: 'ColumnHeaderBox' })`
  margin-bottom: 10px;
  min-height: 40px;
  hyphens: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const BaseBtn = `
  padding: 9px 15px;
  background: transparent;
  border: 1px solid #b0bec5;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  color: #00838f;
  text-transform: none;
  cursor: pointer;
  overflow: hidden;
`;

export const ExportBtn = styled(CsvDownloadButton)`
  ${BaseBtn}
`;

export const TableTransparentButton = styled(Button, {
  label: 'TableTransparentButton',
})<{
  isSmall?: boolean;
  isJustifyBetween?: boolean;
}>`
  ${BaseBtn};
  padding: ${({ isSmall }) => (isSmall ? '6.5px 15px' : '9px 15px')};
  ${({ isJustifyBetween }) =>
    isJustifyBetween &&
    css`
      justify-content: space-between;
    `}
  & .MuiTouchRipple-root .MuiTouchRipple-child {
    border-radius: inherit;
  },
`;

export const ColumnsVisibleList = styled(Popover)({
  '& > .MuiPaper-root': {
    width: '200px',
    padding: '13px 20px',
    borderRadius: '6px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
});

export const ColumnsVisibleGroup = styled(FormGroup)({});
export const ColumnVisibleLabel = styled(FormControlLabel)({
  margin: 0,
  '&:not(:last-of-type)': {
    marginBottom: '13px',
  },
  '& .MuiFormControlLabel-label': {
    marginLeft: '13px',
    fontFamily: "'Lato', sans-serif !important",
    fontSize: '15px',
    lineHeight: 1.3,
    color: '#263238',
  },
});
export const ColumnVisibleControl = styled(Checkbox, {
  label: 'ColumnVisibleControl',
})({ padding: 0 });

export const FixedTable = styled(Table, { label: 'FixedTable' })({
  tableLayout: 'fixed',
  minWidth: 1450,
});
export const TableHeadRow = styled(TableRow, { label: 'TableRow' })({});

export const Cell = styled(TableCell, { label: 'Cell' })({
  '&.code': {
    width: `5%`,
  },
  '&.status': {
    width: `8%`,
  },
  '&.bookingDate': {
    width: `7%`,
  },
  '&.bookingID': {
    width: `8%`,
  },
  '&.reservationDate': {
    width: `10%`,
  },
});

export const TableHeadCell = styled(Cell, { label: 'TableHeadCell' })`
  padding: 6px;
  cursor: pointer;
  border: none;
`;

export const TableBodyCell = styled(Cell)`
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ColumnHeading = styled(Typography)({
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: 1.2,
  color: '#263238',
});

export const Filter = styled(Styled.Input)({
  '& .MuiOutlinedInput-root': {
    border: '1px solid #B0BEC5',
    borderRadius: '8px',
    background: '#FFFFFF',
  },
  '& .MuiInputBase-input': {
    padding: '6px 8px',
  },
  '& input': {
    height: 'auto',
  },
  '& input::placeholder': {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#263238',
  },
});

export const TableBodyRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    background: '#F4F8FB',
  },
});

export const Status = styled(Chip, { label: 'Status' })({
  height: 'auto',
  borderRadius: '10px',
  '&.pending': {
    background: '#FFEC8A',
  },
  '&.confirmed': {
    background: '#2be139',
  },
  '&.cancelled': {
    background: '#ff8ab7',
  },
  '&.error': {
    background: '#ff8a8a',
  },
  '&.checkedIn': {
    background: '#93cc87',
  },
  '&.noShow': {
    background: '#bebebe',
  },
  '&.toCheck': {
    background: '#8ad2ff',
  },
  '& > .MuiChip-label': {
    paddingLeft: '10px',
    paddingRight: '10px',
    fontFamily: "'Lato', sans-serif",
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: 1.7,
  },
});

export const Content = styled(Typography)({
  fontSize: '15px',
  lineHeight: 1.3,
  color: '#263238',
  '&.AltFontFamily': {
    fontFamily: "'Lato', sans-serif !important",
  },
  '&.white-space': {
    whiteSpace: 'normal',
  },
});

export const Details = styled(Link)({
  fontWeight: 700,
  fontSize: '15px',
  lineHeight: 1.3,
  color: '#00838F',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:focus': {
    textDecoration: 'underline',
  },
});
