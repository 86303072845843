import { parse, format } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { Slot } from '../types/models';

const PRIMA_POSSIBILE_DATE = 'Prima possibile';

const timeZone = 'UTC';
const patternDefault = 'dd/MM/yy';

const getParsedDate = (str: string) => {
  const [, cut] = str.split(' ');
  const parsed = parse(cut, 'dd/MM/yy', new Date());
  return parsed;
};

const getYYMMDD = (date: Date | null) => {
  if (!date) return null;
  return format(date, 'yyyy-MM-dd');
};

const formatUTCDate = (date: string | Date, pattern?: string) => {
  return formatTz(
    utcToZonedTime(new Date(date), timeZone),
    pattern ?? patternDefault,
    { timeZone },
  );
};

function utcTo1GMT(UTCDate: string | Date, pattern?: string) {
  return formatTz(utcToZonedTime(UTCDate, '+01:00'), pattern ?? patternDefault);
}

const getYYMMDDFromString = (str: string | null) => {
  if (!str || str === PRIMA_POSSIBILE_DATE) {
    return null;
  }
  return format(parse(str, 'EEEE dd/MM/yy', new Date()), 'yyyy-MM-dd');
};

const getFirstAvailTimeInMs = (slot: Slot) => {
  const [hours, minutes] = slot.time.split(' - ')[0].split(':');
  // const date = parse(slot.date, 'yyyy-mm-dd', startOfToday());
  // date.setHours(Number(hours));
  // date.setMinutes(Number(minutes));
  // return date.getTime();
  const date = new Date(slot.date);
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));

  return date.getTime();
};

const getDDMMYY = (str: string) => {
  return format(parse(str, 'yyyy-MM-dd', new Date()), 'EEEE dd/MM/yy');
};

const utils = {
  getParsedDate,
  getYYMMDD,
  formatUTCDate,
  utcTo1GMT,
  getYYMMDDFromString,
  getFirstAvailTimeInMs,
  getDDMMYY,
};

export default utils;
