import { MuiBox, MuiTypography } from './Unavailable.styled';

const SlotsUnavailable = (): JSX.Element => {
  return (
    <MuiBox>
      <MuiTypography>
        {
          'Non ci sono più disponibilità per la data selezionata, prova a cercare per i giorni successivi.'
        }
      </MuiTypography>
    </MuiBox>
  );
};

export default SlotsUnavailable;
