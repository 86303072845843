import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as RRDLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

export const FloatingBox = styled(Box)({
  boxSizing: 'border-box',
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  padding: '12px 24px',
  background: '#FFFFFF',
  boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.12)',
  zIndex: 1,
});

const Styled = {
  // Assistance & ForgotPassword
  Link: styled(Link)({
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#00838F',
  }) as any,
  Input: styled(TextField)({
    width: '100%',
    '& label': {
      top: 'auto',
      bottom: 'calc(100% + 6px)',
      transform: 'none',
      transformOrigin: 'unset',
      fontWeight: 800,
      fontSize: '14px',
      lineHeight: 1.2,
      color: '#607D8B',
      '&.Mui-focused': {
        color: '#607D8B',
      },
    },
    '& legend': {
      height: 'auto',
    },
    '& .MuiOutlinedInput-root': {
      border: '1px solid #B0BEC5',
      borderRadius: '16px',
      '&.Mui-focused': {
        borderColor: '#263238',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '&.with-pointer .MuiOutlinedInput-input': {
      cursor: 'pointer',
    },
    '&.with-tags': {
      marginBottom: '8px',
    },
    '&.with-error > .MuiInputLabel-root': {
      color: '#D81B60',
    },
    '&.with-error > .MuiOutlinedInput-root': {
      borderColor: '#D81B60',
    },
    '&.no-fiscal-code > .MuiOutlinedInput-root': {
      background: '#F4F8FB',
    },
    '&.no-tel > .MuiOutlinedInput-root': {
      background: '#F4F8FB',
    },
  }),
  Button: styled(Button)({
    width: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    background: '#D81B60',
    borderRadius: '16px',
    fontWeight: 800,
    fontSize: '16px',
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      background: '#C2185B',
    },
    '&.Mui-disabled': {
      color: '#FFFFFF',
      background: '#CFD8DC',
    },
    '&.p-reduced': {
      paddingTop: '9px',
      paddingBottom: '9px',
    },
  }),
  Title: styled(Typography)({
    fontWeight: 800,
    fontSize: '24px',
    lineHeight: 1.3,
    color: '#263238',
  }),
  RRDLink: styled(RRDLink)({
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#00838F',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
    '&.retreive-password': {
      marginBottom: '46px',
      marginLeft: 'auto',
      display: 'block',
      width: 'fit-content',
    },
  }),
};

export const MuiTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
  },
  '& .MuiInputBase-readOnly::placeholder': {
    color: '#78909C',
  },
  [theme.breakpoints.up('lg')]: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
    },
  },
}));

export const ModalWrapper = styled(Box)({
  boxSizing: 'border-box',
  width: '340px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  background: '#FFFFFF',
  borderRadius: '20px',
});

export const ModalCall = styled(Typography)({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: 1.4,
  letterSpacing: '0.2px',
  color: '#323F4B',
});

export const Input = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& label': {
    top: 'auto',
    bottom: 'calc(100% + 6px)',
    transform: 'none',
    transformOrigin: 'unset',
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: 1.2,
    color: '#607D8B',
    '&.Mui-focused': {
      color: '#607D8B',
    },
  },
  '& legend': {
    height: 'auto',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'inherit',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#263238',
    border: '1px solid #B0BEC5',
    borderRadius: '16px',
    '&.Mui-focused': {
      borderColor: '#263238',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },

  '&.with-error': {
    '& label': {
      color: '#D81B60',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: '#D81B60',
    },
    '& .MuiFormHelperText-root': {
      color: '#D81B60',
    },
  },
}));

export default Styled;
