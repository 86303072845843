import { styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { LinkProps } from '@mui/material/Link';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import {
  ArrowRightAlt,
  KeyboardBackspace,
  Close,
  CalendarMonth,
} from '@mui/icons-material';

export const MuiButton = {
  Primary: styled(Button)(({ theme }) => ({
    width: '100%',
    margin: '0 !important',
    paddingTop: '16px',
    paddingBottom: '16px',
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#FFFFFF',
    background: '#D81B60',
    border: '1px solid #D81B60',
    borderRadius: '16px',
    '&:hover, &:focus': {
      background: '#D81B60',
    },
    '&.decreased-paddings': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '&.outlined': {
      color: '#D81B60',
      background: 'transparent',
      '&:hover, &:focus': {
        background: 'transparent',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '&.centered': {
        margin: '0 auto !important',
        width: '320px',
      },
    },
  })),
  Secondary: styled((props: ButtonProps & LinkProps) => (
    <Button {...props} endIcon={<ArrowRightAlt />}></Button>
  ))({
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: 1.5,
    letterSpacing: '0.4px',
    textTransform: 'none',
    color: '#00838F',
  }),
  Back: styled((props: IconButtonProps) => (
    <IconButton {...props}>
      <KeyboardBackspace fontSize={'small'} />
    </IconButton>
  ))({
    width: '24px',
    height: '24px',
    '&.regime': {
      margin: '0 0 0 -8px',
    },
  }),
  Close: styled((props: IconButtonProps) => (
    <IconButton {...props}>
      <Close fontSize={'small'} />
    </IconButton>
  ))({
    width: '24px',
    height: '24px',
  }),
  Calendar: styled((props: IconButtonProps) => (
    <IconButton {...props}>
      <CalendarMonth />
    </IconButton>
  ))({
    width: '48px',
    height: '48px',
    background: '#ffffff',
    border: '1px solid #B0BEC5',
    borderRadius: '12px',
  }),
};
