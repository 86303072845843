const paths = (params?: Record<string, string>) => ({
  search: 'search',
  booking: 'booking',
  registry: 'registry',
  results: '/risultati',
  confirmation: 'confirmation',
  active: 'active-booking',
  patient: `registry/${params?.id || ':id'}`,
  bookingID: `booking/${params?.id || ':id'}`,
  testingCenter: '/centri-convenzionati/:testingChain/:testingCenter',
  user: `users/${params?.id || ':id'}`,
});

export default paths;
