import { useMutation } from '@apollo/client';
import { CircularProgress, Grid, Typography } from '@mui/material';
import Feedback from 'components/Feedback';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DetailsSkeletons } from '../../../components/DetailsSkeletons/DetailsSkeletons.component';
import { BookingStatus } from '../../../types';
import notify from '../../../utils/notify';
import { FIND_BOOKINGS } from '../../Bookings/Bookings.query';
import {
  getDateAndTime,
  getDateAndTimeAndFormatTo1GMT,
  useBooking,
} from '../BookingDetails.helpers';
import { CANCEL_BOOKING } from '../BookingDetails.query';
import { Control, DetailDivider } from '../BookingDetails.styled';
import { BookingDetailsUpdate } from '../BookingDetailsUpdate';
import paths from '../../../routes/paths';

export const Booking = () => {
  const navigate = useNavigate();
  const { loading, booking, id, refetch } = useBooking();

  const [cancelBookingMutation, { loading: cancellationPending }] = useMutation(
    CANCEL_BOOKING,
    {
      onCompleted: onCancellationFulfilled,
      refetchQueries: [{ query: FIND_BOOKINGS }],
    },
  );
  const canCancel =
    booking?.status === BookingStatus.Pending ||
    booking?.status === BookingStatus.ConfirmedManual ||
    booking?.status === BookingStatus.ConfirmedAuto;

  function onCancellationFulfilled() {
    notify('Annullato con successo', 'success');
  }
  async function cancelBooking() {
    if (!canCancel) return;
    await cancelBookingMutation({ variables: { id } });
    navigate(`/${paths().booking}`);
  }

  if (loading || !booking) {
    return <DetailsSkeletons />;
  }

  return (
    <Grid container>
      <Grid item xs={6} sx={{ pb: '50px' }}>
        <Typography variant="h3" sx={{ mb: '18px' }}>
          Prestazione
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: '6px', color: '#D81B60' }}>
          {`ID patient: ${booking.publicId}`}
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: '18px' }}>
          {booking.test.name}
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: '18px', color: '#D81B60' }}>
          {`ID internal: ${booking.code}`}
        </Typography>
        <Typography variant="subtitle1">Centro Medico</Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {booking.center.name}
        </Typography>
        <Typography variant="subtitle1">Dottore</Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {booking.doctor}
        </Typography>
        <Typography variant="subtitle1">Data della prestazione</Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {getDateAndTime(booking?.reservationDate)}
        </Typography>
        {booking.status === BookingStatus.Pending && (
          <BookingDetailsUpdate
            bookingID={id || booking.id}
            presetTest={{ id: booking.test.id, name: booking.test.name }}
            presetCenter={{ id: booking.center.id, name: booking.center.name }}
            presetDate={booking.reservationDate ?? ''}
            presetTime={booking.time ?? ''}
            refetch={refetch}
          />
        )}
      </Grid>
      <Grid sx={{ pt: '36px' }} item xs={6}>
        <Typography variant="h3" sx={{ mb: '18px' }}>
          Prenotazione
        </Typography>
        <Typography variant="subtitle1">Status</Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {booking.status}
        </Typography>
        <Typography variant="subtitle1">Creata il</Typography>
        <Typography variant="body1" sx={{ mb: '18px' }}>
          {getDateAndTimeAndFormatTo1GMT(booking?.date)}
        </Typography>
        <Typography variant="subtitle1">Creata da</Typography>
        <Typography variant="body1">Utente</Typography>
      </Grid>

      <DetailDivider />

      {booking.feedback !== null && (
        <>
          <Grid sx={{ p: '47px 0px' }} item xs={12}>
            <Feedback status="pre" feedback={booking.feedback} />
          </Grid>

          <DetailDivider />
        </>
      )}

      <Grid sx={{ pt: '32px' }} item xs={12}>
        <Control
          className="Inverse"
          onClick={cancelBooking}
          disabled={!canCancel || cancellationPending}
        >
          {cancellationPending ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Cancella la prenotazione'
          )}
        </Control>
      </Grid>
    </Grid>
  );
};
