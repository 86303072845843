import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { Booking } from 'components/DataGrid';
import Styled from 'components/Styled';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { TTableBookings } from '../../types';
import { mapBookingsToTableBookings } from './Bookings.helpers';
import { FIND_BOOKINGS } from './Bookings.query';
import { FindBookingsResult } from './Bookings.types';

const Wrapper = styled(Container)(({ theme }) => ({
  width: '100%',
  padding: '34px',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'unset',
  },
}));

const Bookings = () => {
  const { loading, data } = useQuery<FindBookingsResult>(FIND_BOOKINGS);

  const entries: TTableBookings = useMemo(() => {
    if (!data) return;
    return mapBookingsToTableBookings(data.findBookings);
  }, [data]);

  return (
    <Wrapper>
      <Helmet>
        <title>Lista prenotazioni</title>
      </Helmet>
      <Styled.Title>Lista prenotazioni</Styled.Title>
      <Booking entries={entries} loading={loading} />
    </Wrapper>
  );
};

export default Bookings;
