import client from 'graphql/client';
import schemas from 'graphql/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getAll = createAsyncThunk('categories/get', async (_, api) => {
  try {
    const {
      data: { findTests: tests },
    } = await client.query({
      query: schemas.FIND_TESTS,
      fetchPolicy: 'network-only',
    });

    return tests;
  } catch (error: any) {
    return api.rejectWithValue(error.message);
  }
});

const operations = { getAll };

export default operations;
