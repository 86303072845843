import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  parse,
  startOfToday,
  format,
  startOfTomorrow,
  compareAsc,
  isValid,
} from 'date-fns';
import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import { GoogleMapsAPIContext as PlacesAPIContext } from '../App';
import Card from '../components/Card';
import Carousel from '../components/Carousel';
import CentersQuantity from '../components/CentersQuantity';
import { DayPicker, DatePicker } from '../components/DatePickers';
import Header from '../components/Desktop/Header';
import Sort from '../components/Desktop/Sort';
import Filter from '../components/Filter';
import { normalize } from '../components/Filter';
import GoogleMap from '../components/Map';
import { CentersUnavailable } from '../components/Unavailable';
import Progress from '../components/Progress';
import Redirect from '../components/Redirect';
import Toggle from '../components/Toggle';
import { ECriteria } from '../components/Toggle/Toggle.types';
import Feature from '../components/Feature';
import {
  PRIMA_POSSIBILE_DATE,
  THRESHOLDS,
  CDI_CHAIN_LABEL,
} from '../constants';
import schemas from '../graphql/schemas';
import useSocket from '../hooks/useSocket';
import {
  LogoAlternative,
  Magnifier,
  Strain,
  Map,
  Order,
  Options,
} from '../icons-components/Icons';
import Slots from '../pages/Slots';
import {
  setCenters,
  setStaff,
  setFilter,
  setChainsNamesWithAutomaticApiType,
} from '../redux/filter/slice';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { updatePlace, updateDate, updateTest } from '../redux/query/querySlice';
import {
  getQueryTestID,
  getQueryTestName,
  getQueryPlaceCoords,
  getQueryPlaceAddress,
  getQueryDate,
  getQueryFallbackDestination,
} from '../redux/query/selectors';
import { store } from '../redux/store';
import paths from '../routes/paths';
import {
  ISeoCitySlot,
  Slot,
  Framed,
  TWithinPublicSystemTimeSlots,
  TSlotsTreatedAsPublicSystemOnes,
} from '../types/models';
import utils from '../utils/date';
import filtrate from '../utils/filter';
import {
  InfoBox,
  InfoIconBox,
  InfoList,
  InfoListItem,
} from './_Results.styled';

import { CentersBox } from './StaticPages/MedicalServiceInCity/Centers/Centers.styled';
import { ApiTypes } from '../types/models';
import { ToggleShowMoreButton } from '../components/StaticPage/ShowMoreButton/ShowMoreButton';
import { MuiModal } from '../components/MuiStyledComponents';
import classNames from 'classnames';
import { MuiTypography } from '../components/MuiStyledComponents';

// Filters
// import { CentersFilters } from './StaticPages/MedicalServiceInCity/Centers/CentersFilters.component';

// TODO: soonest! + change Header date prop

export enum EModalContent {
  DatePicker = 'DatePicker',
  Redirect = 'Redirect',
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            '&.Actions': {
              marginBottom: '12px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              [theme.breakpoints.up('lg')]: {
                marginBottom: 0,
              },
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
            width: 'auto',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.Query': {
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#263238',
            },
            '&.Meta': {
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#263238',
              textTransform: 'capitalize',
            },
            '&.CentersQuantity': {
              fontWeight: 800,
              fontSize: '24px',
              lineHeight: 1.3,
              color: '#263238',
            },
            '&.WeekDay': {
              fontWeight: 500,
              fontSize: '14px',
              color: '#607d8b',
              lineHeight: 1.5,
              letterSpacing: '0.2px',
              textTransform: 'capitalize',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '9px 16px',
            fontFamily: 'inherit',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: 1.4,
            letterSpacing: '0.3px',
            textTransform: 'none',
            color: '#607D8B',
            border: '1px solid #B0BEC5',
            borderRadius: '12px',
            '&:hover, &:focus': {
              borderColor: '#00838F',
            },
          },
          startIcon: {
            color: '#00838F',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            // marginBottom: '12px',
            borderColor: '#B0BEC5',
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            height: '100%',
            background: '#fff',
            overflow: 'scroll',
            [theme.breakpoints.down('lg')]: {
              '& .MuiBackdrop-root': {
                backgroundColor: '#fff',
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 0,
            '&.ToggleDatepicker': {
              marginLeft: 'auto',
              width: '40px',
              height: '40px',
              background: '#F4F8FB',
              borderRadius: '12px',
              '& > svg': {
                width: '18px',
                height: '18px',
              },
            },
          },
        },
      },
    },
  });
};

export const sortingAlgs: any = {
  priceAsc: (a: any, b: any): any => a.price - b.price,
  priceDesc: (a: any, b: any): any => b.price - a.price,
  distanceAsc: (a: any, b: any): any =>
    Number(a.distance.split('km')[0]) - Number(b.distance.split('km')[0]),
  default: (a: Framed, b: Framed) => {
    if (
      (a.center.apiType === ApiTypes.AUTOMATIC &&
        (b.center.apiType === ApiTypes.MANUAL ||
          b.center.apiType === ApiTypes.REDIRECT)) ||
      (a.center.apiType === ApiTypes.MANUAL &&
        b.center.apiType === ApiTypes.REDIRECT)
    ) {
      return -1;
    } else if (a.center.apiType === b.center.apiType) {
      const comparison = compareAsc(
        parse(a.slots[0].date, 'yyyy-MM-dd', startOfToday()),
        parse(b.slots[0].date, 'yyyy-MM-dd', startOfToday()),
      );

      if (comparison === 0 && !!a.distance && !!b.distance) {
        return (
          Number(a.distance.split('km')[0]) - Number(b.distance.split('km')[0])
        );
      }

      return comparison;
    } else {
      return 1;
    }
  },
  soonest: (a: Framed, b: Framed) =>
    utils.getFirstAvailTimeInMs(a.slots[0]) -
    utils.getFirstAvailTimeInMs(b.slots[0]),
};

const sortKeys = (obj: Record<string, boolean>) => {
  const sorted: Record<string, boolean> = {};
  const keys = Object.keys(obj);
  const sortedKeys = keys.sort((a: string, b: string) => a.localeCompare(b));
  for (const key of sortedKeys) {
    sorted[key] = obj[key];
  }
  return sorted;
};

const hasCoords = (arr: [number, number]) =>
  arr.every((el: number) => el !== 0);

const getLabel = (key: string) => {
  switch (key) {
    case 'priceAsc':
      return 'Prezzo dal più basso al più alto.';
    case 'priceDesc':
      return 'Prezzo dal più alto al più basso.';
    case 'distanceAsc':
      return 'Distanza.';
    default:
      return 'Rilevanza.';
  }
};

const PARTIAL_SIZE = 8;

const checkSearchParamsDateValidity = (
  searchParamsDate: string | null | undefined,
) => {
  return (
    !!searchParamsDate &&
    isValid(parse(searchParamsDate, 'yyyy-MM-dd', new Date())) &&
    compareAsc(
      parse(searchParamsDate, 'yyyy-MM-dd', new Date()),
      startOfTomorrow(),
    ) === 1
  );
};

const Results = () => {
  const isQueryWithinPublicSystem = false;
  const isQueryWithinPublicSystemWithoutReceipt = false;
  const shouldUIBeCut = false;
  const queryWithinPublicSystemReceiptTestName = null;
  const queryWithinPublicSystemRegionName = null;
  const queryWithinPublicSystemWithoutReceiptTestName = null;
  const queryWithinPublicSystemWithoutReceiptRegionName = null;

  const [searchParams, setSearchParams] = useSearchParams();
  const [params] = useState<{
    testID: string | null;
    placeID: string | null;
    coords: [number, number];
    dest: string | null;
    stamp?: string | null;
    // should be a string
    sort: any;
    filter: any;
  }>(() => ({
    testID: searchParams.get('testID'),
    placeID: searchParams.get('placeID'),
    coords: [Number(searchParams.get('lat')), Number(searchParams.get('lng'))],
    stamp: searchParams.get('stamp'),
    dest: searchParams.get('dest'),
    sort: searchParams.get('sort'),
    filter: searchParams.get('filter')
      ? JSON.parse(searchParams.get('filter') as string)
      : null,
  }));

  // const [centerID, setCenterID] = useState<string | null>(searchParams.get('centerId'));
  const [centerID, setCenterID] = useState<string | null>(null);

  let testID = useAppSelector(getQueryTestID);
  testID = Boolean(testID) ? testID : params.testID;

  const [findTestByID, { data: testFallback }] = useLazyQuery(
    schemas.FIND_TEST_BY_ID,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    findTestByID({
      variables: { id: params.testID },
      onCompleted: (data: any) => {
        // console.log(data, 'onCompleted');
        dispatch(
          updateTest({
            testID: data?.findTestById.id,
            test: data?.findTestById.name,
          }),
        );
      },
    });
  }, [params.testID]); // eslint-disable-line react-hooks/exhaustive-deps

  const place = useAppSelector(getQueryPlaceAddress);

  useEffect(() => {
    if (Boolean(place)) return;
    const payload = {
      id: params.placeID,
      address: params.dest,
      coords: params.coords,
    };

    dispatch(updatePlace(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.placeID]);

  // console.log(testFallback, "testFallback");

  let testName = useAppSelector(getQueryTestName);
  testName = Boolean(testName)
    ? testName
    : testFallback
    ? testFallback.findTestById.name
    : '...';

  let coords = useAppSelector(getQueryPlaceCoords);
  coords = hasCoords(coords) ? coords : params.coords;

  const day = useAppSelector(getQueryDate);
  // const day: any = "";
  // const date = day === "Prima possibile" ? null : utils.getYYMMDD(utils.getParsedDate(day));
  const date =
    day === ''
      ? params.stamp
      : day === PRIMA_POSSIBILE_DATE
      ? null
      : utils.getYYMMDD(utils.getParsedDate(day));

  // console.log(date, '=== date');

  // const soonest = !day;
  const soonest = !Boolean(date);

  const destination = useAppSelector(getQueryFallbackDestination);
  const queryDestination = destination.length === 0 ? place : destination;

  useEffect(() => {
    if (
      // !params.stamp ||
      params.stamp &&
      (!isValid(parse(params.stamp, 'yyyy-MM-dd', new Date())) ||
        compareAsc(
          parse(params.stamp, 'yyyy-MM-dd', new Date()),
          startOfTomorrow(),
        ) !== 1)
    ) {
      searchParams.set('stamp', format(startOfTomorrow(), 'yyyy-MM-dd'));
      setSearchParams(searchParams, { replace: true });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.stamp]);

  // prev logics
  const [selected, setSelected] = useState<any>(() => {
    if (
      day === PRIMA_POSSIBILE_DATE &&
      checkSearchParamsDateValidity(params.stamp)
    ) {
      dispatch(
        updateDate(
          `${format(
            parse(params.stamp as string, 'yyyy-MM-dd', new Date()),
            'EEEE dd/MM/yy',
          )}`,
        ),
      );
    }

    if (day.length === 0) {
      const initial = params.stamp as string;
      let formatted;

      if (!initial) {
        dispatch(updateDate(PRIMA_POSSIBILE_DATE));
        return;
        // formatted = `${format(startOfTomorrow(), 'EEEE', )} ${format(startOfTomorrow(), 'dd/MM/yy')}`;
        // dispatch(updateDate(formatted));
        // return startOfTomorrow();
      }

      const parsed = parse(initial, 'yyyy-MM-dd', new Date());
      const valid = compareAsc(parsed, startOfTomorrow()) === 1;

      if (valid) {
        formatted = `${format(parsed, 'EEEE')} ${format(parsed, 'dd/MM/yy')}`;
      } else {
        formatted = `${format(startOfTomorrow(), 'EEEE')} ${format(
          startOfTomorrow(),
          'dd/MM/yy',
        )}`;
      }

      dispatch(updateDate(formatted));
      return valid ? parsed : startOfTomorrow();
    }
    return utils.getParsedDate(day);
  });

  const {
    data,
    setData,
    isLoading,
    isFinished,
    setIsLoading,
    offPrevious,
    changeUIDonDateChange,
    // setShouldSlotsRefetch,
  } = useSocket(centerID, testID, coords, date);

  const centersRef = useRef(data);
  centersRef.current = data;

  // FIXME: Temporary solution
  const [
    localChainsNamesWithAutomaticApiType,
    setLocalChainsNamesWithAutomaticApiType,
  ] = useState<string[]>([]);
  console.log(
    localChainsNamesWithAutomaticApiType,
    'localChainsNamesWithAutomaticApiType',
  );

  useEffect(() => {
    const chainsNamesWithAutomaticApiType = data.reduce<string[]>((a, c) => {
      if (
        a.indexOf(c.center.name) === -1 &&
        (c.center.apiType === ApiTypes.AUTOMATIC ||
          c.center.chainLabel === 'punti-raf')
      ) {
        a.push(c.center.name);
      }
      return a;
    }, []);

    if (!chainsNamesWithAutomaticApiType.length) {
      return;
    }

    console.log({ data, chainsNamesWithAutomaticApiType });

    setLocalChainsNamesWithAutomaticApiType(chainsNamesWithAutomaticApiType);
    dispatch(
      setChainsNamesWithAutomaticApiType(chainsNamesWithAutomaticApiType),
    );
  }, [data, dispatch]);

  const [fakeLoading, setFakeLoading] = useState(true);
  const handleDateChange = (date: any) => {
    setData([]);
    setIsLoading(true);
    setCenterID(null);
    changeUIDonDateChange();
    setFakeLoading(true);

    if (date.length === 0 || date === PRIMA_POSSIBILE_DATE) {
      const today = startOfToday();
      const formatted = `${format(today, 'EEEE')} ${format(today, 'dd/MM/yy')}`;
      dispatch(updateDate(formatted));
      return setSelected(today);
    }

    const result = `${format(date, 'EEEE')} ${format(date, 'dd/MM/yy')}`;

    const dateParam = utils.getYYMMDD(date) as string;

    searchParams.delete('filter');
    searchParams.set('stamp', dateParam);
    setSearchParams(searchParams, { replace: true });

    dispatch(updateDate(result));
    dispatch(
      setFilter({
        onecheck: { isOn: false },
        centers: {},
        staff: {},
        timeframe: {
          morning: false,
          afternoon: false,
          evening: false,
        },
        chainsNamesWithAutomaticApiType: [],
      }),
    );

    setSelected(date);

    offPrevious();
  };

  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          onecheck: { isOn: false },
          centers: {},
          staff: {},
          timeframe: {
            morning: false,
            afternoon: false,
            evening: false,
          },
          chainsNamesWithAutomaticApiType: [],
        }),
      );
    };
  }, [dispatch]);

  // prev logics
  const [findLogos, { data: lazyData }] = useLazyQuery(schemas.FIND_LOGOS);

  useEffect(() => {
    findLogos();
  }, [findLogos]);

  const framed: Framed[] = data.map((avail: ISeoCitySlot) => {
    const { slots } = avail;
    if (slots.length === 0) {
      return { ...avail, frame: { morning: 0, afternoon: 0, evening: 0 } };
    }

    const frame = slots?.reduce(
      (
        acc: { morning: number; afternoon: number; evening: number },
        slot: Slot,
      ) => {
        const { time } = slot;
        const start = time.split(' - ')[0].split(':').join('');
        const threshold = Number(start);

        if (threshold >= 800 && threshold <= THRESHOLDS['morning']) {
          return { ...acc, morning: acc['morning'] + 1 };
        } else if (
          threshold > THRESHOLDS['morning'] &&
          threshold <= THRESHOLDS['afternoon']
        ) {
          return { ...acc, afternoon: acc['afternoon'] + 1 };
        } else if (
          threshold > THRESHOLDS['afternoon'] &&
          threshold <= THRESHOLDS['evening']
        ) {
          return { ...acc, evening: acc['evening'] + 1 };
        }
        return acc;
      },

      { morning: 0, afternoon: 0, evening: 0 },
    );
    return { ...avail, frame };
  });

  const withLogos = useMemo(() => {
    const logos = lazyData?.findChains;
    return framed.map((avail: Framed) => {
      const name = avail.center.name;
      const logo = logos?.find((logo: any) => logo.name === name);
      return { ...avail, logo: logo?.logo };
    });
  }, [framed, lazyData]);

  const filter = store.getState().filter;

  useEffect(() => {
    // if (!isFinished) return;

    // console.log("This useEffect() works!");

    const centers = withLogos.reduce((acc: any, val) => {
      const {
        center: { name },
      } = val;
      if (acc.hasOwnProperty(name)) return acc;
      return { ...acc, [name]: false };
    }, {});

    const staff = withLogos
      .filter(({ center }) => !center['apiUrl'])
      .reduce((acc: any, val) => {
        const { slots } = val;
        const doctors = slots.reduce((acc: any, slot: Slot) => {
          const { doctor } = slot;

          if (acc.hasOwnProperty(doctor)) return acc;
          return { ...acc, [doctor]: false };
        }, {});
        return { ...acc, ...doctors };
      }, {});

    // const staffSorted = params.filter ? sortKeys(params.filter.staff) : sortKeys(staff);
    // const centersSorted = params.filter ? sortKeys(params.filter.centers) : sortKeys(centers);
    const staffSorted = sortKeys(staff);
    const centersSorted = sortKeys(centers);

    let renewedCenters;
    if (
      Object.keys(filter['centers']).length === 0 &&
      !filter['onecheck'].isOn &&
      params?.filter?.centers
    ) {
      renewedCenters = { ...centersSorted, ...params?.filter?.centers };
    } else {
      renewedCenters = { ...centersSorted, ...filter['centers'] };
      // console.log(renewedCenters, 'renewedCenters work!');
    }

    let renewedStaff;
    if (
      Object.keys(filter['staff']).length === 0 &&
      !filter['onecheck'].isOn &&
      params?.filter?.staff
    ) {
      renewedStaff = { ...staffSorted, ...params?.filter?.staff };
    } else {
      renewedStaff = { ...staffSorted, ...filter['staff'] };
    }

    // console.log(renewedStaff, 'renewedStaff');

    dispatch(setCenters(renewedCenters));
    dispatch(setStaff(renewedStaff));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, framed]);
  // }, [framed, dispatch]);
  // const staff = useMemo(() => {
  //   const doctors = framed.reduce((acc:any, val) => {
  //     const { slots } = val;
  //     const doctors = slots.reduce((acc:any, slot) => {
  //       const { doctor } = slot;

  //       if (acc.hasOwnProperty(doctor)) return acc;
  //       return { ...acc, [doctor]: false };
  //     }, {});
  //     return { ...acc, ...doctors };
  //   }, {});
  //   dispatch(setStaff(doctors));
  // }, [framed, dispatch]);

  // console.log(staff, 'staff');

  // 1. Framed.
  // 2. Filters applied.
  // 3. Filtered array received.
  // 4. Filtered reduced and new values are set.

  // const filter = useAppSelector(getFilter);
  // console.log(filter, 'FILTER STATE _RESULTS');
  // const gathered = filtrate.getCriteria(filter);
  // const unfiltered = Object.entries(gathered).length === 0;

  const filtered = useMemo(() => {
    // console.log(filter, 'filter changed!');

    const result = filtrate.withMultiple(withLogos, filter);

    // console.log(result, 'RESULT FILTER');

    return filtrate.withMultiple(withLogos, filter);
  }, [withLogos, filter]);

  // useEffect(() => {
  //   if (!isFinished || unfiltered) return;

  //   if (gathered.hasOwnProperty('centers')) {
  //     const staff = filtered.reduce((acc:any, val) => {
  //       const { slots } = val;
  //       const doctors = slots.reduce((acc:any, slot) => {
  //         const { doctor } = slot;

  //         if (acc.hasOwnProperty(doctor)) return acc;
  //         return { ...acc, [doctor]: false };
  //       }, {});
  //       return { ...acc, ...doctors };
  //     }, {});
  //     dispatch(setStaff(staff));
  //   }
  // }, [isFinished, gathered]);

  // console.log(filtered, 'filtered');

  // console.log(data, 'data');
  // console.log(isLoading, 'isLoading');
  // console.log(isFinished, 'isFinished');

  // console.log(filtered, 'filtered');

  const desktop = useMediaQuery('(min-width:1200px)');
  const navigate = useNavigate();

  // prev logics

  const [view, setView] = useState('list');
  const [isModalOpen, setIsModalOpen] = useState<any>({
    isOpen: false,
    content: null,
  });
  const [dialog, setDialog] = useState({ isOpen: false, content: '' });

  // prev logics

  const [sortBy, setSortBy] = useState(() => params.sort ?? 'default');

  useEffect(() => {
    if (params.sort && sortingAlgs[params.sort]) return;

    searchParams.set('sort', 'default');
    setSearchParams(searchParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sort]);

  const onSortByChange = (newAlg: string) => {
    setSortBy(newAlg);
    searchParams.set('sort', newAlg);
    setSearchParams(searchParams, { replace: true });
  };

  const filteredByChain = framed;

  const sorted = useMemo(() => {
    if (!sortBy) {
      return shouldUIBeCut ? filteredByChain : filtered;
    }

    //
    return [...(shouldUIBeCut ? filteredByChain : filtered)].sort(
      sortingAlgs[sortBy],
    );
  }, [filtered, sortBy, shouldUIBeCut]);

  const [shown, setShown] = useState<any>([]);
  const [hasMore, setHasMore] = useState(false);

  // useEffect(() => {
  //   if (sorted.length < 10) return setShown(sorted);

  //   setShown(sorted.slice(0, 10));
  //   setHasMore(true);
  // }, [sorted]);

  const observer = useRef<any>(null);
  const last = (node: any) => {
    if (!hasMore || !isFinished) return;

    if (observer && observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries: any) => {
      if (entries[0].isIntersecting) {
        // console.log('Intersecting!');
        setShown((prev: any) => [...prev, ...sorted.slice(10)]);
        setHasMore(false);
      }
    });
    if (node) {
      observer.current.observe(node);
    }
  };

  const isMapLoaded = useContext(PlacesAPIContext);
  const [curr, setCurr] = useState(0);
  const [lat, lng] = coords;

  // const [key, setKey] = useState<null | string>(null);
  // const memoized = useCallback((key: string) => {
  //   setKey(key);
  // }, []);

  // console.log(framed, 'framed');

  const reordered = useMemo(() => {
    if (curr === 0) {
      return sorted;
    }
    const copy = [...sorted];
    const [el] = copy.splice(curr, 1);
    copy.splice(0, 0, el);
    return copy;
  }, [curr, sorted]);

  const [soonestDate, setSoonestDate] = useState<string>('');

  const [external, setExternal] = useState<{
    open: boolean;
    href: string;
    content: EModalContent | null;
  }>({
    open: false,
    href: '#',
    content: null,
  });

  const [toggle, setToggle] = useState(false);

  const onFormSubmit = (values: any) => {
    // console.log(values, 'onFormSubmitValues');

    const { staff } = values;
    const normalized = normalize(staff, 'opposite');
    let personnel = null;
    let dests = null;

    const payload = { ...values, staff: normalized };
    // console.log(res, 'res filtered');
    // console.log(unfiltered, "unfiltered");
    // console.log(payload, "payload");
    // console.log(res, 'res');
    const test = filtrate.withMultiple(withLogos, {
      ...values,
      staff: normalized,
      onecheck: {
        isOn: values.onecheck.isOn,
      },
    });
    // console.log(test, 'filtrate withMultiple');

    const positives = filtrate.getCriteria({
      ...values,
      staff: normalized,
      onecheck: { isOn: values.onecheck },
    });
    const invalid = Object.entries(positives).length === 0;

    // console.log(positives.hasOwnProperty('onecheck'), 'hasOneCheck');

    // console.log(positives.hasOwnProperty('centers'), 'hasCenters');
    // console.log(positives.hasOwnProperty('staff'), 'hasStaff');

    if (
      positives.hasOwnProperty('centers') &&
      !positives.hasOwnProperty('staff')
      // (!positives.hasOwnProperty('centers') &&
      //   !positives.hasOwnProperty('staff'))
    ) {
      personnel = test.reduce((acc: any, val: any) => {
        const { slots } = val;
        const doctors = slots.reduce((acc: any, slot: any) => {
          const { doctor } = slot;

          if (acc.hasOwnProperty(doctor)) return acc;
          return { ...acc, [doctor]: false };
        }, {});
        return { ...acc, ...doctors };
      }, {});
      console.log(personnel, 'staff');
    } else if (
      positives.hasOwnProperty('staff') &&
      !positives.hasOwnProperty('centers')
      // (!positives.hasOwnProperty('staff') &&
      //   !positives.hasOwnProperty('centers'))
    ) {
      dests = test?.reduce((acc: any, val: any) => {
        const {
          center: { name },
        } = val;
        if (acc.hasOwnProperty(name)) return acc;
        return { ...acc, [name]: false };
      }, {});
      // console.log(dests, 'dests');
    } else if (
      (!positives.hasOwnProperty('staff') &&
        !positives.hasOwnProperty('centers')) ||
      positives.hasOwnProperty('onecheck')
    ) {
      personnel = test?.reduce((acc: any, val: any) => {
        const { slots } = val;
        const doctors = slots.reduce((acc: any, slot: any) => {
          const { doctor } = slot;

          if (acc.hasOwnProperty(doctor)) return acc;
          return { ...acc, [doctor]: false };
        }, {});
        return { ...acc, ...doctors };
      }, {});
      dests = test?.reduce((acc: any, val: any) => {
        const {
          center: { name },
        } = val;
        if (acc.hasOwnProperty(name)) return acc;
        return { ...acc, [name]: false };
      }, {});
    }

    // console.log(doctors, 'DOC');
    // console.log(centers, 'CENTER');

    // for (const positive of positives) {
    //   values[positive] = positives[positive];
    // }
    // console.log(positive, 'positive');

    console.log({ dests, centers: values?.centers }, 'dests');

    const newValues = {
      ...values,
      staff: personnel ? personnel : normalized,
      centers: dests ? dests : values?.centers,
      onecheck: { isOn: values.onecheck.isOn },
      // chainsNamesWithAutomaticApiType: filter?.chainsNamesWithAutomaticApiType,
      chainsNamesWithAutomaticApiType: localChainsNamesWithAutomaticApiType,
    };

    // console.log(newValues, 'newValues');

    dispatch(setFilter(newValues));
    searchParams.set('filter', JSON.stringify(newValues));
    setSearchParams(searchParams, { replace: true });
    personnel = null;
    dests = null;
    // dispatch(setFilter({ ...values, staff: doctors, centers, ...positives }));

    // if (!isDesktop && handleClose) {
    //   handleClose();
    // }
  };

  const onToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;

    if (!desktop) {
      setToggle(checked);

      onFormSubmit({
        ...filter,
        onecheck: { isOn: checked },
        chainsNamesWithAutomaticApiType: localChainsNamesWithAutomaticApiType,
      });
    } else {
      setToggle(checked);
      dispatch(
        setFilter({
          ...filter,
          onecheck: { isOn: checked },
          chainsNamesWithAutomaticApiType: localChainsNamesWithAutomaticApiType,
        }),
      );
    }
  };

  useEffect(() => {
    setToggle(false);
  }, [selected, soonest]);

  function goBack() {
    navigate(-1);
  }

  const onModalOpen = (
    redirect: boolean,
    href: string,
    slots: any,
    chainLabel: string,
    center: any,
  ) => {
    setDialog({
      isOpen: true,
      content: isQueryWithinPublicSystemWithoutReceipt
        ? 'regime'
        : 'availabilities',
    });
  };

  const [partialsQuantity, setPartialsQuantity] = useState(() => PARTIAL_SIZE);
  const sortedSlotsPartial = useMemo<Framed[]>(() => {
    return (view === 'map' ? reordered : sorted).slice(0, partialsQuantity);
  }, [view, sorted, reordered, partialsQuantity]);
  const isFullList = sorted.length === sortedSlotsPartial.length;
  const toggleShowMore = () => {
    if (partialsQuantity >= sorted.length) {
      window.scrollTo({ top: 0 });
      setPartialsQuantity(PARTIAL_SIZE);
    } else {
      setPartialsQuantity(prev => prev + PARTIAL_SIZE);
    }
  };

  // Centro Diagnostico Italiano (CDI) slots treated as ones of a Public System
  const shouldTreatSlotAsPublicSystemOne = (chainLabel: string) =>
    chainLabel === CDI_CHAIN_LABEL;

  const [slotTreatedAsPublicSystemOne, setSlotTreatedAsPublicSystemOne] =
    useState(false);
  const [withinPublicSystemDateTimeSlots, setWithinPublicSystemDateTimeSlots] =
    useState<TWithinPublicSystemTimeSlots>(null);
  const [slotsTreatedAsPublicSystemOnes, setSlotsTreatedAsPublicSystemOnes] =
    useState<TSlotsTreatedAsPublicSystemOnes>(null);

  const [withinPublicSystemDateSelected, setWithinPublicSystemDateSelected] =
    useState(PRIMA_POSSIBILE_DATE);
  useEffect(() => {
    if (!!slotsTreatedAsPublicSystemOnes) {
      return setWithinPublicSystemDateSelected(
        utils.getDDMMYY(slotsTreatedAsPublicSystemOnes[0].date),
      );
    }
  }, [slotsTreatedAsPublicSystemOnes, withinPublicSystemDateTimeSlots]);
  const withinPublicSystemTimeSlotsForDateSelected = useMemo(() => {
    if (!!slotsTreatedAsPublicSystemOnes) {
      return slotsTreatedAsPublicSystemOnes.filter(
        dateTimeSlot =>
          dateTimeSlot.date ===
          utils.getYYMMDDFromString(withinPublicSystemDateSelected),
      );
    }
    return [];
  }, [slotsTreatedAsPublicSystemOnes, withinPublicSystemDateSelected]);

  const mapWrapperRef = useRef<HTMLDivElement>(null);
  const findReorderedSlotInitialId = (slotCenterId: string) => {
    return sorted.findIndex(slot => slot.center.id === slotCenterId) ?? 0;
  };
  const reorderSlotsOnSlotClick = (slotCenterId: string) => {
    const slotIdx = findReorderedSlotInitialId(slotCenterId);
    return setCurr(slotIdx);
  };
  const scrollMapIntoViewWithSlotsReordered = (slotCenterId: string) => {
    reorderSlotsOnSlotClick(slotCenterId);
    return mapWrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const isMapViewActive = useMemo(() => view === 'map', [view]);
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setFakeLoading(false);
    }, 10000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [date]);
  const slotsLoading = useMemo(
    () => isLoading || fakeLoading,
    [isLoading, fakeLoading],
  );

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Container>
        {desktop && (
          <Header
            isQueryShown
            test={
              isQueryWithinPublicSystem
                ? queryWithinPublicSystemReceiptTestName
                : isQueryWithinPublicSystemWithoutReceipt
                ? queryWithinPublicSystemWithoutReceiptTestName
                : testName
            }
            dest={
              isQueryWithinPublicSystem
                ? queryWithinPublicSystemRegionName
                : isQueryWithinPublicSystemWithoutReceipt
                ? queryWithinPublicSystemWithoutReceiptRegionName
                : queryDestination
            }
            date={shouldUIBeCut ? PRIMA_POSSIBILE_DATE : day}
            onQueryClick={() => navigate(`/${paths().search}`)}
          />
        )}
        {!desktop && (
          <Container>
            <Box
              sx={{
                marginBottom: '16px',
                paddingTop: '12px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link to={paths().search}>
                <LogoAlternative />
              </Link>
              <InfoBox onClick={goBack}>
                <InfoIconBox>
                  <Magnifier fill="#00838F" />
                </InfoIconBox>

                <InfoList>
                  <InfoListItem isBold>
                    {isQueryWithinPublicSystem
                      ? queryWithinPublicSystemReceiptTestName
                      : isQueryWithinPublicSystemWithoutReceipt
                      ? queryWithinPublicSystemWithoutReceiptTestName
                      : testName}
                  </InfoListItem>
                  <InfoListItem>{`${
                    isQueryWithinPublicSystem
                      ? queryWithinPublicSystemRegionName
                      : isQueryWithinPublicSystemWithoutReceipt
                      ? queryWithinPublicSystemWithoutReceiptRegionName
                      : queryDestination
                  } - ${
                    shouldUIBeCut ? PRIMA_POSSIBILE_DATE : day
                  }`}</InfoListItem>
                </InfoList>
              </InfoBox>
            </Box>
          </Container>
        )}
        {/* maxWidth to make cards width 740px */}
        {/* <Container sx={shouldUIBeCut ? { maxWidth: { lg: '788px' } } : {}}> */}
        <Container>
          <Box
            sx={
              desktop
                ? {
                    pt: '46px',
                    pb: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }
                : shouldUIBeCut
                ? {
                    marginBottom: '12px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }
                : {}
            }
          >
            {desktop && (
              <CentersQuantity
                finished={isFinished && !slotsLoading}
                quantity={(shouldUIBeCut ? filteredByChain : filtered)?.length}
              />
            )}
            <Feature on={!shouldUIBeCut}>
              <List className="Actions">
                {!desktop && (
                  <ListItem>
                    <Button
                      variant="outlined"
                      startIcon={<Strain fill="#00838F" />}
                      onClick={() =>
                        setIsModalOpen({ isOpen: true, content: 'filter' })
                      }
                      disabled={!filtered || filtered.length === 0}
                    >
                      {'Filtri'}
                    </Button>
                  </ListItem>
                )}
                {desktop && (
                  <ListItem sx={{ mr: '8px' }}>
                    <Toggle
                      criteria={ECriteria.ChainType}
                      label={'Conferma immediata'}
                      checked={toggle}
                      onChange={onToggle}
                    />
                  </ListItem>
                )}
                <ListItem sx={desktop ? { mr: '8px' } : {}}>
                  <Button
                    variant="outlined"
                    startIcon={
                      view === 'list' ? <Map /> : <Options fill="#00838F" />
                    }
                    onClick={() => {
                      if (view === 'list') {
                        setView('map');
                      } else {
                        setView('list');
                      }
                    }}
                    disabled={!filtered || filtered.length === 0}
                  >
                    {view === 'list' ? 'Mappa' : 'Lista'}
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    sx={view === 'map' ? { visibility: 'hidden' } : {}}
                    variant="outlined"
                    startIcon={<Order fill="#00838F" />}
                    disabled={!filtered || filtered.length === 0}
                    onClick={() =>
                      setDialog({ isOpen: true, content: 'order' })
                    }
                  >
                    {'Ordina'}
                  </Button>
                </ListItem>
              </List>
            </Feature>
            {shouldUIBeCut && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {!desktop && (
                    <Button
                      sx={{
                        marginRight: '5px',
                        '& .MuiButton-startIcon': {
                          width: '24px',
                          height: '24px',
                        },
                      }}
                      variant="outlined"
                      startIcon={<Strain fill="#00838F" />}
                      onClick={() =>
                        setIsModalOpen({ isOpen: true, content: 'filter' })
                      }
                      disabled={!filtered || filtered.length === 0}
                    >
                      {'Filtri'}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    startIcon={
                      view === 'list' ? <Map /> : <Options fill="#00838F" />
                    }
                    onClick={() => {
                      if (view === 'list') {
                        setView('map');
                      } else {
                        setView('list');
                      }
                    }}
                    disabled={!filtered || filtered.length === 0}
                  >
                    {view === 'list' ? 'Mappa' : 'Lista'}
                  </Button>
                </Box>
              </>
            )}
            {!desktop && (
              <Box
                sx={{
                  mb: shouldUIBeCut ? 0 : '12px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <CentersQuantity
                  finished={isFinished && !slotsLoading}
                  quantity={
                    (shouldUIBeCut ? filteredByChain : filtered)?.length
                  }
                />
                <Feature on={!shouldUIBeCut}>
                  <Toggle
                    criteria={ECriteria.ChainType}
                    label={'Conferma immediata'}
                    checked={toggle}
                    onChange={onToggle}
                  />
                </Feature>
              </Box>
            )}
          </Box>
          {!desktop && isQueryWithinPublicSystemWithoutReceipt && (
            <Box sx={{ mt: '6px', mb: '20px' }}>
              <MuiTypography.PublicSystemCaption>
                {
                  'I dati pubblicati si riferiscono a tutta l’offerta fornita dalle aziende sanitarie, indipendentemente dai dettagli dell’impegnativa.'
                }
              </MuiTypography.PublicSystemCaption>
              <MuiTypography.PublicSystemCaption
                sx={{ mt: '20px' }}
                className={'bold'}
              >
                {
                  'Verifica cliccando sulla data di preferenza se tale opzione è compatibile con la tua ricetta.'
                }
              </MuiTypography.PublicSystemCaption>
            </Box>
          )}
        </Container>
        {!desktop && <Divider />}
        {desktop ? (
          // maxWidth to make cards width 740px
          // <Container sx={shouldUIBeCut ? { maxWidth: { lg: '788px' }, pb: '16px' } : {}}>
          <Container>
            <Grid container spacing={0}>
              {shouldUIBeCut && (
                <Grid item xs={3}>
                  {/* <CentersFilters
                  shouldTrack={false}
                  toggleView={() => {}}
                  currentSortingOption={'default'}
                  setCurrentSortingOption={() => {}}
                  chainsNamesOnly
                  centersLoading={isLoading}
                /> */}
                </Grid>
              )}

              <Feature on={!shouldUIBeCut}>
                <Grid item xs={3}>
                  <Filter
                    toggle={toggle}
                    isLoading={slotsLoading}
                    res={filtered}
                    unfiltered={withLogos}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    localChainsNamesWithAutomaticApiType={
                      localChainsNamesWithAutomaticApiType
                    }
                  />
                </Grid>
              </Feature>
              {/* <Grid sx={shouldUIBeCut ? {} : { pl: '24px' }} item xs={shouldUIBeCut ? 12 : 9}> */}
              <Grid sx={{ pl: '24px' }} item xs={9}>
                {view === 'list' ? (
                  <>
                    {!soonest && !shouldUIBeCut && (
                      <DayPicker
                        selectedDate={selected}
                        handleDateChange={handleDateChange}
                      />
                    )}
                    {slotsLoading ? (
                      <Progress />
                    ) : filtered && filtered.length === 0 ? (
                      <CentersUnavailable
                        searchSoonest={() =>
                          dispatch(updateDate('Prima possibile'))
                        }
                      />
                    ) : (
                      <CentersBox className={'res-page'}>
                        <TransitionGroup>
                          {filtered &&
                            // DESKTOP
                            sortedSlotsPartial.map(
                              (filtered: any, index: number) => {
                                const {
                                  center,
                                  distance,
                                  price,
                                  slots,
                                  logo,
                                  frame: avails,
                                } = filtered;
                                const [slot] = slots;

                                const redirect =
                                  center.apiType === ApiTypes.REDIRECT;

                                return (
                                  <Slide key={center?.id} direction="up">
                                    <ListItem
                                      sx={{ mb: '12px' }}
                                      ref={index === 9 ? last : null}
                                    >
                                      <Card
                                        soonest={soonest}
                                        id={center?.id}
                                        key={center?.id}
                                        // day={day}
                                        slot={slot}
                                        destination={center?.centerName}
                                        address={center?.address}
                                        distance={distance}
                                        price={price}
                                        styles={
                                          desktop ? { width: '100%' } : {}
                                        }
                                        availability={avails}
                                        onModalOpen={() => {
                                          if (isQueryWithinPublicSystem) {
                                            setWithinPublicSystemDateTimeSlots(
                                              slots,
                                            );
                                          } else if (
                                            shouldTreatSlotAsPublicSystemOne(
                                              center.chainLabel,
                                            )
                                          ) {
                                            setSlotTreatedAsPublicSystemOne(
                                              true,
                                            );
                                            setSlotsTreatedAsPublicSystemOnes(
                                              slots.map((slot: any) => ({
                                                ...slot,
                                                center,
                                              })),
                                            );
                                          } else {
                                            setSlotTreatedAsPublicSystemOne(
                                              false,
                                            );
                                          }

                                          setDialog({
                                            isOpen: true,
                                            content:
                                              isQueryWithinPublicSystemWithoutReceipt
                                                ? 'regime'
                                                : 'availabilities',
                                          });
                                        }}
                                        setDate={setSelected}
                                        setCenterID={setCenterID}
                                        logo={logo}
                                        setSoonestDate={setSoonestDate}
                                        redirect={redirect}
                                        apiType={center.apiType}
                                      />
                                    </ListItem>
                                  </Slide>
                                );
                              },
                            )}
                        </TransitionGroup>
                        {sorted.length > PARTIAL_SIZE && (
                          <ToggleShowMoreButton
                            style={{ marginBottom: '12px' }}
                            centered
                            isFullList={isFullList}
                            toggleShowMore={toggleShowMore}
                          />
                        )}
                      </CentersBox>
                    )}
                  </>
                ) : (
                  <Box sx={{ overflow: 'hidden' }} ref={mapWrapperRef}>
                    {isMapLoaded ? (
                      <GoogleMap
                        // curr={curr}
                        center={{ lat, lng }}
                        testingCenters={sorted}
                        onMarkerClick={setCurr}
                        isFinished={isFinished}
                        idx={curr}
                      />
                    ) : (
                      <CircularProgress />
                    )}
                    <Box
                      sx={{
                        p: desktop ? '15px 0px' : '15px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {desktop && !soonest && (
                        <DayPicker
                          selectedDate={selected}
                          handleDateChange={handleDateChange}
                        />
                      )}
                      <CentersBox>
                        <List
                          className="MapViewCardsList"
                          sx={
                            desktop
                              ? {
                                  display: 'block',
                                  transform: 'none',
                                  transition: 'none',
                                }
                              : {
                                  display: 'flex',
                                  transform: `translateX(${-325 * curr}px)`,
                                  transition: 'transform 500ms linear',
                                }
                          }
                        >
                          {slotsLoading ? (
                            <Progress quantity={5} />
                          ) : (
                            reordered &&
                            sortedSlotsPartial.map(
                              (filtered: any, index: number) => {
                                const {
                                  center,
                                  distance,
                                  price,
                                  slots,
                                  frame: availability,
                                  logo,
                                } = filtered;
                                const [slot] = slots;
                                const redirect =
                                  center.apiType === ApiTypes.REDIRECT;

                                return (
                                  <ListItem
                                    key={center.id}
                                    sx={
                                      desktop
                                        ? {
                                            width: '100%',
                                            '&:not(:last-of-type)': {
                                              mb: '12px',
                                            },
                                          }
                                        : {
                                            flexShrink: 0,
                                            width: '325px',
                                            mr: '12px',
                                          }
                                    }
                                  >
                                    <Card
                                      id={center?.id}
                                      key={center?.id}
                                      slot={slot}
                                      destination={center?.centerName}
                                      address={center?.address}
                                      distance={distance}
                                      price={price}
                                      availability={availability}
                                      styles={
                                        index === 0
                                          ? {
                                              border: '1px solid #000000',
                                              width: '100%',
                                            }
                                          : {
                                              width: '100%',
                                            }
                                      }
                                      onModalOpen={() => {
                                        if (isQueryWithinPublicSystem) {
                                          setWithinPublicSystemDateTimeSlots(
                                            slots,
                                          );
                                        } else if (
                                          shouldTreatSlotAsPublicSystemOne(
                                            center.chainLabel,
                                          )
                                        ) {
                                          setSlotTreatedAsPublicSystemOne(true);
                                          setSlotsTreatedAsPublicSystemOnes(
                                            slots.map((slot: any) => ({
                                              ...slot,
                                              center,
                                            })),
                                          );
                                        } else {
                                          setSlotTreatedAsPublicSystemOne(
                                            false,
                                          );
                                        }

                                        setDialog({
                                          isOpen: true,
                                          content:
                                            isQueryWithinPublicSystemWithoutReceipt
                                              ? 'regime'
                                              : 'availabilities',
                                        });
                                      }}
                                      soonest={soonest}
                                      setDate={setSelected}
                                      logo={logo}
                                      setSoonestDate={setSoonestDate}
                                      redirect={redirect}
                                      apiType={center.apiType}
                                      isMapViewActive={isMapViewActive}
                                      onSlotClickWithMapViewActive={() =>
                                        scrollMapIntoViewWithSlotsReordered(
                                          center.id,
                                        )
                                      }
                                    />
                                  </ListItem>
                                );
                              },
                            )
                          )}
                        </List>
                        {sorted.length > PARTIAL_SIZE && (
                          <ToggleShowMoreButton
                            style={{ marginTop: '12px', marginBottom: '12px' }}
                            centered
                            isFullList={isFullList}
                            toggleShowMore={toggleShowMore}
                          />
                        )}
                      </CentersBox>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
        ) : view === 'list' ? (
          slotsLoading ? (
            <Container sx={{ pt: '12px', pb: '12px' }}>
              <Progress />
            </Container>
          ) : filtered && filtered.length === 0 ? (
            <Container sx={{ pt: '12px' }}>
              <CentersUnavailable
                searchSoonest={() => dispatch(updateDate('Prima possibile'))}
              />
            </Container>
          ) : (
            <>
              <Container sx={{ pt: '12px' }}>
                <CentersBox>
                  <TransitionGroup>
                    {filtered &&
                      sortedSlotsPartial.map((filtered: any) => {
                        const {
                          center,
                          distance,
                          price,
                          slots,
                          frame: availability,
                          logo,
                        } = filtered;
                        const [slot] = slots;
                        const redirect = center.apiType === ApiTypes.REDIRECT;

                        return (
                          <Slide direction="up" key={center?.id}>
                            <Box key={center?.id} sx={{ mb: '12px' }}>
                              <Card
                                id={center?.id}
                                key={center?.id}
                                slot={slot}
                                destination={center?.centerName}
                                address={center?.address}
                                distance={distance}
                                price={price}
                                availability={availability}
                                soonest={soonest}
                                setDate={setSelected}
                                logo={logo}
                                onModalOpen={() => {
                                  if (isQueryWithinPublicSystem) {
                                    setWithinPublicSystemDateTimeSlots(slots);
                                  } else if (
                                    shouldTreatSlotAsPublicSystemOne(
                                      center.chainLabel,
                                    )
                                  ) {
                                    setSlotTreatedAsPublicSystemOne(true);
                                    setSlotsTreatedAsPublicSystemOnes(
                                      slots.map((slot: any) => ({
                                        ...slot,
                                        center,
                                      })),
                                    );
                                  } else {
                                    setSlotTreatedAsPublicSystemOne(false);
                                  }

                                  setDialog({
                                    isOpen: true,
                                    content:
                                      isQueryWithinPublicSystemWithoutReceipt
                                        ? 'regime'
                                        : 'availabilities',
                                  });
                                }}
                                setSoonestDate={setSoonestDate}
                                redirect={redirect}
                                apiType={center.apiType}
                              />
                            </Box>
                          </Slide>
                        );
                      })}
                  </TransitionGroup>
                  {sorted.length > PARTIAL_SIZE && (
                    <ToggleShowMoreButton
                      style={{ marginBottom: '12px' }}
                      centered
                      isFullList={isFullList}
                      toggleShowMore={toggleShowMore}
                    />
                  )}
                </CentersBox>
              </Container>
            </>
          )
        ) : (
          <Box sx={{ overflow: 'hidden' }}>
            {isMapLoaded ? (
              <GoogleMap
                center={{ lat, lng }}
                testingCenters={sorted}
                onMarkerClick={setCurr}
                isFinished={isFinished}
                idx={curr}
              />
            ) : (
              <CircularProgress />
            )}
            <Box>
              <Carousel
                avails={sorted}
                slideTo={curr}
                handleSlideChange={setCurr}
                onModalOpen={onModalOpen}
                soonest={soonest}
              />
            </Box>
          </Box>
        )}
        <Modal
          open={isModalOpen.isOpen}
          onClose={() => setIsModalOpen({ isOpen: false, content: 'null' })}
          hideBackdrop
          closeAfterTransition
        >
          <Box sx={{ overflow: 'scroll' }}>
            {(() => {
              switch (isModalOpen.content) {
                case 'filter':
                  return shouldUIBeCut ? (
                    // <CentersFilters
                    //   centersLoading={isLoading}
                    //   currentSortingOption={'default'}
                    //   setCurrentSortingOption={() => {}}
                    //   shouldTrack={false}
                    //   toggleView={() => {}}
                    //   chainsNamesOnly
                    //   closeExternalModal={() =>
                    //     setIsModalOpen({ isOpen: false, content: 'null' })
                    //   }
                    // />
                    <></>
                  ) : (
                    <Filter
                      toggle={toggle}
                      isLoading={slotsLoading}
                      res={filtered}
                      unfiltered={withLogos}
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                      handleClose={() =>
                        setIsModalOpen({ isOpen: false, content: 'null' })
                      }
                      localChainsNamesWithAutomaticApiType={
                        localChainsNamesWithAutomaticApiType
                      }
                    />
                  );
              }
            })()}
          </Box>
        </Modal>
        <MuiModal
          className={classNames('res-page-parent-modal', {
            sort: dialog.content === 'order',
            regime: dialog.content === 'regime',
          })}
          open={dialog.isOpen}
          onClose={() => {
            setDialog({ isOpen: false, content: '' });
          }}
        >
          <Box>
            {(() => {
              switch (dialog.content) {
                case 'availabilities':
                  return (
                    <>
                      <Slots
                        onModalClose={() => {
                          setDialog({ isOpen: false, content: '' });
                        }}
                        soonestDate={soonestDate}
                        setExternal={setExternal}
                        withinPublicSystemSlots={
                          withinPublicSystemTimeSlotsForDateSelected
                        }
                        withinPublicSystemDateSelected={
                          withinPublicSystemDateSelected
                        }
                        shouldTreatSlotAsPublicSystemOne={
                          slotTreatedAsPublicSystemOne
                        }
                        setWithinPublicSystemDateSelected={
                          setWithinPublicSystemDateSelected
                        }
                      />
                      {/* <Nested /> */}
                      <MuiModal
                        sx={{ background: 'transparent' }}
                        className={classNames('res-page', {
                          'full-size':
                            !desktop &&
                            external.content === EModalContent.DatePicker,
                          'grey-bg':
                            external.content === EModalContent.Redirect,
                        })}
                        open={external.open}
                        onClose={() =>
                          setExternal({ open: false, href: '#', content: null })
                        }
                      >
                        {external.content === EModalContent.Redirect ? (
                          <Redirect
                            open={external.open}
                            onClose={() => {
                              setExternal({
                                open: false,
                                href: '#',
                                content: null,
                              });
                            }}
                            href={external.href}
                          />
                        ) : (
                          <>
                            <DatePicker
                              selected={'#'}
                              onClose={() =>
                                setExternal({
                                  open: false,
                                  href: '#',
                                  content: null,
                                })
                              }
                              onTextFieldChange={(str: string) => {}}
                              availableDates={[]}
                            />
                          </>
                        )}
                      </MuiModal>
                    </>
                  );
                case 'order':
                  return (
                    <Sort
                      soonest={soonest}
                      curr={sortBy}
                      onMenuItemClick={onSortByChange}
                      onIconButtonClick={() =>
                        setDialog({ isOpen: false, content: '' })
                      }
                    />
                  );
              }
            })()}
          </Box>
        </MuiModal>
      </Container>
    </ThemeProvider>
  );
};

export default Results;
