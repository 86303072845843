import axios from 'axios';

import { STORAGE_KEYS } from '../constants';

const BASE_URL = process.env.REACT_APP_REST_API_BASE_URL;

export function removeTokensGlobal() {
  localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
}

const REQUESTS_DO_NOT_NEED_REFRESH = ['/login/admin'];
export async function refreshGlobal(): Promise<string | null> {
  try {
    const response = await api.post('/refresh-token', {
      refresh_token: localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN),
    });
    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, response.data.access_token);
    localStorage.setItem(
      STORAGE_KEYS.REFRESH_TOKEN,
      response.data.refresh_token,
    );

    return response.data.access_token ?? null;
  } catch (e) {
    removeTokensGlobal();
    window.location.href = '/auth';
    return null;
  }
}

export const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
    config.headers = {
      Authorization: !!accessToken ? `Bearer ${accessToken}` : '',
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      !REQUESTS_DO_NOT_NEED_REFRESH.includes(error.response.config.url)
    ) {
      originalRequest._retry = true;
      const accessToken = await refreshGlobal();

      axios.defaults.headers.common['Authorization'] = !!accessToken
        ? `Bearer ${accessToken}`
        : '';
      return api(originalRequest);
    }
    return Promise.reject(error);
  },
);
