export interface IFormState {
  firstName: string;
  lastName: string;
  birthDate: string;
  birthPlace: string;
  residenceAddress: string;
  gender: string;
  fiscalCode: string;
  noFiscalCode: boolean;
  email: string;
  phone: string;
}

export interface IConfrmationModal {
  open: boolean;
  onConfirm: (values: IFormState) => void;
  onDecline: () => void;
}

export interface IModal {
  open: boolean;
  status: '' | 'sent' | 'processing' | 'rejected';
}

export enum ERejected {
  RESERVATION = 'RESERVATION',
  PAYMENT = 'PAYMENT',
}

export interface ICreds {
  id: string;
  paymentType: 'CASH';
  callbackUrl: string;
  callbackErrUrl: string;
}
