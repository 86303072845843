const changeValue = <T>(
  [name, value]: [string, string],
  state: T,
  tools: {
    changeValue: (
      state: T,
      name: string,
      mutate: (prevValue: string) => string,
    ) => void;
  },
) => {
  const { changeValue } = tools;
  changeValue(state, name, () => value);
};

export default changeValue;
