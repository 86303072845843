import { RootState } from 'redux/store';

const selectUser = (state: RootState) => state.user;
const selectUserById = (state: RootState) => ({
  user: state.user.userById,
  loading: state.user.userByIdLoading,
});
const selectUsers = (state: RootState) => ({
  users: state.user.users,
  loading: state.user.usersLoading,
});

export const userSelectors = { selectUser, selectUserById, selectUsers };
