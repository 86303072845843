import { isTomorrow, isMonday, isFriday } from 'date-fns';
import { Slot, ISeoCitySlot as Avail, ApiTypes } from '../types/models';

const DEFAULT_WORKDAY_ENDING = 16;
const SYNLAB_WORKDAY_ENDING = 12;
const AFTERNOON_BEGINNING = 1259;
const SYNLAB_CHAIN_LABEL = 'synlab';

const getWorkdayEnding = (chainLabel: string) =>
  chainLabel === SYNLAB_CHAIN_LABEL
    ? SYNLAB_WORKDAY_ENDING
    : DEFAULT_WORKDAY_ENDING;

const parse = (datestr: string) => {
  const [year, month, day] = datestr.split('-').map(str => Number(str));
  return new Date(year, month - 1, day);
};

const isWorkday = (avail: Avail) =>
  new Date().getHours() <
  getWorkdayEnding(avail.center.chainLabel ?? avail.center.name);
const isWorkweek = () => new Date().getDay() > 0 && new Date().getDay() < 6;
const isWeekend = (avail: Avail) =>
  (isFriday(new Date()) && !isWorkday(avail)) || !isWorkweek();

const isLimited = (datestr: string, avail: Avail) =>
  (isWorkweek() && !isWorkday(avail) && isTomorrow(parse(datestr))) ||
  (isWeekend(avail) && isMonday(parse(datestr)));

const isDefault = (avail: Avail) =>
  avail.center.apiType === ApiTypes.AUTOMATIC ||
  avail.center.apiType === ApiTypes.REDIRECT ||
  !isLimited(avail.slots[0].date, avail);

export const clearMorningAvails = (slots: Slot[]) =>
  slots.filter(
    slot =>
      Number(slot.time.split(' - ')[0].split(':').join('')) >
      AFTERNOON_BEGINNING,
  );

const limit = (avail: Avail) => {
  if (isDefault(avail)) return avail;

  // return { ...avail, slots: clearMorningAvails(avail.slots) };
  return { ...avail, slots: [] };
};

export default limit;
