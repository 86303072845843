import { publicHolidays } from '../data';
import { isPublicHoliday } from '../utils';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import {
  nextMonday,
  previousMonday,
  compareAsc,
  addDays,
  getMonth,
  getWeekOfMonth,
  getWeeksInMonth,
  getDay,
  startOfDay,
  startOfWeek,
  parse,
  nextFriday,
  nextThursday,
  nextWednesday,
  nextTuesday,
  previousTuesday,
  previousWednesday,
  previousThursday,
  previousFriday,
  startOfTomorrow,
  nextSaturday,
  previousSaturday,
} from 'date-fns';
import { ReactComponent as NextWeekChevron } from 'icons/next-week-chevron.svg';
import { ReactComponent as PrevWeekChevron } from 'icons/prev-week-chevron.svg';
import React, { useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerWrapper = styled(Box)({
  paddingBottom: '8px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
});

const SetWeekButton = styled(IconButton)({
  width: '30px',
  height: '40px',
  padding: 0,
  background: '#FFFFFF',
  border: '1px solid #B0BEC5',
  borderRadius: '12px',
});

const Week = styled(List)({
  marginBottom: '-5px',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-around',
});

const WeekDay = styled(Typography)({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#5D708C',
});

const getDates = (startDate: Date, desktop: boolean) => {
  const startDay = getDay(new Date(startDate));

  console.log({
    startDay,
    desktop,
    dateIntervals: [
      {
        start: startDate,
        end: startDate,
      },
      {
        start: addDays(startDate, 2),
        end: addDays(startDate, desktop ? 6 : 5),
      },
    ],
  });

  switch (startDay) {
    case 1: {
      return [
        {
          start: startDate,
          // end: addDays(startDate, desktop ? 5 : 4),
          end: addDays(startDate, 5),
        },
      ];
    }
    case 2: {
      // if (desktop) {
      return [
        {
          start: startDate,
          end: addDays(startDate, 4),
        },
        {
          start: addDays(startDate, 6),
          end: addDays(startDate, 6),
        },
      ];
      // } else {
      //   return [
      //     {
      //       start: startDate,
      //       end: addDays(startDate, 4),
      //     },
      //   ];
      // }
    }
    case 3: {
      return [
        {
          start: startDate,
          end: addDays(startDate, 3),
        },
        {
          start: addDays(startDate, 5),
          // end: addDays(startDate, desktop ? 6 : 5),
          end: addDays(startDate, 6),
        },
      ];
    }
    case 4: {
      return [
        {
          start: startDate,
          end: addDays(startDate, 2),
        },
        {
          start: addDays(startDate, 4),
          // end: addDays(startDate, desktop ? 6 : 5),
          end: addDays(startDate, 6),
        },
      ];
    }
    case 5: {
      return [
        {
          start: startDate,
          end: addDays(startDate, 1),
        },
        {
          start: addDays(startDate, 3),
          // end: addDays(startDate, desktop ? 6 : 5),
          end: addDays(startDate, 6),
        },
      ];
    }
    case 6: {
      return [
        {
          start: startDate,
          end: startDate,
        },
        {
          start: addDays(startDate, 2),
          // end: addDays(startDate, desktop ? 6 : 5),
          end: addDays(startDate, 6),
        },
      ];
    }
    default:
      return [];
  }
};

const getNextInterval = (startDate: Date, desktop: boolean) => {
  return startOfDay(nextMonday(startDate));
  // if (desktop) return startOfDay(nextMonday(startDate));

  /*
  const startDay = getDay(new Date(startDate));
  switch (startDay) {
    // case 1: {
    //   return startOfDay(nextFriday(startDate));
    // }
    case 1: {
      return startOfDay(nextSaturday(startDate));
    }
    case 2: {
      return startOfDay(nextMonday(startDate));
    }
    case 3: {
      return startOfDay(nextTuesday(startDate));
    }
    case 4: {
      return startOfDay(nextWednesday(startDate));
    }
    case 5: {
      return startOfDay(nextThursday(startDate));
    }
    // case 6: {
    //   return startOfDay(nextThursday(startDate));
    // }
    default: {
      return startOfDay(nextMonday(startDate));
    }
  }
  */
};

const getPrevInterval = (startDate: Date, desktop: boolean) => {
  return startOfDay(previousMonday(startDate));
  // if (desktop) return startOfDay(previousMonday(startDate));

  /*
  const startDay = getDay(new Date(startDate));
  switch (startDay) {
    case 1: {
      return startOfDay(previousTuesday(startDate));
    }
    case 2: {
      return startOfDay(previousWednesday(startDate));
    }
    case 3: {
      return startOfDay(previousThursday(startDate));
    }
    case 4: {
      return startOfDay(previousFriday(startDate));
    }
    // case 4: {
    //   return startOfDay(previousSaturday(startDate));
    // }
    case 5: {
      return startOfDay(previousMonday(startDate));
    }
    // case 6: {
    //   return startOfDay(previousMonday(startDate));
    // }
    default: {
      return startOfDay(previousMonday(startDate));
    }
  }
  */
};

/*
  [m, t, w, t, f]
  [s, f, m, t, w]
  [t, f, s, m, t]
  [w, t, f, s, m]
  [t, w, t, f, s]
  [m, t, w, t, f]
  ...
*/

console.log(
  getDates(parse('17-11-2022', 'dd-MM-yyyy', new Date()), false),
  'parsed',
);

console.log(
  startOfDay(nextFriday(parse('17-11-2022', 'dd-MM-yyyy', new Date()))),
  'nextFriday',
);

console.log(
  getNextInterval(parse('17-11-2022', 'dd-MM-yyyy', new Date()), false),
);

export const getDaysPicker = (
  startDate: Date,
  desktop: boolean,
  isItalianLocale: boolean,
) => {
  const startDay = getDay(new Date(startDate));
  switch (startDay) {
    case 1: {
      return desktop
        ? isItalianLocale
          ? ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
          : ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
        : isItalianLocale
        ? ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
        : ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    }
    case 2: {
      return desktop
        ? isItalianLocale
          ? ['Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Lun']
          : ['Tu', 'We', 'Th', 'Fr', 'Sa', 'Mo']
        : isItalianLocale
        ? ['Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Lun']
        : ['Tu', 'We', 'Th', 'Fr', 'Sa', 'Mo'];
    }
    case 3: {
      return desktop
        ? isItalianLocale
          ? ['Mer', 'Gio', 'Ven', 'Sab', 'Lun', 'Mar']
          : ['We', 'Th', 'Fr', 'Sa', 'Mo', 'Tu']
        : isItalianLocale
        ? ['Mer', 'Gio', 'Ven', 'Sab', 'Lun', 'Mar']
        : ['We', 'Th', 'Fr', 'Sa', 'Mo', 'Tu'];
    }
    case 4: {
      return desktop
        ? isItalianLocale
          ? ['Gio', 'Ven', 'Sab', 'Lun', 'Mar', 'Mer']
          : ['Th', 'Fr', 'Sa', 'Mo', 'Tu', 'We']
        : isItalianLocale
        ? ['Gio', 'Ven', 'Sab', 'Lun', 'Mar', 'Mer']
        : ['Th', 'Fr', 'Sa', 'Mo', 'Tu', 'We'];
    }
    case 5: {
      return desktop
        ? isItalianLocale
          ? ['Ven', 'Sab', 'Lun', 'Mar', 'Mer', 'Gio']
          : ['Fr', 'Sa', 'Mo', 'Tu', 'We', 'Th']
        : isItalianLocale
        ? ['Ven', 'Sab', 'Lun', 'Mar', 'Mer', 'Gio']
        : ['Fr', 'Sa', 'Mo', 'Tu', 'We', 'Th'];
    }
    case 6: {
      return desktop
        ? isItalianLocale
          ? ['Sab', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven']
          : ['Sa', 'Mo', 'Tu', 'We', 'Th', 'Fr']
        : isItalianLocale
        ? ['Sab', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven']
        : ['Sa', 'Mo', 'Tu', 'We', 'Th', 'Fr'];
    }
    default:
      return [];
  }
};

const Picker = (props: {
  classes?: string[];
  selectedDate: Date;
  handleDateChange: (date: Date) => void;
}) => {
  const { classes, selectedDate, handleDateChange } = props;
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState<any>(null);
  const [startDay, setStartDay] = useState(() =>
    startDate ? getDay(startDate) : getDay(selectedDate),
  );

  const minDate = new Date();

  // const nextWeek = startOfDay(nextMonday(startDate || selectedDate));
  // const prevWeek = startOfDay(previousMonday(startDate || selectedDate));
  const desktop = useMediaQuery('(min-width:1200px)');
  const nextWeek = getNextInterval(startDate || selectedDate, desktop);
  const prevWeek = getPrevInterval(startDate || selectedDate, desktop);

  // console.log(startDate, 'startDate');

  const utils: any = {};

  const goBack = (prevWeek: Date, minDate: Date) => {
    const res = compareAsc(prevWeek, minDate);
    switch (res) {
      case -1: {
        // Should be the idx of minDate.
        console.log('This is case -1!');

        // const day = getDay(new Date());
        const day = getDay(startOfTomorrow());
        if (day === 0 || day === 6) return;

        setStartDay(day);
        // setStartDate(startOfDay(new Date()));
        setStartDate(startOfDay(startOfTomorrow()));
        return;
      }
      case 0: {
        // console.log('This is case 0!');
        return;
      }
      case 1: {
        setStartDay(1);
        // console.log('This is the first case!');
        // console.log(startDate, 'startDate');
        // console.log(prevWeek, 'prevWeek');
        // console.log(getMonth(prevWeek), 'prevWeek');

        const dateToCompare = startDate || selectedDate;
        const weekOfMonth = getWeekOfMonth(dateToCompare, {
          weekStartsOn: getDay(dateToCompare),
        });
        const weeksInMonth = getWeeksInMonth(startDate, { weekStartsOn: 1 });
        // console.log(getWeeksInMonth(startDate), 'getWeeksInMonth');
        // console.log(getWeekOfMonth(startDate), 'getWeekOfMonth');
        // console.log(getMonth(startDate), 'startDate');
        const monthsEqual =
          getMonth(startDate) === getMonth(prevWeek) ||
          isNaN(getMonth(startDate));
        // console.log(lastDayOfWeek(startDate), 'lastDayOfWeek');
        // const shouldDecreaseMonth =
        //   weekOfMonth === weeksInMonth &&
        //   getMonth(startDate) !== getMonth(lastDayOfWeek(startDate));

        const shouldDecreaseMonth =
          weekOfMonth === weeksInMonth &&
          getMonth(startDate) !== getMonth(selectedDate);
        // console.log(shouldDecreaseMonth, 'shouldDecreaseMonth');
        // console.log(
        //   getMonth(startDate) !== getMonth(lastDayOfWeek(startDate)),
        //   'otherMonth',
        // );
        console.log(
          getMonth(startOfWeek(startDay)) === getMonth(selectedDate),
          'sameMonth',
        );
        // console.log(monthsEqual, 'monthsEqual');
        // || getMonth(selectedDate) !== getMonth(prevWeek)
        // console.log(shouldDecreaseMonth, 'shouldDecreaseMonth');
        console.log(startDay, 'startDayy');

        // console.log(weekOfMonth, 'weekOfMonth');
        // console.log(weeksInMonth, 'weeksInMonth');

        // if (
        //   !monthsEqual &&
        //   getMonth(prevWeek) !== getMonth(lastDayOfWeek(startDay))
        // ) {
        //   utils.decreaseMonth();
        // }
        if (weekOfMonth === 2) {
          // utils.decreaseMonth();
        }

        const prevWeekBegins = startOfDay(previousMonday(dateToCompare));

        // console.log(prevWeekBegins, 'nextDate');
        // console.log(getMonth(dateToCompare), 'getMonth(dateToCompare)');
        // console.log(getMonth(prevWeekBegins), 'getMonth(nextDate)');

        console.log(dateToCompare, 'dateToCompare');
        console.log(prevWeekBegins, 'prevWeekBegins');

        // if (
        //   getMonth(dateToCompare) !== getMonth(prevDate) &&
        //   getMonth(selectedDate) !== getMonth(prevDate)
        // ) {
        //   utils.decreaseMonth();
        // }
        setStartDate(prevWeek);

        if (
          getMonth(dateToCompare) !== getMonth(prevWeekBegins) ||
          getMonth(utils.date) !== getMonth(prevWeekBegins)
        ) {
          // if (getMonth(selectedDate) === getMonth(prevWeekBegins)) {
          //   return;
          // }
          // if (
          //   getMonth(prevWeekBegins) !== getMonth(endOfWeek(prevWeekBegins)) &&
          //   getMonth(selectedDate) === getMonth(prevWeekBegins)
          // ) {
          //   return;
          // }
          utils.decreaseMonth();
        }

        return;
      }
      default: {
        return;
      }
    }
  };

  const goNext = () => {
    setStartDay(1);
    const monthsEqual = getMonth(startDate) === getMonth(nextWeek);
    console.log(monthsEqual, 'monthsEqual');
    console.log(getMonth(selectedDate) !== getMonth(nextWeek), 'dateWeek');

    const weeksInMonth = getWeeksInMonth(startDate, { weekStartsOn: 1 });
    const weekOfMonth = getWeekOfMonth(startDate, { weekStartsOn: 1 });

    console.log(weeksInMonth, 'getWeeksInMonth(startDate)');
    console.log(weekOfMonth, 'getWeekOfMonth(startDate)');

    // if (!monthsEqual && getMonth(selectedDate) !== getMonth(nextWeek)) {
    //   // if (!monthsEqual) {
    //   utils.increaseMonth();
    //   console.log('!isEqual');
    // }
    // console.log(addDays(startDate, 7), 'startDayPlus7');
    // console.log(
    //   getMonth(addDays(startDate, 7)) === getMonth(startDate),
    //   'startDayPlus7',
    // );

    // console.log(getMonth(startDate), 'startDate');
    // console.log(dateIntervals, 'dateIntervals');

    if (
      weeksInMonth === weekOfMonth ||
      (isNaN(weeksInMonth) && isNaN(weekOfMonth))
    ) {
      // utils.increaseMonth();
    }

    const dateToCompare = startDate || selectedDate;
    const nextWeekBegins = addDays(dateToCompare, 7);

    console.log(nextWeekBegins, 'nextDate');
    console.log(getMonth(dateToCompare), 'getMonth(dateToCompare)');
    console.log(getMonth(nextWeekBegins), 'getMonth(nextDate)');

    // if (
    //   getMonth(dateToCompare) !== getMonth(nextDate) &&
    //   getMonth(selectedDate) !== getMonth(nextDate)
    // ) {
    //   utils.increaseMonth();
    // }
    setStartDate(nextWeek);
    console.log(utils.date, 'UTILS.DATE');

    if (
      getMonth(dateToCompare) !== getMonth(nextWeekBegins) &&
      getMonth(utils.date) !== getMonth(nextWeekBegins)
    ) {
      // if (getMonth(selectedDate) === getMonth(nextWeekBegins)) {
      //   return;
      // }
      utils.increaseMonth();
    }
  };

  // console.log(selectedDate, 'selected');
  // const desktop = useMediaQuery('(min-width:1200px)');
  const dateIntervals = getDates(startDate || selectedDate, desktop);
  // export const _days = getDaysPicker(startDate || selectedDate, desktop, _t);

  const isItalianLocale = true;
  const days = useMemo(
    () => getDaysPicker(startDate || selectedDate, desktop, isItalianLocale),
    [startDate, selectedDate, desktop, isItalianLocale],
  );

  // console.log(dateIntervals, 'dateIntervals');

  return (
    <DatePickerWrapper
      sx={
        classes?.includes('slots')
          ? { width: '100%', justifyContent: 'space-between' }
          : {}
      }
    >
      <SetWeekButton
        sx={classes?.includes('slots') ? { width: '38px', height: '50px' } : {}}
        onClick={() => {
          goBack(prevWeek, minDate);
        }}
      >
        <PrevWeekChevron />
      </SetWeekButton>
      <DatePicker
        inline
        calendarClassName={`v2 ${classes?.join(' ')}`}
        disabledKeyboardNavigation
        startDate={startDate || selectedDate}
        selected={selectedDate}
        onChange={date => date && handleDateChange(date)}
        renderCustomHeader={(props: any) => {
          const { date, decreaseMonth, increaseMonth } = props;
          utils.date = date;
          utils.decreaseMonth = decreaseMonth;
          utils.increaseMonth = increaseMonth;

          return (
            <Week>
              {days.map((day: string, idx: number) => (
                <WeekDay key={idx}>{day}</WeekDay>
              ))}
            </Week>
          );
        }}
        calendarStartDay={startDay}
        includeDateIntervals={dateIntervals}
        onSelect={(date: Date) => {
          if (!startDate) {
            setStartDate(new Date(selectedDate));
          }
        }}
        onMonthChange={() => {
          console.log('Changed!');
        }}
        fixedHeight
        dayClassName={date =>
          classNames({ weekend: isPublicHoliday(publicHolidays, date) })
        }
      />

      <SetWeekButton
        sx={classes?.includes('slots') ? { width: '38px', height: '50px' } : {}}
        onClick={goNext}
      >
        <NextWeekChevron />
      </SetWeekButton>
    </DatePickerWrapper>
  );
};

export default Picker;
