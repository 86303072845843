import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const Reusable = {
  Wrapper: styled(Container)(({ theme }) => ({
    width: '500px',
    padding: '78px 24px 150px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '28px',
      paddingRight: '28px',
    },
  })),
};

export default Reusable;
