import { gql } from '@apollo/client';
import {
  PATIENT_FRAGMENT_GQL,
  PAYMENT_FRAGMENT_GQL,
  USER_FRAGMENT_GQL,
} from '../../graphql/fragments';

export const FIND_BOOKINGS = gql`
  ${PAYMENT_FRAGMENT_GQL}
  ${USER_FRAGMENT_GQL}
  ${PATIENT_FRAGMENT_GQL}
  query FindBookings {
    findBookings {
      id
      code
      patient {
        ...PatientFragment
      }
      date
      reservationDate
      time
      price
      paymentType
      paymentDate
      payments {
        ...PaymentFragment
      }
      status
      user {
        ...UserFragment
      }
      test {
        id
        name
      }
      center {
        id
        name
        address
      }
    }
  }
`;
