import React, { useEffect, useMemo, useState } from 'react';
import { TableBody, TableContainer, TableHead } from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { ReactComponent as Checked } from '../../../icons/checkboxes/checked.svg';
import { ReactComponent as Unchecked } from '../../../icons/checkboxes/not-checked.svg';
import { ReactComponent as Columns } from '../../../icons/table.svg';
import { BookingStatus, ITableBooking, TTableBookings } from '../../../types';
import { ColumnHeader } from '../ColumnHeader.component';
import {
  FixedTable,
  ColumnsVisible,
  ColumnsVisibleGroup,
  ColumnsVisibleList,
  ColumnVisibleControl,
  ColumnVisibleLabel,
  TableTransparentButton,
  ExportBtn,
  TableBodyCell,
  TableBodyRow,
  TableHeadCell,
  TableHeadRow,
  TableSetting,
  TableSettings,
} from '../DataGrid.styled';
import { DataGridSkeletons } from '../DataGridSkeletons.component';
import { columns } from './Booking.data';
import {
  mapTableBookingDataToCsv,
  renderCustomFilter,
  sortByCenter,
  sortByTest,
  withAppointment,
  withCenter,
  withCode,
  withEmail,
  withPatient,
  withStatus,
} from './Booking.helpers';
import {
  BookingChangeStatusDialog,
  BookingChangeStatusDialogState,
} from './BookingChangeStatusDialog.component';
import { sortByName } from '../DataGrid.helpers';

interface IBookingProps {
  entries: TTableBookings;
  loading: boolean;
}
const Booking = ({ entries, loading }: IBookingProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const showColumns = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const hideColumns = () => setAnchorEl(null);
  const [sorting, setSorting] = useState<SortingState>(() => [
    { id: 'code', desc: true },
  ]);
  const [defaultData, setDefaultData] = useState<ITableBooking[]>(
    () => entries ?? [],
  );
  const [dialogState, setDialogState] =
    useState<BookingChangeStatusDialogState>({ bookingID: null, status: null });
  const [dropdownCoords, setDropdownCoords] = useState({ x: 0, y: 0 });
  const memoizedColumns = useMemo(() => columns(openDialog), []);
  const tableInstance = useReactTable({
    data: defaultData,
    columns: memoizedColumns,
    state: { sorting },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      withCode,
      withStatus,
      withPatient,
      withEmail,
      withAppointment,
      withCenter,
    },
    sortingFns: {
      sortByStatus: sortByName('patient'),
      sortByCenter,
      sortByTest,
    },
  });

  function openDialog(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    bookingID: string,
    status: BookingStatus,
  ) {
    const { top, left, height } = (e.target as Element).getBoundingClientRect();
    setDropdownCoords({ x: left, y: top + height + 4 });
    document.body.style.overflow = 'hidden';
    setDialogState({ bookingID, status });
  }
  function closeDropdown() {
    setDropdownCoords({ x: 0, y: 0 });
    document.body.style.overflow = 'visible';
  }
  function closeDialog() {
    setDialogState({ bookingID: null, status: null });
  }
  function getFilteredAndSortedRows() {
    const original = tableInstance
      .getSortedRowModel()
      .rows.map(row => row.original);
    return mapTableBookingDataToCsv(original);
  }

  useEffect(() => {
    if (!entries) return;
    setDefaultData(entries);
  }, [entries]);

  return (
    <>
      <BookingChangeStatusDialog
        state={dialogState}
        closeDialog={closeDialog}
        dropdownCoords={dropdownCoords}
        closeDropdown={closeDropdown}
      />

      <TableSettings>
        <TableSetting>
          <ColumnsVisible onClick={showColumns}>
            <Columns />
          </ColumnsVisible>
        </TableSetting>
        <TableSetting>
          <TableTransparentButton
            onClick={() => tableInstance.resetColumnFilters(true)}
          >
            Rimuovi tutti i filtri
          </TableTransparentButton>
        </TableSetting>
        <TableSetting>
          <ExportBtn data={getFilteredAndSortedRows()} />
        </TableSetting>
      </TableSettings>
      <ColumnsVisibleList
        open={!!anchorEl}
        onClose={hideColumns}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <ColumnsVisibleGroup>
          {tableInstance.getAllColumns().map(column => {
            const columnHeader = column.columnDef.header;
            const columnLabel = columnHeader as React.ReactNode;
            return (
              <ColumnVisibleLabel
                key={column.id}
                label={columnLabel}
                control={
                  <ColumnVisibleControl
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                    disableRipple
                  />
                }
              />
            );
          })}
        </ColumnsVisibleGroup>
      </ColumnsVisibleList>
      {loading ? (
        <DataGridSkeletons />
      ) : (
        <TableContainer>
          <FixedTable>
            <TableHead>
              {tableInstance.getHeaderGroups().map(headerGroup => {
                return (
                  <TableHeadRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      const columnHeading = header.column.columnDef
                        .header as string;
                      const filterPlaceholder =
                        header.id === 'bookingID' ? 'ID' : columnHeading;
                      return (
                        <TableHeadCell key={header.id} className={header.id}>
                          <ColumnHeader
                            customFilter={renderCustomFilter(
                              header.id,
                              header.column,
                              entries,
                            )}
                            heading={columnHeading}
                            placeholder={filterPlaceholder}
                            columnInstance={header.column}
                          />
                        </TableHeadCell>
                      );
                    })}
                  </TableHeadRow>
                );
              })}
            </TableHead>

            <TableBody>
              {tableInstance.getRowModel().rows.map(row => {
                return (
                  <TableBodyRow key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableBodyCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableBodyCell>
                      );
                    })}
                  </TableBodyRow>
                );
              })}
            </TableBody>
          </FixedTable>
        </TableContainer>
      )}
    </>
  );
};

export default Booking;
