import React from 'react';
import { Column } from '@tanstack/react-table';

import { ColumnHeaderBox, ColumnHeading, Filter } from './DataGrid.styled';
import { ReactComponent as ChevronUp } from '../../icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../icons/chevron.svg';

interface ColumnHeaderProps<T> {
  heading: string;
  placeholder: string;
  columnInstance: Column<T>;
  isSortingEnabled?: boolean;
  customFilter?: JSX.Element;
}
export function ColumnHeader<T>({
  heading,
  placeholder,
  isSortingEnabled,
  columnInstance,
  customFilter,
}: ColumnHeaderProps<T>) {
  return (
    <>
      <ColumnHeaderBox>
        <ColumnHeading
          sx={isSortingEnabled ? { mr: '10px' } : {}}
          onClick={columnInstance.getToggleSortingHandler()}
        >
          {heading}
        </ColumnHeading>
        {{ asc: <ChevronUp />, desc: <ChevronDown /> }[
          columnInstance.getIsSorted() as string
        ] ?? null}
      </ColumnHeaderBox>
      {!!customFilter ? (
        customFilter
      ) : (
        <Filter
          value={columnInstance.getFilterValue() ?? ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            columnInstance.setFilterValue(event.target.value)
          }
          placeholder={placeholder}
        />
      )}
    </>
  );
}
