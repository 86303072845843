import { gql } from '@apollo/client';

export const FIND_PATIENTS = gql`
  query FindPatients {
    findPatients {
      id
      firstName
      lastName
      fiscalCode
      email
      phone
      birthDate
      birthPlace
      gender
      nationality
      creationDate
    }
  }
`;
