export const publicHolidays = [
  '2023-01-06',
  '2023-04-10',
  '2023-04-25',
  '2023-05-01',
  '2023-06-02',
  '2023-08-15',
  '2023-11-01',
  '2023-12-08',
  '2023-12-25',
  '2023-12-26',
];

export const dateFormats = {
  publicHoliday: 'yyyy-MM-dd',
};

export const soonestAvailable = 'Prima possibile';
