import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ShowMoreButton = styled('p', { label: 'ShowMoreButton' })<{
  centered?: boolean;
  isMedicalServiceInCity?: boolean;
}>`
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.2px;
  color: #00838f;
  max-width: max-content;

  & > svg {
    margin-left: 4px;
  }

  ${({ centered }) =>
    centered &&
    css`
      margin: 0 auto;
    `}

  ${({ isMedicalServiceInCity }) =>
    isMedicalServiceInCity &&
    css`
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 120%;
        & > svg {
          width: 16px;
          height: 16px;
        }
      }
    `}
`;
