import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  surname: '',
  code: '',
};

const patient = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<any>) => (state = action.payload),
  },
});

export const { addPatient } = patient.actions;

export default patient.reducer;
