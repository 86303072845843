export interface IPatient {
  __typename: string;
  id: string;
  firstName: string;
  lastName: string;
  fiscalCode: string;
  email: string;
  phone: string;
  birthDate: string;
  birthPlace: string;
  gender: string;
  nationality: string;
  creationDate: string;
  user: null | IUserGql;
  residenceAddress: null | string;
}

export interface LastBook {
  date: string;
}

export interface IUserGql {
  id: string;
  email: string;
  role: string;
  firstName: string;
  updatedAt: string;
  createdAt: string;
  lastName: string;
  lastSession: string;
  phone: string;
  birthdate: string;
  gender: string;
  citizenship: null; //Fixme
  cityOfBirth: null; //Fixme
  passportID: null; //Fixme
  fiscalCode: string;
  residenceCity: string;
  residenceAddress: null; //Fixme
  country: null; //Fixme
  region: null; //Fixme
  cap: null; //Fixme
  lastBook: null | LastBook;
  bookCount: number;
}

export interface IBooking {
  id: string;
  code: number;
  publicId: string;
  patient: IPatient;
  patientId: string;
  user: IUserGql;
  chainId: string;
  reservationDate: null | string;
  chain: IChain;
  apiId: string;
  date: string;
  time: string;
  doctor: string;
  centerId: string;
  center: ICenter;
  testId: string;
  test: ITest;
  status: BookingStatus;
  price: number;
  feedback: null | number;
  paymentType: string;
  paymentDate: any; // FIXME
  payments: IPayment[];
}

export interface IPayment {
  id: string;
  date: string;
  status: string; //FIXME good to change to enum
  type: string; //FIXME good to change to enum
  amount: number;
  axerveId: null | string;
  cardType: null | string;
  cardIssuer: null | string;
  commission: null | string;
}

export interface IChain {
  __typename: string;
  id: string;
  name: string;
  label: string;
  logo: null | string;
  logoBig: null | string;
  enabled: boolean;
  type: string;
}

export interface ICenter {
  __typename: string;
  id: string;
  chain: IChain;
  name: string;
  address: string;
  enabled: boolean;
  region: IRegion;
  rating: number;
  label: string;
  location: ILocation;
}

export interface IRegion {
  __typename: string;
  id: string;
  name: string;
  label: string;
}

export interface ILocation {
  coordinates: number[];
}

export interface ITest {
  __typename: string;
  id: string;
  name: string;
  enabled: boolean;
  answers: any[]; // FIXME
  code: string;
  category1: any; // FIXME
  category: string;
  question: any; // FIXME
  prescription: boolean;
  label: string;
}

export enum BookingStatus {
  PendingPayment = 'PENDING_PAYMENT',
  PaymentFailed = 'PAYMENT_FAILED',
  Pending = 'PENDING',
  ConfirmedManual = 'CONFIRMED_MANUAL',
  ConfirmedAuto = 'CONFIRMED_AUTO',
  CancelledUser = 'CANCELLED_USER',
  CancelledAdmin = 'CANCELLED_ADMIN',
  CheckedInAuto = 'CHECKED_IN_AUTO',
  CheckedInManual = 'CHECKED_IN_MANUAL',
  NoShow = 'NO_SHOW',
  ToCheck = 'TO_CHECK',
  ApiError = 'API_ERROR',
}

export interface ITableBooking {
  status: BookingStatus;
  code: number;
  bookingDate: string;
  bookingID: string;
  test: Omit<ITest, '__typename'>;
  patient: Omit<IPatient, '__typename'>;
  reservationDate: string;
  user: Omit<IUserGql, '__typename'>;
  center: Omit<ICenter, '__typename'>;
  payment: IPayment[];
}

export type TTableBookings = undefined | ITableBooking[];

export enum PaymentValues {
  DATE = 'date',
  STATUS = 'status',
  TYPE = 'type',
  AMOUNT = 'amount',
  AXERVE_ID = 'axerveId',
  CARD_TYPE = 'cardType',
  CARD_ISSUER = 'cardIssuer',
  COMMISSION = 'commission',
}
