import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import dateHelpers from '../../utils/date';
import { FIND_BOOKING_BY_ID } from './BookingDetails.query';
import { FindBookingByIdResult, LogsLevels } from './BookingDetails.types';

export const useBooking = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, data, refetch } = useQuery<FindBookingByIdResult>(
    FIND_BOOKING_BY_ID,
    {
      variables: { id },
    },
  );
  const booking = data?.findBookById;

  return { loading, booking, id, refetch };
};

export function getDateAndTime(date: null | string): string {
  if (!date) return '---';
  return `${dateHelpers.formatUTCDate(
    date,
    'dd/MM/yyyy',
  )} ore ${dateHelpers.formatUTCDate(date, 'HH:mm')}`;
}

export function getDateAndTimeAndFormatTo1GMT(date: null | string): string {
  if (!date) return '---';
  return `${dateHelpers.utcTo1GMT(
    date,
    'dd/MM/yyyy',
  )} ore ${dateHelpers.utcTo1GMT(date, 'HH:mm')}`;
}

export function getLogLevelLabel(level: LogsLevels) {
  return level === LogsLevels.INFO ? 'success' : 'error';
}
