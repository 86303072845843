import type { RootState } from '../store';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { ApiTypes } from '../../types/models';

// const CDI_CHAIN_LABEL = 'centro-diagnostico-italiano';
const CHAINS_WITH_PAYMENTS_DISABLED_LABELS = [
  'centro-diagnostico-italiano',
  'welcomed',
];

interface IUser {
  userID?: null | string;
  patientID?: null | string;
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: 'M' | 'F' | 'DS';
  nationality: string;
  fiscalCode: null | string;
  birthPlace: string;
  residenceAddress?: string;
  self: boolean;
}

interface IMeta {
  paymentMethod: string;
  centerApiType: ApiTypes;

  paymentError: boolean;
  unpaidReservationID: string;
}

export interface IEntry {
  api: string;
  chain: string;
  chainLabel: string;
  test: string;
  date: string;
  time: string;
  center: string;
  doctor: string;
  price: null | number;
  user: IUser;
  data: Record<string, string>;
  meta: IMeta;
}

type TOmitEntry =
  | 'api'
  | 'chain'
  | 'chainLabel'
  | 'test'
  | 'date'
  | 'time'
  | 'center'
  | 'doctor'
  | 'price'
  | 'data'
  | 'meta';

type UserID = {
  userID: string;
};

type PatientID = {
  patientID: null | string;
};

type OmittedCreateEntryPayload = Omit<IEntry, 'user' | 'callbackUrl' | 'meta'>;
type CenterApiType = { centerApiType: ApiTypes };
type CreateEntryPayload = OmittedCreateEntryPayload & CenterApiType;

type UnpaidReservationPayload = {
  paymentError: boolean;
  unpaidReservationID: string;
};

const initialState: IEntry = {
  api: '',
  chain: '',
  chainLabel: '',
  test: '',
  date: '',
  time: '',
  center: '',
  doctor: '',
  price: null,
  user: {
    userID: null,
    patientID: null,
    email: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: 'DS',
    nationality: '',
    fiscalCode: null,
    birthPlace: '',
    residenceAddress: '',
    self: false,
  },
  data: {},
  meta: {
    paymentMethod: '',
    centerApiType: ApiTypes.INITIAL,

    paymentError: false,
    unpaidReservationID: '',
  },
};

const entry = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    createEntry: (state, action: PayloadAction<CreateEntryPayload>) => {
      state.api = action.payload.api;
      state.chain = action.payload.chain;
      state.chainLabel = action.payload.chainLabel;
      state.test = action.payload.test;
      state.date = action.payload.date;
      state.time = action.payload.time;
      state.center = action.payload.center;
      state.price = action.payload.price;
      state.doctor = action.payload.doctor;
      state.data = action.payload.data;
      state.meta.centerApiType = action.payload.centerApiType;
    },
    createPatient: (state, action: PayloadAction<Omit<IEntry, TOmitEntry>>) => {
      state.user.email = action.payload.user.email;
      state.user.firstName = action.payload.user.firstName;
      state.user.lastName = action.payload.user.lastName;
      state.user.birthDate = action.payload.user.birthDate;
      state.user.gender = action.payload.user.gender;
      state.user.nationality = action.payload.user.nationality;
      state.user.fiscalCode = action.payload.user.fiscalCode;
      state.user.birthPlace = action.payload.user.birthPlace;
      state.user.self = action.payload.user.self;
      state.user.residenceAddress = action.payload.user.residenceAddress;
    },
    updateUserID: (state, action: PayloadAction<UserID>) => {
      state.user.userID = action.payload.userID;
    },
    updatePatientID: (state, action: PayloadAction<PatientID>) => {
      state.user.patientID = action.payload.patientID;
    },
    resetUser: state => {
      state.user = initialState.user;
    },
    setPaymentMethod: (
      state,
      action: PayloadAction<{ paymentMethod: string }>,
    ) => {
      state.meta.paymentMethod = action.payload.paymentMethod;
    },
    setUnpaidReservation: (
      state,
      action: PayloadAction<UnpaidReservationPayload>,
    ) => {
      state.meta.paymentError = action.payload.paymentError;
      state.meta.unpaidReservationID = action.payload.unpaidReservationID;
    },
    resetUnpaidReservation: state => {
      state.meta.paymentError = initialState.meta.paymentError;
      state.meta.unpaidReservationID = initialState.meta.unpaidReservationID;
    },
    resetEntry: state => {
      state.api = initialState.api;
      state.chain = initialState.chain;
      state.chainLabel = initialState.chainLabel;
      state.test = initialState.test;
      state.date = initialState.date;
      state.time = initialState.time;
      state.center = initialState.center;
      state.doctor = initialState.doctor;
      state.price = initialState.price;
      state.user = initialState.user;
      state.data = initialState.data;
      state.meta = initialState.meta;
    },
  },
});

export const {
  createEntry,
  createPatient,
  updateUserID,
  updatePatientID,
  resetUser,
  setPaymentMethod,
  setUnpaidReservation,
  resetUnpaidReservation,
  resetEntry,
} = entry.actions;

export const selectors = {
  getEntry: (state: RootState) => state.entry as IEntry,
  getPatient: (state: RootState) => state.entry.user,
  selectPaymentMethod: (state: RootState) => state.entry.meta.paymentMethod,
  selectChainType: (state: RootState) => state.entry.meta.centerApiType,
  selectIsManualChain: (state: RootState) =>
    state.entry.meta.centerApiType === ApiTypes.MANUAL,
  selectPaymentError: (state: RootState) => state.entry.meta.paymentError,
  selectUnpaidReservationID: (state: RootState) =>
    state.entry.meta.unpaidReservationID,
  selectLastUser: (state: RootState) => {
    const user = state.entry.user;
    return {
      userID: user.userID,
      patientID: user.patientID,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: user.birthDate,
      gender: user.gender,
      nationality: user.nationality,
      fiscalCode: user.fiscalCode,
      birthPlace: user.birthPlace,
      residenceAddress: user.residenceAddress,
      self: user.self,
    };
  },
  selectIsEntrySet: (state: RootState) => !!state.entry.api,
  selectIsPatientChosen: (state: RootState) => {
    console.log(state.entry.user.userID, 'state.entry.userID');
    return (
      !!state.entry.user.userID &&
      (!!state.entry.user.patientID || !!state.entry.user.firstName)
    );
  },
  selectChainLabel: (state: RootState) => state.entry.chainLabel,
};

export const selectCanPaymentBeInvoked = createSelector(
  selectors.selectIsEntrySet,
  selectors.selectIsPatientChosen,
  (isEntrySet, isPatientChosen) => isEntrySet && isPatientChosen,
);

export const selectIsChainWithPaymentsDisabled = createSelector(
  selectors.selectIsManualChain,
  selectors.selectChainLabel,
  (isManualChain, chainLabel) =>
    isManualChain || CHAINS_WITH_PAYMENTS_DISABLED_LABELS.includes(chainLabel),
);

export const selectEntryTimeslot = createSelector(selectors.getEntry, entry => {
  const { meta, data, user, ...rest } = entry as IEntry;
  return rest;
});

export default entry.reducer;
