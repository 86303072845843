import { gql } from '@apollo/client';
import {
  CHAIN_FRAGMENT_GQL,
  PATIENT_FRAGMENT_GQL,
  PAYMENT_FRAGMENT_GQL,
  TEST_FRAGMENT_GQL,
  USER_FRAGMENT_GQL,
} from '../../graphql/fragments';

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($id: String!) {
    cancelBooking(id: $id) {
      id
    }
  }
`;

export const RESEND_BOOKING_CONFIRMATION = gql`
  mutation ResendBookingConfirmation($id: String!, $edited: Boolean) {
    resendBookingConfirmation(id: $id, edited: $edited) {
      success
    }
  }
`;

export const FIND_BOOKING_BY_ID = gql`
  ${PAYMENT_FRAGMENT_GQL}
  ${PATIENT_FRAGMENT_GQL}
  ${USER_FRAGMENT_GQL}
  ${CHAIN_FRAGMENT_GQL}
  ${TEST_FRAGMENT_GQL}
  query FindBookingById($id: ID!) {
    findBookById(id: $id) {
      id
      code
      publicId
      patient {
        ...PatientFragment
      }
      patientId
      user {
        ...UserFragment
      }
      chainId
      chain {
        ...ChainFragment
      }
      date
      reservationDate
      time
      doctor
      centerId
      center {
        id
        chain {
          ...ChainFragment
        }
        name
        address
        enabled
        region {
          id
          name
          label
        }
        rating
        label
        location {
          coordinates
        }
      }
      testId
      test {
        ...TestFragment
      }
      status
      price
      feedback
      paymentType
      paymentDate
      payments {
        ...PaymentFragment
      }
      logs {
        level
        message
        time
      }
    }
  }
`;
