import { format, isToday } from 'date-fns';
import { it } from 'date-fns/locale';

export function getDateAndTime(date: string): string {
  const dateFromComponent = new Date(date);
  const isTodayHelper = isToday(dateFromComponent);
  const formattedDate = format(dateFromComponent, 'dd MMMM yyyy', {
    locale: it,
  });
  const formattedTime = format(dateFromComponent, 'hh:mm');
  if (isTodayHelper) {
    return `Oggi, ${formattedDate} ore ${formattedTime}`;
  }
  return `${formattedDate} ore ${formattedTime}`;
}
