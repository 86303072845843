import { createSlice } from '@reduxjs/toolkit';

import { STORAGE_KEYS } from '../../../constants';
import { authOperations } from './operations';
import { IAuth } from './types';

export const initialState: IAuth = {
  isLoading: false,
  isLoggedIn: !!localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
  isRejected: true,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(authOperations.login.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(authOperations.login.fulfilled, state => {
      state.isLoggedIn = true;
      state.isLoading = false;
    });
    builder.addCase(authOperations.login.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(authOperations.logout.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(authOperations.logout.fulfilled, state => {
      state.isLoggedIn = false;
      state.isLoading = false;
    });
    builder.addCase(authOperations.logout.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const authReducer = auth.reducer;
