import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IPlace {
  id: string;
  address: string;
  coords: [number, number];
}

export interface IRebookPayload {
  appt: string;
  apptID: string;
  hasPrescription: boolean;
  date: string;
  dest: string;
  place: IPlace;
}

export interface QueryState {
  test: string;
  testID: string;
  destination: string;
  place?: IPlace;
  date: string;
  answers: string[];
  suggested: null | string;
  hasPrescription?: boolean;
}

// place: { id, address, coords }

const initialState: QueryState = {
  test: '',
  testID: '',
  destination: '',
  place: {
    id: '',
    address: '',
    coords: [0, 0],
  },
  date: '',
  answers: [],
  suggested: null,
  hasPrescription: false,
};

const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    createQuery: (state, action: PayloadAction<QueryState>) =>
      (state = action.payload),
    updateDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    updateTest: (state, action: PayloadAction<any>) => {
      state.testID = action.payload.testID;
      state.test = action.payload.test;
    },
    updateDestination: (state, action: PayloadAction<any>) => {
      state.destination = action.payload;
    },
    updatePlace: (state, action: PayloadAction<any>) => {
      state.place = action.payload;
    },
    updateTestId: (state, action: PayloadAction<string>) => {
      state.testID = action.payload;
    },
    updateQueryRebook: (state, action: PayloadAction<IRebookPayload>) => {
      state.test = action.payload.appt;
      state.testID = action.payload.apptID;
      state.hasPrescription = action.payload.hasPrescription;
      state.date = action.payload.date;
      state.place = action.payload.place;
      state.destination = action.payload.dest;
    },
  },
});

export const {
  createQuery,
  updateDate,
  updateTest,
  updatePlace,
  updateTestId,
  updateDestination,
  updateQueryRebook,
} = querySlice.actions;

export default querySlice.reducer;
