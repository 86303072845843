import gql from 'graphql-tag';
import { useState } from 'react';
import { useQuery } from '@apollo/client';

const B2B_SOURCE_TYPE = 'B2B';

interface Source {
  id: string;
  type: string;
}

type Sources = {
  findSources: Source[];
};

export const FIND_SOURCES = gql`
  query FindSources {
    findSources {
      id
      type
    }
  }
`;

export const useSource = () => {
  const [sourceId, setSourceId] = useState<string | null>(null);
  useQuery<Sources>(FIND_SOURCES, {
    onCompleted: data => {
      const b2bSrc = data.findSources.find(
        ({ type }) => type === B2B_SOURCE_TYPE,
      );
      if (!b2bSrc) {
        return;
      }
      return setSourceId(b2bSrc.id);
    },
  });
  return sourceId;
};
