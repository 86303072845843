import { createSlice } from '@reduxjs/toolkit';
import operations from 'redux/tests/operations';

type Answer = {
  title: string;
  service: string;
};

type Test = {
  id: string;
  name: string;
  question: null | string;
  answers: Answer[];
};

interface State {
  all: Test[];
  loading: boolean;
  error: null | string;
}

const initialState: State = {
  all: [],
  loading: false,
  error: null,
};

// TODO: Add case rejected!

const tests = createSlice({
  name: 'tests',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(operations.getAll.pending, (state, _) => {
      state.loading = true;
    });
    builder.addCase(operations.getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.all = action.payload;
    });
    builder.addCase(operations.getAll.rejected, (state, action) => {
      state.loading = false;
      //  @ts-ignore
      state.error = action.payload;
    });
  },
});

export default tests.reducer;
