import { styled } from '@mui/material';
import Modal from '@mui/material/Modal';

export const MuiModal = styled(Modal)(({ theme }) => ({
  background: 'none',
  '& > .MuiBox-root': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '&.regime': {
    '& > .MuiBox-root': {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      padding: '16px 0',
      background: '#ffffff',
      [theme.breakpoints.up('lg')]: {
        width: '526px',
        height: 'auto',
        maxHeight: '520px',
        overflowY: 'auto',
        border: '1px solid #263238',
        borderRadius: '20px',
        '&::-webkit-scrollbar': {
          width: '0px',
        },
      },
    },
  },
  '&.res-page': {
    '&.full-size': {
      '& > .MuiBox-root': {
        width: '100%',
        height: '100%',
        padding: '68px 16px 0px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& .MuiBackdrop-root': {
        backgroundColor: '#00000080',
      },
    },
    '&.grey-bg': {
      '& .MuiBackdrop-root': {
        backgroundColor: '#00000080 !important',
      },
    },
  },
  '&.public-system-validation-loader': {
    '& > .MuiBackdrop-root': {
      backgroundColor: '#90a4ae99',
    },
    '& > .MuiBox-root': {
      width: '328px',
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      background: '#ffffff',
      borderRadius: '20px',
    },
    '& svg': {
      flexShrink: 0,
      marginRight: '16px',
      animation: 'spin 3s linear infinite',
    },
  },
  '&.patient.full-size': {
    '& > .MuiBox-root': {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      padding: 0,
    },
    '& > .MuiBackdrop-root': {
      backgroundColor: '#ffffff',
    },
  },
  '&.res-page-parent-modal': {
    '& > .MuiBox-root': {
      width: '100%',
      height: '100%',
      background: '#FFFFFF',
      overflow: 'auto',
    },
    '&.sort > .MuiBox-root': {
      paddingTop: '24px',
    },
    '&.regime > .MuiBox-root': {
      padding: '16px 0',
    },
    [theme.breakpoints.up('lg')]: {
      '& > .MuiBox-root': {
        width: '478px',
        height: '520px',
        padding: '24px 24px 0px',
        borderRadius: '20px',
        '&::-webkit-scrollbar': {
          width: '0px',
        },
      },
      '&.sort > .MuiBox-root': {
        height: 'auto',
        padding: '24px 0px 24px',
      },
      '&.regime > .MuiBox-root': {
        width: '526px',
        height: 'auto',
        maxHeight: '520px',
        border: '1px solid #263238',
      },
    },
  },
}));
