import styled from '@emotion/styled';
import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Go from '../../components/Go';
import Views from '../../components/Views';
import { views } from './Patient.data';
import { usePatient } from './Patient.helpers';
import { ViewsTypes } from './Patient.types';
import { PatientBookings } from './TabsContent/PatientBookings.component';
import { PatientInfo } from './TabsContent/PatientInfo.component';

const Flexed = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
});

const Patient = () => {
  const navigate = useNavigate();
  const { patient } = usePatient();
  const [view, setView] = useState(ViewsTypes.Info);

  useEffect(() => {
    document.body.scrollIntoView();
  }, [view]);

  return (
    <Container sx={{ padding: '28px 34px' }}>
      <Flexed sx={{ pb: '28px' }}>
        <Go onClick={() => navigate(-1)} />
        {!!patient?.firstName && !!patient?.firstName && (
          <Typography variant="h1" sx={{ ml: '25px' }}>
            {`Paziente: ${patient?.firstName} ${patient?.lastName}`}
          </Typography>
        )}
      </Flexed>
      <Views views={views} initial={ViewsTypes.Info} liftView={setView} />
      <Box sx={{ mt: '36px' }}>
        {view === ViewsTypes.Info && <PatientInfo />}
        {view === ViewsTypes.Bookings && <PatientBookings />}
      </Box>
    </Container>
  );
};

export default Patient;
