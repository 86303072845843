import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperClass from 'swiper/types/swiper-class';

import { Next, Prev } from '../icons-components/Icons';
import { Framed, ApiTypes } from '../types/models';
import Card from './Card';

interface Props {
  slideTo?: number;
  avails: Framed[];
  handleSlideChange: (arg: number) => void;
  onModalOpen?: (
    redirect: boolean,
    href: string,
    slots: any,
    chainLabel: string,
    center: any,
  ) => void;
  soonest: boolean;
}

interface IAvail extends Framed {
  logo: string | undefined;
}

// const Card = styled(Box)({
//   width: '100%',
//   height: '100px',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   background: '#ececec',
// });

const Controllers = styled(List)({
  margin: '10px 0',
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const Controller = styled(ListItem)({
  width: 'auto',
  padding: 0,
});
const Switch = styled(IconButton)({});

const Carousel = (props: Props): JSX.Element => {
  const {
    slideTo = 0,
    avails,
    handleSlideChange,
    onModalOpen,
    soonest,
  } = props;
  const [controller, setController] = useState<SwiperClass | null>(null);

  useEffect(() => {
    return () => {
      controller?.destroy();
    };
  }, [controller]);

  const onClick = useCallback(() => {
    if (!controller) return;
    controller.slideTo(slideTo, 250);
  }, [controller, slideTo]);

  useEffect(() => {
    // if (slideTo === controller?.activeIndex) return;
    onClick();
  }, [onClick]);

  const handlePrev = () => {
    if (!controller) return;
    controller.slidePrev();
  };

  const handleNext = () => {
    if (!controller) return;
    controller.slideNext();
  };

  return (
    <Container sx={{ p: '15px 0px' }}>
      <Controllers>
        <Controller>
          <Switch onClick={handlePrev}>
            <Prev />
          </Switch>
        </Controller>
        <Controller>
          <Switch onClick={handleNext}>
            <Next />
          </Switch>
        </Controller>
      </Controllers>
      <Swiper
        spaceBetween={12}
        slidesPerView={1}
        onSwiper={swiper => setController(swiper)}
        onSlideChange={() => {
          if (!controller) return;
          // console.log(controller?.activeIndex, 'activeIndex');
          handleSlideChange(controller?.activeIndex);
        }}
      >
        {avails.map((avail: any) => {
          const {
            // center: { id, name, address },
            center,
            distance,
            price,
            slots,
            frame,
            logo,
          } = avail;
          const [slot] = slots;

          const [st, num] = center.address.split(',');
          const formatted = `${st}, ${num}`;
          const redirect = center.apiType === ApiTypes.REDIRECT;

          return (
            <SwiperSlide key={center.id}>
              <Card
                soonest={soonest}
                id={center.id}
                slot={slot}
                destination={center?.centerName}
                address={formatted}
                distance={distance}
                price={price}
                availability={frame}
                logo={logo}
                redirect={redirect}
                onModalOpen={() => () =>
                  onModalOpen?.(
                    redirect,
                    center['apiUrl'],
                    slots,
                    center.chainLabel,
                    center,
                  )}
                apiType={center.apiType}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};

export default Carousel;
