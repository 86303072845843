import Typography from '@mui/material/Typography';
import Reusable from 'components/Authorization/Reusable';
import Styled from 'components/Styled';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { IForm } from './Reset.types';

const Reset = () => {
  const navigate = useNavigate();

  const onSubmit = (vals: IForm) => {
    navigate('/auth/confirm', { state: vals });
  };

  return (
    <Reusable.Wrapper>
      <Typography sx={{ mb: '8px' }} variant="h1">
        Password dimenticata
      </Typography>
      <Typography sx={{ mb: '70px' }} variant="caption">
        Inserisci l’indirizzo mail e ti invieremo le istruzioni per accedere al
        tuo account
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, dirtyFields }) => {
          const hasPristineFields = Object.values(dirtyFields).length === 0;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                render={({ input }) => {
                  return (
                    <Styled.Input
                      sx={{ mb: '16px' }}
                      label="Indirizzo mail"
                      InputLabelProps={{ shrink: true, focused: false }}
                      {...input}
                    />
                  );
                }}
              />
              <Styled.Button type="submit" disabled={hasPristineFields}>
                Ripristina la password
              </Styled.Button>
            </form>
          );
        }}
      />
    </Reusable.Wrapper>
  );
};

export default Reset;
