import { Filter, Frame, Framed, Dynamic, ApiTypes } from '../types/models';

type Quantity = {
  morning: number;
  afternoon: number;
  evening: number;
};

const withDay = () => {};

// FIXME: Now with this condition enabled we show only centers of auto chains. Rename accordingly

const withOnecheck = (data: Framed[], gathered: Record<string, Dynamic>) => {
  const criterion = gathered['onecheck'];

  if (!criterion.isOn) {
    return data;
  }

  const filtered = data.filter(({ center }) => {
    return center.apiType === ApiTypes.AUTOMATIC;
  });

  return filtered;
};

const withFrame = (data: Framed[], gathered: Record<string, Dynamic>) => {
  const criteria = gathered['timeframe'];
  const frames: string[] = Object.keys(criteria);
  const filtered = data.filter(({ frame }) => {
    return frames.some(
      (condition: string) => frame[condition as keyof Quantity] > 0,
    );
  });

  console.log(filtered, 'withFrame');

  return filtered;
};

const withCenter = (data: Framed[], gathered: Record<string, Dynamic>) => {
  const criteria = gathered['centers'];
  const centers: string[] = Object.keys(criteria);
  const filtered = data.filter(({ center: { name } }) => {
    return centers.includes(name);
  });

  return filtered;
};

const withStaff = (data: Framed[], gathered: Record<string, Dynamic>) => {
  const criteria = gathered['staff'];
  const staff: string[] = Object.keys(criteria);
  const filtered = data.filter(({ slots }) => {
    return slots.some(({ doctor }) => staff.includes(doctor));
  });

  return filtered;
};

const getCriteria = (filter: Filter) => {
  const keys = Object.keys(filter);

  const gathered: Record<string, Dynamic> = {};
  for (const key of keys) {
    const nested: Record<string, boolean> = filter[key as keyof Filter];
    const values: boolean[] = Object.values(nested);
    if (values.some((value: boolean) => value === true)) {
      const interm: Record<string, boolean> = {};
      const nkeys = Object.keys(nested);
      for (const nkey of nkeys) {
        if (nested[nkey as keyof (Frame | Dynamic)] === true) {
          interm[nkey] = nested[nkey];
        }
      }
      gathered[key] = interm;
    }
  }
  return gathered;
};

const withMultiple = (data: Framed[], filter: Filter) => {
  let filtered: Framed[] = [];
  let prefiltered = false;

  // const keys = Object.keys(filter);
  // const gathered: Record<string, Dynamic> = {};
  // for (const key of keys) {
  //   const nested: Record<string, boolean> = filter[key as keyof Filter];
  //   const values: boolean[] = Object.values(nested);
  //   if (values.some((value: boolean) => value === true)) {
  //     const interm: Record<string, boolean> = {};
  //     const nkeys = Object.keys(nested);
  //     for (const nkey of nkeys) {
  //       if (nested[nkey as keyof (Frame | Dynamic)] === true) {
  //         interm[nkey] = nested[nkey];
  //       }
  //     }
  //     gathered[key] = interm;
  //   }
  // }

  const gathered = getCriteria(filter);

  const unfiltered = Object.entries(gathered).length === 0;
  if (unfiltered) return data;

  console.log(gathered, 'gathered');

  if (gathered.hasOwnProperty('timeframe')) {
    filtered = withFrame(data, gathered);
    prefiltered = true;
  }

  if (gathered.hasOwnProperty('onecheck')) {
    console.log('onecheck works!');
    filtered = withOnecheck(prefiltered ? filtered : data, gathered);
    // filtered = withOnecheck(data, gathered);
    if (!prefiltered) prefiltered = true;
  }

  if (gathered.hasOwnProperty('centers')) {
    filtered = withCenter(prefiltered ? filtered : data, gathered);
    if (!prefiltered) prefiltered = true;
  }

  if (gathered.hasOwnProperty('staff')) {
    filtered = withStaff(prefiltered ? filtered : data, gathered);
    if (!prefiltered) prefiltered = true;
  }

  return filtered;
};

const filter = {
  getCriteria,
  withDay,
  withFrame,
  withCenter,
  withStaff,
  withMultiple,
};

export default filter;
