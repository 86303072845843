import { ReactComponent as Awesome } from 'icons/awesome.svg';
import { ReactComponent as Bad } from 'icons/bad.svg';
import { ReactComponent as Good } from 'icons/good.svg';
import { ReactComponent as MightBeBetter } from 'icons/mightBeBetter.svg';

import { EFeedback, TIcons } from './Feedback.types';

export const icons: TIcons = {
  [EFeedback.Bad]: Bad,
  [EFeedback.MightBeBetter]: MightBeBetter,
  [EFeedback.Good]: Good,
  [EFeedback.Awesome]: Awesome,
};
