import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import Dots from 'components/Dots';

const MuiBox = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'baseline',
});

const MuiTypography = styled(Typography)({
  fontWeight: 800,
  fontSize: '24px',
  lineHeight: 1.3,
  color: '#263238',
});

// TODO: 1.Get rid of ts-ignore. 2. Create a *.css for transitions etc.

const CentersQuantity = ({
  finished,
  quantity,
}: {
  finished: boolean;
  quantity: number;
}) => {
  const pendingRef = React.useRef(null);
  const finishedRef = React.useRef(null);
  const nodeRef = finished ? finishedRef : pendingRef;

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        // @ts-ignore
        key={finished}
        nodeRef={nodeRef}
        classNames="fade"
        addEndListener={(done: any) =>
          // @ts-ignore
          nodeRef.current.addEventListener('transitionend', done, false)
        }
      >
        <MuiBox ref={nodeRef}>
          {finished ? (
            <MuiTypography>{quantity} centri trovati</MuiTypography>
          ) : (
            <Dots />
          )}
        </MuiBox>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default CentersQuantity;
