import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createTheme } from '@mui/material/styles';
import React from 'react';

export const BookNowBlock = styled('div', { label: 'BookNowBlock' })<{
  withTitleAndBg?: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${({ withTitleAndBg }) =>
    withTitleAndBg ? '#e0f7fa' : 'transparent'};
  padding: 50px 0;
  @media (max-width: 460px) {
    ${({ withTitleAndBg }) =>
      !withTitleAndBg &&
      css`
        padding-top: 0;
      `}
  }
`;
export const ExamsHeader = styled('div')`
  display: flex;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  text-align: left;
  color: #121a26;
  margin-bottom: 20px;

  @media (max-width: 460px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ExamsDescription = styled('div')`
  display: flex;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #8298ab;
  margin-bottom: 54px;

  @media (max-width: 460px) {
    margin-bottom: 30px;
  }
`;
export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 1140px;

  @media (max-width: 460px) {
    width: 320px;
  }
`;

const theme = createTheme();
export const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.MobileMenuIconButton': {
              background: '#F4F8FB',
              borderRadius: '12px',
              [theme.breakpoints.up('lg')]: {
                display: 'none',
              },
            },
            '&.DesktopSearchIconButton': {
              marginLeft: 'auto',
              padding: '18px 25px',

              color: '#FFFFFF',
              background: '#D81B60',
              border: '1px solid #B0BEC5',
              borderLeft: 'none',
              borderRadius: '16px',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,

              '& > svg': {
                width: '29px',
                height: '29px',
              },
              '&:hover': {
                color: '#FFFFFF',
                background: '#D81B60',
              },
            },
            '&.SharedCleanerIconButton': {
              padding: 0,
            },
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '0px 12px',
            '&:not(:first-of-type)': {
              paddingLeft: '24px',
            },
            '&:not(:last-of-type)': {
              paddingRight: '24px',
            },
            '&.MuiDivider-root': {
              margin: 0,
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: 1.5,
            letterSpacing: '0.2px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            height: '10px',
            background: '#263238',
            borderRightWidth: 'initial',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '24px',
            fontWeight: 800,
            lineHeight: 1.3,
            color: '#263238',
            letterSpacing: 'inherit',
            [theme.breakpoints.up('lg')]: {
              maxWidth: '520px',
              fontSize: '48px',
              lineHeight: 1.2,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
            marginBottom: '16px',
            [theme.breakpoints.up('lg')]: {
              margin: 0,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Manrope',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            color: '#263238',
            background: '#FFFFFF',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: '1px solid #B0BEC5',
            borderRadius: '16px',
            [theme.breakpoints.up('lg')]: {
              border: 'none',
            },
          },
          input: {
            padding: '16px 16px 16px 12px',
            '&::placeholder': {
              fontWeight: 400,
              letterSpacing: '0.3px',
              // color: '#78909C',
              color: 'red',
            },
            '&.Mui-disabled': {
              textFillColor: '#263238',
              textTransform: 'capitalize',
            },
            [theme.breakpoints.up('lg')]: {
              padding: '23px 16px 23px 12px',
            },
          },
          notchedOutline: {
            border: 'none',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0,
          },
          label: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            color: '#263238',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '0px 12px 0px 0px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            width: '100%',
            marginTop: '24px',
            paddingTop: '16px',
            paddingBottom: '16px',
            background: '#D81B60',
            borderRadius: '16px',
            '&:hover': {
              background: '#D81B60',
            },
            '&.Mui-disabled': {
              background: '#90A4AE',
              color: '#ffffff',
            },
          },
          text: {
            fontFamily: 'Manrope',
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: 1.5,
            textTransform: 'none',
            color: '#ffffff',
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            height: '100vh',
            background: '#fff',
            overflow: 'scroll',
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            color: '#00838F',
          },
        },
      },
      // Desktop
      MuiPaper: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',

            border: '1px solid #B0BEC5',
            borderRight: 'none',
            borderRadius: '16px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: 'none',
          },
        },
      },
    },
  });
};
