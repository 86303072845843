import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import MuiContainer from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { parse } from 'date-fns';
import { io } from 'socket.io-client';
import Styled from 'components/Styled';
import Countdown from 'components/Countdown';
import { DatePicker } from 'components/DatePickers';
import Select from 'components/Inputs/Select';
import Cities from 'components/Inputs/Cities';
import IntlTelInput from 'components/Inputs/IntlTelInput';
import { MuiButton } from 'components/MuiStyledComponents';
import Masked from 'components/Masked';
import BookingConfirmation from 'components/BookingConfirmation';
import paths from 'routes/paths';
import { ReactComponent as Arrow } from 'icons/adornments/arrow.svg';
import { ReactComponent as Map } from 'icons/adornments/map.svg';
import { ReactComponent as Calendar } from 'icons/adornments/calendar.svg';
import { ReactComponent as Checked } from 'icons/checkboxes/checked.svg';
import { ReactComponent as NotChecked } from 'icons/checkboxes/not-checked.svg';
// import { ReactComponent as Spinner } from 'icons/spinner.svg';
import {
  IconProcessing,
  IconRejected,
  Logo,
  Cross,
} from 'icons-components/Icons';

import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  selectors as entrySelectors,
  setUnpaidReservation,
} from 'redux/entry/entry';
import { userSelectors } from 'redux/slices/user/selectors';

import {
  IFormState,
  IConfrmationModal,
  IModal,
  ERejected,
  ICreds,
} from './Confirmation.types';
import {
  Positioned,
  ModalWrapper,
  CallText,
  CaptionText,
} from './Confirmation.styled';
import { validate } from './utils';

import { mutate, dateHelpers } from 'utils';
// import { mutate, dateHelpers, notify } from 'utils';
// import useModal from 'hooks/useModal';
import { STORAGE_KEYS } from '../../constants';
import { Residence } from '../../components/Fields';
import { useSource } from './Confirmation.helpers';
import { getSocket } from 'utils';

const Title = styled(Typography)({
  marginBottom: '40px',
  fontWeight: 800,
  fontSize: '24px',
  lineHeight: 1.3,
  color: '#263238',
});

const Heading = styled(Typography)({
  marginBottom: '60px',
  fontWeight: 800,
  fontSize: '20px',
  lineHeight: 1.4,
  letterSpacing: '0.2px',
  color: '#263238',
});

const Subtitle = styled(Typography)({
  marginTop: '-10px',
  marginBottom: '10px',
  fontWeight: 800,
  fontSize: '18px',
  lineHeight: 1.4,
  letterSpacing: '0.2px',
  color: '#263238',
});

const Caption = styled(Typography)({
  marginBottom: '3px',
  fontSize: '14px',
  lineHeight: 1.4,
  letterSpacing: '0.3px',
  color: '#607D8B',
});

const Confirm = styled(Styled.Button)({ marginTop: '-10px' });

const FloatingBox = styled(Box)({
  position: 'sticky',
  top: '34px',
  width: '100%',
  maxWidth: '330px',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 24px 12px',
  background: '#F4F8FB',
  borderRadius: '20px',
});

const Test = styled(Typography)({
  marginBottom: '20px',
  fontWeight: '800',
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  color: '#212121',
});

const Item = styled(ListItem)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  '&:nth-of-type(1)': {
    marginBottom: '10px',
  },
  '& > svg': {
    flexShrink: 0,
  },
});

const Text = styled(Typography)({
  marginLeft: '5px',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  color: '#263238',
});

const Modify = styled(Button)({
  width: 'fit-content',
  alignSelf: 'end',
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: 1.2,
  color: '#00838F',
  textTransform: 'none',
});

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  margin: 0,
  '&:first-of-type': {
    marginRight: 0,
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '12px',
    lineHeight: 1.5,
    letterSpacing: '0.4px',
    color: '#607D8B',
  },
}));

const socket = getSocket('book');

const Rejected: React.FC<{
  rejected: ERejected;
  checkOtherSlots: () => void;
}> = ({ rejected, checkOtherSlots }) => {
  const slotUnavailable = rejected === ERejected.RESERVATION;
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& > svg': { flexShrink: 0 },
          ...(slotUnavailable ? { mb: '26px' } : {}),
        }}
      >
        <IconRejected />
        <Box sx={{ ml: '15px' }}>
          {slotUnavailable ? (
            <CallText>
              Ops.. lo slot da te scelto non è più disponibile!
            </CallText>
          ) : (
            <>
              <CallText sx={{ mb: '8px' }}>
                Ops.. il tuo pagamento non è andato a buon fine!
              </CallText>
              <CaptionText>
                Verifica i dettagli della carta o prova con un diverso metodo di
                pagamento.
              </CaptionText>
            </>
          )}
        </Box>
      </Box>
      {slotUnavailable && (
        <MuiButton.Primary sx={{ m: 0 }} onClick={checkOtherSlots}>
          Cerca un’altro slot
        </MuiButton.Primary>
      )}
    </Box>
  );
};

const Processing = () => {
  return (
    <>
      <Box
        sx={{
          animation: 'spin 3s linear infinite',
        }}
      >
        <IconProcessing />
      </Box>
      <CallText sx={{ ml: '16px' }}>
        Prenotazione <br /> appuntamento in corso
      </CallText>
    </>
  );
};

const Confirmation = (): JSX.Element => {
  // const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const test = useAppSelector(state => state.query.test);
  // const date = useAppSelector(state => state.query.date);
  const app = useAppSelector(state => state.appointment);

  const sourceId = useSource();

  const [open, setOpen] = useState(false);
  const openDatePicker = () => setOpen(true);
  const closeDatePicker = () => setOpen(false);

  const entry = useAppSelector(entrySelectors.getEntry);
  const { meta, user: entryUser, ...timeslotDetails } = entry;
  const user = useAppSelector(userSelectors.selectUser);
  const paymentError = useAppSelector(entrySelectors.selectPaymentError);
  const unpaidReservationID = useAppSelector(
    entrySelectors.selectUnpaidReservationID,
  );

  const [modal, setModal] = useState<IModal>({ open: false, status: '' });
  const [isRejected, setIsRejected] = useState<ERejected>(
    ERejected.RESERVATION,
  );

  const onModalClose = (event: any, reason: string) => {
    if (reason === 'backdropClick' && modal.status !== 'rejected') {
      return;
    }
    setModal({ open: false, status: '' });
  };

  const [dial, setDial] = useState('+39');

  const [dialog, setDialog] = useState<IConfrmationModal>({
    open: false,
    onConfirm: () => {},
    onDecline: () => {},
  });

  // const { Modal, onModalOpen } = useModal({
  //   Content: Processing,
  //   props: {
  //     shouldCloseOnBackdropClick: false,
  //     shouldCloseOnEscapeKeyDown: false,
  //   },
  // });

  const onDecline = () =>
    setDialog({ open: false, onConfirm: () => {}, onDecline: () => {} });

  const pay = (creds: ICreds) =>
    socket.emit('pay', creds, (response: Record<string, unknown>) => {
      if (response.hasOwnProperty('error')) {
        setIsRejected(ERejected.PAYMENT);
        dispatch(
          setUnpaidReservation({
            paymentError: true,
            unpaidReservationID: creds.id,
          }),
        );
        return setModal({ open: true, status: 'rejected' });
      }

      if (response.hasOwnProperty('id'))
        return navigate({
          pathname: `/${paths({ id: response.id as string }).bookingID}`,
        });
    });

  const onConfirm = (values: IFormState) => {
    const confirmation = sessionStorage.getItem(STORAGE_KEYS.CONFIRMATION);
    if (confirmation) sessionStorage.removeItem(STORAGE_KEYS.CONFIRMATION);

    const payload = {
      ...timeslotDetails,
      token: localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
      user: {
        id: user.info?.id,
        email: values.email,
        phone: `${dial} ${values.phone}`,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        birthDate: dateHelpers.getYYMMDD(
          parse(values.birthDate, 'dd/MM/yyyy', new Date()),
        ),
        nationality: '#',
        fiscalCode: values.fiscalCode,
        birthPlace: values.birthPlace,
        residenceAddress: values.residenceAddress,
        self: true,
      },
      ...(!!sourceId ? { sourceId } : {}),
    };

    const creds: Partial<ICreds> = {
      paymentType: 'CASH',
      callbackUrl: '#',
      callbackErrUrl: '#',
    };

    setModal({ open: true, status: 'processing' });

    if (!paymentError) {
      socket.emit('book', payload, (response: Record<string, unknown>) => {
        if (response.hasOwnProperty('error')) {
          return setModal({ open: true, status: 'rejected' });
        }

        if (response.hasOwnProperty('id'))
          return pay({ id: response.id as string, ...creds } as ICreds);
      });
    } else {
      pay({ id: unpaidReservationID, ...creds } as ICreds);
    }
  };

  const onFormSubmit = (values: IFormState) => {
    setDialog({
      open: true,
      onConfirm: () => {
        setDialog(prev => ({ ...prev, open: false }));
        // onModalOpen();
        onConfirm(values);
        // setTimeout(() => {
        //   onConfirm(values);
        // }, 3000);
      },
      onDecline,
    });
  };

  return (
    <MuiContainer sx={{ pt: '34px' }} maxWidth={'lg'}>
      <Title>Completa la tua prenotazione</Title>
      <Grid sx={{ position: 'relative' }} container columnSpacing="50px">
        <Grid item xs={7.2}>
          <Countdown />
          <Heading>Conferma anagrafica paziente</Heading>
          <Form
            onSubmit={onFormSubmit}
            validate={validate}
            mutators={{ mutate }}
            render={({ handleSubmit, form, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    rowSpacing="43px"
                    columnSpacing="17px"
                    sx={{ pr: '30px' }}
                  >
                    <Grid item container rowSpacing="43px" columnSpacing="17px">
                      <Grid item xs>
                        <Field
                          name="firstName"
                          render={({ input, meta: { touched, error } }) => {
                            return (
                              <Styled.Input
                                {...input}
                                className={classNames({
                                  'with-error': touched && error,
                                })}
                                label="Nome"
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Field
                          name="lastName"
                          render={({ input, meta: { touched, error } }) => {
                            return (
                              <Styled.Input
                                {...input}
                                className={classNames({
                                  'with-error': touched && error,
                                })}
                                label="Cognome"
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container rowSpacing="43px" columnSpacing="17px">
                      <Grid sx={{ position: 'relative' }} item xs>
                        <Field
                          name="birthDate"
                          initialValue=""
                          render={({ input, meta: { touched, error } }) => {
                            return (
                              <>
                                <Styled.Input
                                  {...input}
                                  className={classNames('with-pointer', {
                                    'with-error': touched && error,
                                  })}
                                  label="Data di Nascita"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  onClick={openDatePicker}
                                />
                                {open && (
                                  <ClickAwayListener
                                    onClickAway={closeDatePicker}
                                  >
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: 'calc(100% + 11px)',
                                        zIndex: 2,
                                      }}
                                    >
                                      <DatePicker
                                        type="birthday"
                                        selected={values['birthDate']}
                                        onTextFieldChange={form.mutators.mutate}
                                        onClose={closeDatePicker}
                                      />
                                    </Box>
                                  </ClickAwayListener>
                                )}
                              </>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Field
                          name="birthPlace"
                          render={({ input, meta: { touched, error } }) => {
                            return (
                              <Cities
                                setBirthplace={form.mutators.mutate}
                                isInvalid={touched && error}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Field
                        name="gender"
                        type="select"
                        initialValue="Seleziona"
                        render={({
                          input: { value, onChange },
                          meta: { touched, error },
                        }) => {
                          return (
                            <Select
                              value={value}
                              label="Genere"
                              onSelectChange={onChange}
                              options={['Seleziona', 'M', 'F']}
                              className={classNames({
                                Invalid: touched && error,
                              })}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Field
                        name="residenceAddress"
                        type="select"
                        render={({
                          input: { value, onChange },
                          meta: { touched, error },
                        }) => {
                          return (
                            <Residence
                              initialValue={value}
                              passResidence={form.mutators.mutate}
                              hasError={touched && error}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item container rowSpacing="43px" columnSpacing="17px">
                      <Grid item xs>
                        <Field
                          name="fiscalCode"
                          parse={(val: string) => val}
                          render={({ input, meta: { touched, error } }) => {
                            return (
                              <Styled.Input
                                {...input}
                                label="Codice Fiscale"
                                placeholder="FFF NNN YYMDD RRRRC"
                                className={classNames({
                                  'with-error': touched && error,
                                  'no-fiscal-code': values['noFiscalCode'],
                                })}
                                InputProps={{
                                  inputComponent: Masked as any,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={values['noFiscalCode']}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Field
                          name="noFiscalCode"
                          type="checkbox"
                          render={({ input }) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...input}
                                    icon={<NotChecked />}
                                    checkedIcon={<Checked />}
                                  />
                                }
                                label="Non dispongo di un codice fiscale (cittadino straniero)"
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Subtitle>Contatti Paziente</Subtitle>
                      <Caption>
                        Per comunicare informazioni importanti sulla
                        prenotazione
                      </Caption>
                    </Grid>
                    <Grid item container rowSpacing="43px" columnSpacing="17px">
                      <Grid item xs>
                        <Field
                          name="email"
                          render={({ input, meta: { touched, error } }) => {
                            return (
                              <Styled.Input
                                {...input}
                                className={classNames({
                                  'with-error': touched && error,
                                })}
                                label="Mail"
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Field
                          name="phone"
                          render={({
                            input: { value, onChange, onBlur },
                            meta: { touched, error },
                          }) => {
                            return (
                              <IntlTelInput
                                className={classNames({
                                  'with-error': touched && error,
                                })}
                                value={value}
                                onInputChange={onChange}
                                onInputBlur={onBlur}
                                onDialCodePass={(code: string) => setDial(code)}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Confirm type="submit">Conferma prenotazione</Confirm>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Grid>
        <Grid item xs={4.8}>
          <FloatingBox>
            <Test>{test}</Test>
            <List sx={{ mb: '20px' }}>
              <Item>
                <Map width="16" height="16" stroke="#7986CB" />
                <Text>{`${app.center} - ${app.address}`}</Text>
              </Item>
              <Item>
                <Calendar width="16" height="16" stroke="#7986CB" />
                <Text
                  sx={{ textTransform: 'capitalize' }}
                >{`${app.date} - ore: ${app.time}`}</Text>
              </Item>
            </List>
            <Modify
              onClick={() => navigate(paths().results)}
              endIcon={<Arrow />}
            >
              Modifica
            </Modify>
          </FloatingBox>
        </Grid>
      </Grid>
      <BookingConfirmation
        open={dialog.open}
        onConfirm={dialog.onConfirm}
        // @ts-ignore
        onDecline={dialog.onDecline}
      />
      {/* <Modal /> */}
      <Modal open={modal.open} onClose={onModalClose} disableEscapeKeyDown>
        <Positioned>
          <ModalWrapper
            className={classNames({
              block: modal.status === 'rejected',
              'padding-increased':
                modal.status === 'rejected' && isRejected === ERejected.PAYMENT,
            })}
          >
            <>
              {modal.status === 'rejected' && (
                <Box
                  sx={{
                    mb: '12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Logo />
                  <IconButton
                    sx={{ mr: '-8px' }}
                    onClick={() => setModal({ open: false, status: '' })}
                  >
                    <Cross />
                  </IconButton>
                </Box>
              )}
              {/* {modal.status === 'sent' && <Sent />} */}
              {modal.status === 'processing' && <Processing />}
              {modal.status === 'rejected' && (
                <Rejected
                  rejected={isRejected}
                  checkOtherSlots={() =>
                    navigate({
                      pathname: paths().results,
                      ...(sessionStorage.getItem('search')
                        ? { search: sessionStorage.getItem('search') as string }
                        : {}),
                    })
                  }
                />
              )}
            </>
          </ModalWrapper>
        </Positioned>
      </Modal>
    </MuiContainer>
  );
};

export default Confirmation;
