import { ApolloClient, InMemoryCache } from '@apollo/client';

import { apolloLinks } from './apollo-links';

const apolloClient = new ApolloClient({
  link: apolloLinks,
  cache: new InMemoryCache(),
});

export default apolloClient;
