import React from 'react';

import { ReactComponent as ArrowNarrowRightIcon } from '../../icons/arrow-narrow-right.svg';
import { Calendar, MapPinIcon } from '../../icons-components/Icons';
import { getDateAndTime } from '../../utils/getDateAndTime';
import paths from '../../routes/paths';
import { BookingStatus, IBooking } from '../../types';
import {
  BookingCardBox,
  BookingCardCanceled,
  BookingCardDoctor,
  BookingCardInfo,
  BookingCardLink,
  BookingCardPlace,
  BookingCardTest,
  BookingCardTime,
} from './BookingCards.styled';

interface BookingCardProps {
  booking: IBooking;
  isPast?: boolean;
}

export const BookingCard: React.FC<BookingCardProps> = ({
  booking,
  isPast,
}) => {
  const isCanceled =
    booking.status === BookingStatus.CancelledUser ||
    booking.status === BookingStatus.CancelledAdmin;

  return (
    <BookingCardBox>
      <BookingCardInfo>
        {isCanceled && (
          <BookingCardCanceled>Prenotazione Cancellata</BookingCardCanceled>
        )}
        <BookingCardTest isPast={isPast} isCanceled={isCanceled}>
          {booking.test.name}
        </BookingCardTest>
        <BookingCardPlace isPast={isPast}>
          <MapPinIcon fill={isPast ? '#607D8B' : '#7986CB'} />
          {`${booking.center.name} - ${booking.center.region.name}`}
        </BookingCardPlace>
        <BookingCardTime isPast={isPast}>
          <Calendar width={16} fill={isPast ? '#607D8B' : '#7986CB'} />
          {getDateAndTime(booking.date)}
        </BookingCardTime>
      </BookingCardInfo>
      <BookingCardInfo>
        <BookingCardDoctor isPast={isPast}>
          Dott. {booking.doctor}
        </BookingCardDoctor>
        <BookingCardLink to={`/${paths({ id: booking.id }).bookingID}`}>
          Vedi dettagli <ArrowNarrowRightIcon />
        </BookingCardLink>
      </BookingCardInfo>
    </BookingCardBox>
  );
};
