import { IBooking } from '../types';

export function groupBookings(bookings?: IBooking[]) {
  if (!bookings) return false;

  return bookings.reduce<{ past: IBooking[]; future: IBooking[] }>(
    (a, c) => {
      const date = new Date(c.date).getTime();
      const now = new Date().getTime();
      const isPastBooking = date < now;
      isPastBooking ? a.past.push(c) : a.future.push(c);
      return a;
    },
    { past: [], future: [] },
  );
}
