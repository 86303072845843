import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormApi } from 'final-form';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';

import Tickbox from '../components/Tickbox';
import schemas from '../graphql/schemas';
import { Cross, Magnifier, ShowAll } from '../icons-components/Icons';
import {
  getFilter,
  getCenters,
  getStaff,
  selectChainsNamesWithAutomaticApiType,
} from '../redux/filter/selectors';
import { setFilter } from '../redux/filter/slice';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { getIsQuerySpecific } from '../redux/query/selectors';
import { getQueryTestID } from '../redux/query/selectors';
import { Dynamic } from '../types/models';
import filtrate from '../utils/filter';
import Feature from './Feature';
import { FloatingBox } from './Styled';

interface IFilterProps {
  handleClose?: () => void;
  isFinished?: boolean;
  res?: any;
  unfiltered?: any;
  searchParams: any;
  setSearchParams: any;
  isLoading?: boolean;
  toggle: boolean;
  localChainsNamesWithAutomaticApiType: string[];
}

interface IComplexLabelProps {
  title: string;
  caption: string;
}

interface IStaffFilterEnabledTests {
  findSeoTypologyServices: { id: string }[];
}

interface IClinic {
  name: string;
  label: string;
}
type TClinic = Array<IClinic> | null;

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 0,
            position: 'absolute',
            top: '16px',
            left: '24px',
            [theme.breakpoints.up('lg')]: {
              display: 'none',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.Title': {
              fontWeight: 800,
              fontSize: '16px',
              lineHeight: 1.5,
              letterSpacing: '0.2px',
              color: '#263238',
              textAlign: 'center',
              [theme.breakpoints.up('lg')]: {
                display: 'none',
              },
            },
            '&.Subtitle': {
              fontWeight: 800,
              fontSize: '14px',
              lineHeight: 1.2,
              color: '#607D8B',
            },
            '&.Weekday': {
              fontSize: '16px',
              lineHeight: 1.2,
              letterSpacing: '0.4px',
              color: '#9C9D9F',
              '&.Active': {
                color: '#FFFFFF',
              },
              [theme.breakpoints.up('lg')]: {
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: 1.5,
                color: '#263238',
              },
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '12px',
            lineHeight: 1.5,
            letterSpacing: '0.4px',
            color: '#607D8B',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          thumb: {
            background: '#FFFFFF',
          },
          track: {
            background: '#CFD8DC',
          },
          switchBase: {
            '&.Mui-checked+.MuiSwitch-track': {
              opacity: 1,
              background: '#00838F',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            display: 'flex',
            aligItems: 'center',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
            width: 'auto',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: 0,
            '&.Search': {
              width: '100%',
              paddingTop: '15px',
              paddingBottom: '15px',
              background: '#D81B60',
              borderRadius: '16px',
              [theme.breakpoints.up('lg')]: {
                marginTop: '12px',
              },
              '&:hover': {
                background: '#D81B60',
                textDecoration: 'none',
              },
            },
            '&:hover': {
              background: 'transparent',
              textDecoration: 'underline',
            },
          },
          text: {
            fontFamily: 'inherit',
            fontWeight: 800,
            fontSize: '12px',
            lineHeight: 1.5,
            letterSpacing: '0.4px',
            color: '#607D8B',
            textTransform: 'none',
            '&.Search': {
              fontSize: '16px',
              letterSpacing: '0.2px',
              color: '#FFFFFF',
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            '&.ListItemDivider': {
              height: '10px',
              margin: '0px 6px',
              alignSelf: 'center',
              background: '#607D8B',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Manrope',
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            color: '#263238',
            caret: '#263238',
            [theme.breakpoints.up('lg')]: {
              fontSize: '12px',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            paddingLeft: '16px',
            border: '1px solid #B0BEC5',
            borderRadius: '16px',
          },
          input: {
            padding: '16px 12px',
            [theme.breakpoints.up('lg')]: {
              padding: '14px 12px',
            },
            '&::placeholder': {
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: 1.5,
              letterSpacing: '0.3px',
              color: '#78909C',
              [theme.breakpoints.up('lg')]: {
                fontSize: '12px',
                letterSpacing: '0.4px',
              },
            },
          },
          notchedOutline: {
            border: 'none',
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            [theme.breakpoints.up('lg')]: {
              color: '#09121F',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
    },
  });
};

const weekdays = [
  { name: 'weekdays.mon', label: 'Lun' },
  { name: 'weekdays.tue', label: 'Mar' },
  { name: 'weekdays.wed', label: 'Mer' },
  { name: 'weekdays.thu', label: 'Gio' },
  { name: 'weekdays.fri', label: 'Ven' },
];

const ShowMore = styled(Button)({
  marginTop: '16px',
  padding: 0,
  fontWeight: 800,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#00838F',
});

const TYPOLOGY = 'visite-specialistiche';

const CenterPlaceholder = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Skeleton
        sx={{
          width: '24px',
          height: '24px',
          transform: 'none',
          transformOrigin: 'initial',
          borderRadius: '8px',
          marginRight: '12px',
        }}
      />
      <Skeleton
        sx={{
          width: '90px',
          height: '18px',
          transform: 'none',
          transformOrigin: 'initial',
        }}
      />
    </Box>
  );
};

const ComplexLabel = (props: IComplexLabelProps): JSX.Element => {
  const { title, caption } = props;
  return (
    <Box>
      <Typography className="FormLabelTitle">{title}</Typography>
      <Typography className="FormLabelCaption">{caption}</Typography>
    </Box>
  );
};

const WeekdayCheckbox = (props: {
  isChecked: boolean;
  weekday: string;
}): JSX.Element => {
  const { isChecked, weekday } = props;
  const isDesktop = useMediaQuery('(min-width:1200px)');

  return (
    <Box
      sx={{
        ...(isDesktop
          ? {
              width: '30px',
              height: '25px',
            }
          : {
              width: '40px',
              height: '40px',
            }),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: isChecked ? '#00838F' : 'transparent',
        border: `1px solid ${isChecked ? '#00838F' : '#B0BEC5'}`,
        borderRadius: isDesktop ? '6px' : '4px',
      }}
    >
      <Typography className={isChecked ? 'Weekday Active' : 'Weekday'}>
        {weekday}
      </Typography>
    </Box>
  );
};

export const normalize = (data: Dynamic, type: 'direct' | 'opposite') => {
  const normalized: Dynamic = {};
  const keys = Object.keys(data);

  for (const key of keys) {
    const replaced =
      type === 'direct' ? key.replaceAll('.', '_') : key.replaceAll('_', '.');
    normalized[replaced as keyof Dynamic] = data[key as keyof Dynamic];
  }

  return normalized;
};

const Filter = (props: IFilterProps): JSX.Element => {
  // const { handleClose, isFinished, unfiltered } = props;
  const {
    handleClose,
    isLoading,
    unfiltered,
    toggle,
    localChainsNamesWithAutomaticApiType,
  } = props;

  const [shouldStaffBeVisible, setShouldStaffBeVisible] = useState(false);

  const onQueryCompleted = (data: IStaffFilterEnabledTests) => {
    const test = data.findSeoTypologyServices.find(
      service => service.id === testID || '',
    );
    const isInTheList = Boolean(test);
    if (!isInTheList) return;
    setShouldStaffBeVisible(true);
  };

  const testID = useAppSelector(getQueryTestID);
  const [getStaffFilterEnabledTests] = useLazyQuery<IStaffFilterEnabledTests>(
    schemas.GET_STAFF_FILTER_ENABLED_TESTS,
    {
      variables: {
        typology: TYPOLOGY,
      },
      onCompleted: onQueryCompleted,
    },
  );

  useEffect(() => {
    getStaffFilterEnabledTests();
  }, [getStaffFilterEnabledTests]);

  const clear = ([name]: [string], state: object, { changeValue }: any) => {
    changeValue(state, name, () => false);
  };

  const select = ([name]: [string], state: object, { changeValue }: any) => {
    changeValue(state, name, () => true);
  };

  const dispatch = useAppDispatch();
  const filterState = useAppSelector(getFilter);

  console.log(filterState, 'filterState');

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const onFormSubmit = (values: any) => {
    const { staff } = values;
    const normalized = normalize(staff, 'opposite');
    let personnel = null;
    let dests = null;

    const payload = { ...values, staff: normalized };

    const withoutRedirect = unfiltered.filter(
      ({ center }: any) => !center['apiUrl'],
    );
    const test = filtrate.withMultiple(withoutRedirect, {
      ...values,
      staff: normalized,
      onecheck: {
        isOn: toggle,
      },
    });

    const positives = filtrate.getCriteria({
      ...values,
      staff: normalized,
      onecheck: { isOn: toggle },
    });
    const invalid = Object.entries(positives).length === 0;

    if (
      positives.hasOwnProperty('centers') &&
      !positives.hasOwnProperty('staff')
      // (!positives.hasOwnProperty('centers') &&
      //   !positives.hasOwnProperty('staff'))
    ) {
      personnel = test.reduce((acc: any, val: any) => {
        const { slots } = val;
        const doctors = slots.reduce((acc: any, slot: any) => {
          const { doctor } = slot;

          if (acc.hasOwnProperty(doctor)) return acc;
          return { ...acc, [doctor]: false };
        }, {});
        return { ...acc, ...doctors };
      }, {});
    } else if (
      positives.hasOwnProperty('staff') &&
      !positives.hasOwnProperty('centers')
      // (!positives.hasOwnProperty('staff') &&
      //   !positives.hasOwnProperty('centers'))
    ) {
      dests = test?.reduce((acc: any, val: any) => {
        const {
          center: { name },
        } = val;
        if (acc.hasOwnProperty(name)) return acc;
        return { ...acc, [name]: false };
      }, {});
    } else if (
      (!positives.hasOwnProperty('staff') &&
        !positives.hasOwnProperty('centers')) ||
      positives.hasOwnProperty('onecheck')
    ) {
      personnel = test?.reduce((acc: any, val: any) => {
        const { slots } = val;
        const doctors = slots.reduce((acc: any, slot: any) => {
          const { doctor } = slot;

          if (acc.hasOwnProperty(doctor)) return acc;
          return { ...acc, [doctor]: false };
        }, {});
        return { ...acc, ...doctors };
      }, {});
      dests = test?.reduce((acc: any, val: any) => {
        const {
          center: { name },
        } = val;
        if (acc.hasOwnProperty(name)) return acc;
        return { ...acc, [name]: false };
      }, {});
    }

    const newValues = {
      ...values,
      staff: personnel ? personnel : normalized,
      centers: dests ? dests : values?.centers,
      onecheck: { isOn: toggle },
      // chainsNamesWithAutomaticApiType: filterState?.chainsNamesWithAutomaticApiType,
      chainsNamesWithAutomaticApiType: localChainsNamesWithAutomaticApiType,
      // chainsNamesWithAutomaticApiType: ['Humanitas'],
    };

    dispatch(setFilter(newValues));
    props.searchParams.set('filter', JSON.stringify(newValues));
    props.setSearchParams(props.searchParams, { replace: true });
    personnel = null;
    dests = null;
    // dispatch(setFilter({ ...values, staff: doctors, centers, ...positives }));

    // if (!isDesktop && handleClose) {
    //   handleClose();
    // }
  };

  const centers = useAppSelector(getCenters);
  const list = useMemo(() => {
    const keys = Object.keys(centers);
    if (keys.length === 0) return [];
    return keys.reduce((acc: any, val: any) => {
      return [
        ...acc,
        { name: `centers.${val}`, label: val, isChecked: centers[val] },
      ];
    }, []);
  }, [centers]);

  const chainsNamesWithAutomaticApiType = useAppSelector(
    selectChainsNamesWithAutomaticApiType,
  );
  // console.log({ list, chainsNamesWithAutomaticApiType }, 'toggled');

  const hasCheckedCenters = list.some((center: any) => center.isChecked);
  const checkedCenters = list.filter((center: any) => center.isChecked);
  const uncheckedCentersOfAllTypes = list.filter((center: any) =>
    checkedCenters.every((checkedCenter: any) => {
      const chainsNamesUnchecked = checkedCenter.label !== center.label;
      return chainsNamesUnchecked;
    }),
  );
  const uncheckedCenters = useMemo(() => {
    if (toggle) {
      return uncheckedCentersOfAllTypes.filter((uncheckedCenter: any) =>
        localChainsNamesWithAutomaticApiType.includes(uncheckedCenter.label),
      );
    }
    return uncheckedCentersOfAllTypes;
  }, [
    toggle,
    uncheckedCentersOfAllTypes,
    localChainsNamesWithAutomaticApiType,
  ]);

  // console.log({ uncheckedCenters, list }, 'uncheckedCenters');

  const [uncheckedCentersShown, setUncheckedCentersShown] = useState(false);

  const staff = useAppSelector(getStaff);
  const names = useMemo(() => {
    const keys = Object.keys(staff);
    if (keys.length === 0) return [];
    return keys.reduce((acc: any, val: any) => {
      if (val === 'Medico di staff') {
        return [...acc, { name: `staff.${val}`, label: val }];
      }

      const normalized = val
        .split(' ')
        .map(
          (sub: string) => sub.substring(0, 1) + sub.substring(1).toLowerCase(),
        )
        .join(' ');

      const replaced = val.replaceAll('.', '_');

      return [...acc, { name: `staff.${replaced}`, label: normalized }];
    }, []);
  }, [staff]);

  let inst: any;

  // console.log(list, 'list');

  const initialValues = useMemo(() => {
    // const { timeframe, centers, staff } = filterState;
    // const timeframeEmpty = Object.values(timeframe).some((val: any) => !val);
    // const centersEmpty = Object.values(centers).some((val: any) => !val);
    // const staffEmpty = Object.values(staff).some((val: any) => !val);

    // if (props.searchParams.get("filter") && timeframeEmpty && centersEmpty && staffEmpty) {
    // if (isFinished) return inst.submit();
    if (props.searchParams.get('filter')) {
      const vals = JSON.parse(props.searchParams.get('filter'));

      const { staff: valsStaff } = vals;
      const normalized = normalize(valsStaff, 'direct');

      return { ...vals, staff: normalized };
    }

    // const { staff } = filterState;
    // const normalized = normalize(staff, "direct");

    // return { ...filterState, staff: normalized };
    return { timeframe: filterState.timeframe, centers: {}, staff: {} };
  }, []);

  useEffect(() => {
    inst.submit();
  }, [initialValues]);

  const [shouldBeShown, setShouldBeShown] = useState(false);

  // const day = useAppSelector(getQueryDate);
  // const soonest = day === 'Prima possibile';
  const isQuerySpecific = useAppSelector(getIsQuerySpecific);

  const instance: React.MutableRefObject<FormApi | null> = useRef(null);
  useEffect(() => {
    if (!isLoading || !instance.current) return;
    instance.current.reset();
  }, [isLoading]);

  useEffect(() => {
    instance.current?.submit();
  }, [toggle]);

  // const [clinic, setClinic] = useState<TClinic>(null);
  // console.log({ clinic, list, centers }, 'clinic');

  // console.log({ uncheckedCenters, checkedCenters, hasCheckedCenters, uncheckedCentersShown });

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Container sx={{ pt: '16px', pb: '16px' }} className="bare">
        {!isDesktop && (
          <Box sx={{ mb: '40px' }}>
            <IconButton onClick={handleClose}>
              <Cross />
            </IconButton>
            <Typography className="Title">{'Filtra risultati'}</Typography>
          </Box>
        )}
        <Box sx={isDesktop ? { display: 'flex' } : {}}>
          <Box sx={isDesktop ? { mr: '15px' } : { pb: '168px' }}>
            <Form
              onSubmit={onFormSubmit}
              initialValues={initialValues}
              mutators={{ clear, select }}
              render={({ handleSubmit, form, values }) => {
                instance.current = form;

                inst = form;
                const { medical } = values;
                const filtered = names.filter(
                  (name: { name: string; label: string }) =>
                    name.label
                      .toLowerCase()
                      .includes(medical?.trim().toLowerCase()),
                );

                const handleCheck = (
                  desktop: boolean,
                  event: React.ChangeEvent<HTMLInputElement>,
                  handler: (checked: boolean) => void,
                  // clinic?: IClinic,
                ) => {
                  const {
                    target: { checked },
                  } = event;
                  handler(checked);

                  // if (clinic) setClinic(prev => (prev ? [...prev, clinic] : [clinic]));

                  if (desktop) {
                    form.submit();
                  }
                };
                return (
                  <form id="filter" onSubmit={handleSubmit}>
                    <Typography sx={{ mb: '16px' }} className="Subtitle">
                      {'Gruppo sanitario'}
                    </Typography>
                    <List
                      sx={{
                        mb: '16px',
                      }}
                    >
                      <ListItem>
                        <Button
                          disableRipple
                          onClick={() => {
                            setShouldBeShown(true);
                            list?.forEach(
                              (center: { name: string; label: string }) =>
                                form.mutators.select(center.name),
                            );
                            if (isDesktop) form.submit();
                          }}
                        >
                          {'Seleziona tutti'}
                        </Button>
                      </ListItem>
                      <Divider
                        className="ListItemDivider"
                        orientation="vertical"
                        flexItem
                      />
                      <ListItem>
                        <Button
                          disableRipple
                          sx={{ color: '#00838F' }}
                          onClick={() => {
                            setShouldBeShown(false);
                            setUncheckedCentersShown(false);
                            list?.forEach(
                              (center: { name: string; label: string }) =>
                                form.mutators.clear(center.name),
                            );
                            if (isDesktop) form.submit();
                          }}
                        >
                          {'Cancella tutto'}
                        </Button>
                      </ListItem>
                    </List>
                    {isLoading ? (
                      Array.from({ length: 3 }, (_, i) => i).map((val: any) => (
                        <Box key={val} sx={{ mb: val === 2 ? '' : '5px' }}>
                          <CenterPlaceholder />
                        </Box>
                      ))
                    ) : hasCheckedCenters ? (
                      <>
                        {checkedCenters.map(
                          (
                            center: { name: string; label: string },
                            index: number,
                          ) => (
                            <Field
                              key={index}
                              name={center.name}
                              type="checkbox"
                              render={({ input: { checked, onChange } }) => (
                                <Tickbox
                                  styles={
                                    index === checkedCenters?.length - 1 &&
                                    !uncheckedCentersShown
                                      ? { mb: 0 }
                                      : {}
                                  }
                                  label={center.label}
                                  isChecked={checked || false}
                                  handleChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleCheck(isDesktop, event, onChange)}
                                />
                              )}
                            />
                          ),
                        )}
                        {uncheckedCentersShown &&
                          uncheckedCenters.map(
                            (
                              center: { name: string; label: string },
                              index: number,
                            ) => (
                              <Field
                                key={index}
                                name={center.name}
                                type="checkbox"
                                render={({ input: { checked, onChange } }) => (
                                  <Tickbox
                                    styles={
                                      index === uncheckedCenters?.length - 1
                                        ? { mb: 0 }
                                        : {}
                                    }
                                    label={center.label}
                                    isChecked={checked || false}
                                    handleChange={(
                                      event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      setUncheckedCentersShown(false);
                                      handleCheck(isDesktop, event, onChange);
                                    }}
                                  />
                                )}
                              />
                            ),
                          )}
                        {uncheckedCenters.length !== 0 && (
                          <ShowMore
                            sx={
                              uncheckedCentersShown
                                ? {
                                    '& > span': { transform: 'rotate(180deg)' },
                                  }
                                : {}
                            }
                            endIcon={<ShowAll />}
                            onClick={() =>
                              setUncheckedCentersShown(prev => !prev)
                            }
                            disableRipple
                          >
                            {uncheckedCentersShown
                              ? 'Mostra meno'
                              : 'Mostra di più'}
                          </ShowMore>
                        )}
                      </>
                    ) : (
                      // list
                      uncheckedCenters
                        ?.slice(0, 3)
                        .map(
                          (
                            center: { name: string; label: string },
                            index: number,
                          ) => (
                            <Field
                              key={index}
                              name={center.name}
                              type="checkbox"
                              render={({ input: { checked, onChange } }) => (
                                <Tickbox
                                  styles={
                                    index === list?.slice(0, 3).length - 1
                                      ? { mb: 0 }
                                      : {}
                                  }
                                  label={center.label}
                                  isChecked={checked || false}
                                  handleChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleCheck(isDesktop, event, onChange)}
                                />
                              )}
                            />
                          ),
                        )
                    )}
                    {/* list */}
                    {!isLoading &&
                      !shouldBeShown &&
                      uncheckedCenters?.length > 3 &&
                      !hasCheckedCenters && (
                        <ShowMore
                          endIcon={<ShowAll />}
                          onClick={() => setShouldBeShown(true)}
                          disableRipple
                        >
                          {'Vedi tutti'}
                        </ShowMore>
                      )}
                    {shouldBeShown &&
                      !hasCheckedCenters &&
                      // list
                      uncheckedCenters
                        ?.slice(3)
                        .map(
                          (
                            center: { name: string; label: string },
                            index: number,
                          ) => (
                            <Field
                              key={index}
                              name={center.name}
                              type="checkbox"
                              render={({ input: { checked, onChange } }) => (
                                <Tickbox
                                  styles={
                                    index === 0 && list.slice(3).length === 1
                                      ? { mt: '12px', mb: 0 }
                                      : index === 0
                                      ? { mt: '12px' }
                                      : index === list?.slice(3).length - 1
                                      ? { mb: 0 }
                                      : {}
                                  }
                                  label={center.label}
                                  isChecked={checked || false}
                                  handleChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleCheck(isDesktop, event, onChange)}
                                />
                              )}
                            />
                          ),
                        )}

                    {shouldBeShown &&
                      !hasCheckedCenters &&
                      uncheckedCenters?.length > 3 && (
                        <ShowMore
                          sx={{
                            '& > span': {
                              transform: 'rotate(180deg)',
                            },
                          }}
                          endIcon={<ShowAll />}
                          onClick={() => setShouldBeShown(false)}
                          disableRipple
                        >
                          {'Vedi meno'}
                        </ShowMore>
                      )}

                    <Divider sx={{ mt: '12px', mb: '24px' }} />
                    <Feature>
                      <Typography sx={{ mb: '16px' }} className="Subtitle">
                        {'Disponibilità'}
                      </Typography>
                      <FormControlLabel
                        sx={{ mb: '16px', mr: isDesktop ? '0px' : 'auto`' }}
                        label={'Scegli degli slot orari più precisi'}
                        control={<Switch />}
                      />
                    </Feature>
                    <Field
                      name="timeframe.morning"
                      type="checkbox"
                      render={({ input: { checked, onChange } }) => (
                        <Tickbox
                          label={
                            <ComplexLabel
                              title={'Mattina'}
                              caption={`Dalle 06:00 alle 12:59`}
                            />
                          }
                          isChecked={checked || false}
                          handleChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => handleCheck(isDesktop, event, onChange)}
                        />
                      )}
                    />
                    <Field
                      name="timeframe.afternoon"
                      type="checkbox"
                      render={({ input: { checked, onChange } }) => (
                        <Tickbox
                          label={
                            <ComplexLabel
                              title={'Pomeriggio'}
                              caption={`Dalle 13:00 alle 17:59`}
                            />
                          }
                          isChecked={checked || false}
                          handleChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => handleCheck(isDesktop, event, onChange)}
                        />
                      )}
                    />
                    <Field
                      name="timeframe.evening"
                      type="checkbox"
                      render={({ input: { checked, onChange } }) => (
                        <Tickbox
                          label={
                            <ComplexLabel
                              title={'Sera'}
                              caption={`Dalle 18:00 alle 21:00`}
                            />
                          }
                          isChecked={checked || false}
                          handleChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => handleCheck(isDesktop, event, onChange)}
                        />
                      )}
                    />
                    <Feature on={shouldStaffBeVisible}>
                      <Divider sx={{ mt: '14px', mb: '24px' }} />
                      <Typography sx={{ mb: '16px' }} className="Subtitle">
                        {'Medico'}
                      </Typography>
                      <Field
                        name="medical"
                        initialValue=""
                        render={({ input: { value, onChange } }) => (
                          <TextField
                            sx={{ mb: '18px' }}
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Magnifier />
                                </InputAdornment>
                              ),
                            }}
                            placeholder={'Filtra per nome medico'}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      {/* {(medical?.trim().length > 0 ? filtered : names)?.map(
                      (
                        center: { name: string; label: string },
                        index: number,
                      ) => (
                        <Field
                          key={index}
                          name={center.name}
                          delimiter="/"
                          type="checkbox"
                          render={({ input: { checked, onChange } }) => (
                            <Tickbox
                              styles={
                                index ===
                                (medical?.trim().length > 0 ? filtered : names)
                                  .length -
                                  1
                                  ? { mb: 0 }
                                  : {}
                              }
                              label={center.label}
                              isChecked={checked || false}
                              handleChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => handleCheck(isDesktop, event, onChange)}
                            />
                          )}
                        />
                      ),
                    )} */}
                      {isLoading
                        ? Array.from({ length: 3 }, (_, i) => i).map(
                            (val: any) => (
                              <Box
                                key={val}
                                sx={{ mb: val === 2 ? '' : '5px' }}
                              >
                                <CenterPlaceholder />
                              </Box>
                            ),
                          )
                        : (medical?.trim().length > 0 ? filtered : names)?.map(
                            (
                              center: { name: string; label: string },
                              index: number,
                            ) => (
                              <Field
                                key={index}
                                name={center.name}
                                delimiter="/"
                                type="checkbox"
                                render={({ input: { checked, onChange } }) => (
                                  <Tickbox
                                    styles={
                                      index ===
                                      (medical?.trim().length > 0
                                        ? filtered
                                        : names
                                      ).length -
                                        1
                                        ? { mb: 0 }
                                        : {}
                                    }
                                    label={center.label}
                                    isChecked={checked || false}
                                    handleChange={(
                                      event: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleCheck(isDesktop, event, onChange)
                                    }
                                  />
                                )}
                              />
                            ),
                          )}
                      <Field
                        name="_staff"
                        type="checkbox"
                        render={({ input: { checked, onChange } }) => (
                          <Tickbox
                            styles={
                              names.length > 0 || isLoading
                                ? { mt: '12px' }
                                : {}
                            }
                            label={'Mostra solo medico generico di staff'}
                            isChecked={checked || false}
                            handleChange={onChange}
                          />
                        )}
                      />
                    </Feature>
                    <Feature>
                      <Divider sx={{ mt: '12px', mb: '24px' }} />
                      <Typography sx={{ mb: '16px' }} className="Subtitle">
                        {'Convenzione'}
                      </Typography>
                      <Field
                        name="terms"
                        render={({ input: { value, onChange } }) => (
                          <TextField
                            sx={{ mb: '18px' }}
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Magnifier />
                                </InputAdornment>
                              ),
                            }}
                            placeholder={'Filtra convenzioni per nome'}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                      {['Unisalute', 'Allianz', 'Fondo salute'].map(
                        (field, index) => (
                          <Field
                            key={index}
                            name={field.split(' ')[0].toLowerCase()}
                            type="checkbox"
                            render={({ input: { checked, onChange } }) => (
                              <Tickbox
                                label={field}
                                isChecked={checked || false}
                                handleChange={onChange}
                              />
                            )}
                          />
                        ),
                      )}
                      <Divider sx={{ mt: '24px', mb: '24px' }} />
                      <Typography sx={{ mb: '16px' }} className="Subtitle">
                        {'Logistica'}
                      </Typography>
                      <Field
                        name="with_parking_lot"
                        type="checkbox"
                        render={({ input: { checked, onChange } }) => (
                          <Tickbox
                            label={'Parcheggio in struttura'}
                            isChecked={checked || false}
                            handleChange={onChange}
                          />
                        )}
                      />
                      <Field
                        name="close_to_subway"
                        type="checkbox"
                        render={({ input: { checked, onChange } }) => (
                          <Tickbox
                            label={'Vicino alla metro'}
                            isChecked={checked || false}
                            handleChange={onChange}
                          />
                        )}
                      />
                      {isDesktop && (
                        <Button type="submit" className="Search">
                          {'Cerca'}
                        </Button>
                      )}
                    </Feature>
                  </form>
                );
              }}
            />
          </Box>
          {isDesktop && <Divider orientation="vertical" flexItem />}
        </Box>
        {!isDesktop && (
          <FloatingBox>
            <Button
              form="filter"
              className="Search"
              onClick={() => {
                instance.current?.submit();
                if (handleClose) handleClose();
              }}
            >
              {'Cerca'}
            </Button>
          </FloatingBox>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Filter;
