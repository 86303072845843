import { Navigate, Outlet } from 'react-router-dom';

import { authSelectors } from '../redux/slices/auth/selectors';
import { useAppSelector } from '../redux/hooks';

export const PrivateRoutes = () => {
  const isLoggedIn = useAppSelector(authSelectors.selectIsLoggedIn);

  return isLoggedIn ? <Outlet /> : <Navigate to={'/auth'} />;
};
