import { styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Popper, { PopperProps } from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

export const MuiAutocomplete = styled(Autocomplete)({
  '& label': {
    top: 'auto',
    bottom: 'calc(100% + 6px)',
    transform: 'none',
    transformOrigin: 'unset',
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: 1.2,
    color: '#607D8B',
    '&.Mui-focused': {
      color: '#607D8B',
    },
  },
  '&.with-error': {
    '& label': {
      color: '#D81B60',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: '#D81B60',
    },
    '& .MuiFormHelperText-root': {
      color: '#D81B60',
    },
  },
  '& legend': {
    height: 'auto',
  },
  '& .MuiOutlinedInput-root': {
    padding: '8.5px',
    fontFamily: 'inherit',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.2px',
    color: '#263238',
    border: '1px solid #B0BEC5',
    borderRadius: '16px',
    '&.Mui-focused': {
      borderColor: '#263238',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.regime': {
    marginBottom: '46px',
    '&:last-of-type': {
      marginBottom: '17px',
    },
  },
}) as typeof Autocomplete;

export const MuiPopper = styled((props: PopperProps) => (
  <Popper
    {...props}
    modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
  />
))({});

export const MuiPaper = styled(Paper)({
  border: '1px solid #B0BEC5',
  borderRadius: '16px',
  '& .MuiAutocomplete-listbox': {
    width: '100%',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
});
