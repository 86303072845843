import { PRIMA_POSSIBILE_DATE } from '../../../constants';
import { Cross, NextArrow, PrevArrow } from '../../../icons-components/Icons';
import { publicHolidays } from '../data';
import { isWeekend } from '../utils';
import dateUtils from '../../../utils/date';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';
import Select from 'components/Inputs/Select';
import {
  format,
  parse,
  subYears,
  startOfTomorrow,
  isFriday,
  startOfToday,
  nextMonday,
} from 'date-fns';
import { getYear, setDefaultOptions } from 'date-fns';
import { it } from 'date-fns/locale';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';

import { DatePickerInput } from '../index';
import { EDatePickerType } from '../DatePickerInput/DatePickerInput.types';

registerLocale('it', it);
setDefaultOptions({ locale: it });

interface IDateProps {
  selected: string;
  onClose: () => void;
  // onTextFieldChange?: (date: string) => void;
  onTextFieldChange?: any;
  onDateChangeExpanded?: any;
  type?: string;
  guest?: boolean;
  uncentered?: boolean;
  availableDates?: string[];
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.CloseIcon': {
              padding: 0,
              position: 'absolute',
              top: '16px',
              left: '24px',

              [theme.breakpoints.up('lg')]: {
                display: 'none',
              },
            },
            '&.ArrowIcon': {
              padding: '8px',
              background: '#F4F8FB',
              borderRadius: '12px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            width: '100%',
            padding: '14px 0px',
            fontFamily: 'inherit',
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: '150%',
            color: '#121A26',
            textTransform: 'none',
            border: '1px solid #B0BEC5',
            borderRadius: '16px',

            [theme.breakpoints.up('lg')]: {
              paddingTop: '16px',
              paddingBottom: '16px',
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: '24px',
            marginBottom: '24px',
            borderColor: '#CFD8DC',

            [theme.breakpoints.up('lg')]: {
              marginTop: '16px',
              marginBottom: '16px',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.Call': {
              marginBottom: '21px',

              [theme.breakpoints.up('lg')]: {
                marginBottom: '16px',
              },
            },
            '&.Month': {
              textTransform: 'capitalize',
            },
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: '150%',
            textAlign: 'center',
          },
        },
      },
    },
  });
};

const getMinDate = (currDate: Date) => {
  if (isFriday(currDate)) return nextMonday(currDate);
  return startOfTomorrow();
};

const getSelectedDate = (date: string, type: any) => {
  if (date.length === 0 || date === PRIMA_POSSIBILE_DATE) {
    return type === 'birthday' ? startOfToday() : getMinDate(startOfToday());
  }

  if (type && type === 'birthday') {
    return parse(date, 'dd/MM/yyyy', new Date());
  }

  const [, cut] = date.split(' ');
  const parsed = parse(cut, 'dd/MM/yy', new Date());
  return parsed;
};

const years = Array.from({ length: 100 }, (_, i) => (i + 1924).toString());

const Wrapper: React.FC<{
  uncentered?: boolean;
  children: React.ReactNode;
}> = ({ uncentered, children }) => {
  return uncentered ? (
    <>{children}</>
  ) : (
    <Container className="bare">{children}</Container>
  );
};

const Picker = (props: IDateProps): JSX.Element => {
  const {
    type,
    selected,
    onClose,
    onTextFieldChange,
    onDateChangeExpanded,
    uncentered,
    availableDates,
  } = props;

  const [value, setValue] = useState<Date | null>(() =>
    getSelectedDate(selected, type),
  );
  const [isOpen, setIsOpen] = useState(true);

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const birthdayPicker = type === 'birthday';

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Wrapper uncentered={uncentered}>
        <Box
          sx={{
            background: '#FFFFFF',
            ...(isDesktop
              ? {
                  padding: '26px 17px',
                  width: '326px',
                  border: '1px solid #B0BEC5',
                  borderRadius: '20px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }
              : {
                  paddingTop: '68px',
                  ...(uncentered
                    ? { paddingLeft: '8px', paddingRight: '8px' }
                    : {}),
                }),
          }}
        >
          <IconButton className="CloseIcon" onClick={onClose}>
            <Cross />
          </IconButton>
          {(!type || type !== 'birthday') && (
            <>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  if (onTextFieldChange) {
                    onTextFieldChange(PRIMA_POSSIBILE_DATE);
                  }
                  if (onDateChangeExpanded) {
                    onDateChangeExpanded(PRIMA_POSSIBILE_DATE);
                  }
                  onClose();
                }}
              >
                {'Mostra la prima disponibilità'}
              </Button>
              {/* <Divider />
              <DatePickerInput
                defaultValue={selected}
                passFormattedDate={onTextFieldChange}
                onDatePickerClose={onClose}
              /> */}
              <Divider />
              <Typography className="Call">
                {'oppure seleziona una data'}
              </Typography>
            </>
          )}
          <DatePicker
            locale={'it'}
            open={isOpen}
            startOpen
            minDate={
              type && type === 'birthday'
                ? subYears(new Date(), 100)
                : getMinDate(startOfToday())
            }
            maxDate={type && type === 'birthday' ? new Date() : undefined}
            fixedHeight
            autoFocus={false}
            selected={value}
            onChange={setValue}
            onSelect={(date: any) => {
              const result = `${format(date, 'EEEE', { locale: it })} ${format(
                date,
                'dd/MM/yy',
                { locale: it },
              )}`;
              const birthday = `${format(date, 'dd/MM/yyyy', { locale: it })}`;
              if (onTextFieldChange) {
                if (type === 'birthday' && !props.guest) {
                  onTextFieldChange('birthDate', birthday);
                } else {
                  onTextFieldChange(result);
                }
              }
              if (onDateChangeExpanded) {
                onDateChangeExpanded(date);
              }
              onClose();
            }}
            onClickOutside={() => setIsOpen(true)}
            shouldCloseOnSelect={false}
            showYearDropdown={type === 'birthday'}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              changeYear,
            }: any) => {
              const month = format(date, 'LLLL', { locale: it });
              return (
                <Box>
                  {type === 'birthday' && (
                    <>
                      <DatePickerInput
                        type={EDatePickerType.Birthdate}
                        defaultValue={selected}
                        passFormattedDate={onTextFieldChange}
                        onDatePickerClose={onClose}
                      />
                      <Divider />
                    </>
                  )}
                  {type === 'birthday' && (
                    <Select
                      value={getYear(date)}
                      onSelectChange={(e: any) => {
                        changeYear(e.target.value);
                      }}
                      options={years}
                    />
                  )}
                  <Box
                    sx={{
                      ...(isDesktop
                        ? { marginBottom: '10px', marginTop: '10px' }
                        : { marginBottom: '26px', marginTop: '26px' }),
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      className="ArrowIcon"
                      onClick={() => decreaseMonth()}
                    >
                      <PrevArrow />
                    </IconButton>
                    <Typography className="Month">{month}</Typography>
                    <IconButton
                      className="ArrowIcon"
                      onClick={() => increaseMonth()}
                    >
                      <NextArrow />
                    </IconButton>
                  </Box>
                </Box>
              );
            }}
            dayClassName={date =>
              classNames({
                weekend: birthdayPicker
                  ? false
                  : availableDates
                  ? !availableDates.includes(dateUtils.getYYMMDD(date) ?? '') ||
                    isWeekend(date, publicHolidays)
                  : isWeekend(date, publicHolidays),
              })
            }
          />
        </Box>
      </Wrapper>
    </ThemeProvider>
  );
};

export default Picker;
