import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import classNames from 'classnames';
import { parse, format } from 'date-fns';
import { it } from 'date-fns/locale';
import React from 'react';

import Feature from './Feature';
import { CentersItemMediaWrapper } from '../pages/StaticPages/MedicalServiceInCity/Centers/Centers.styled';
import { ArrowRight, Lightning } from '../icons-components/Icons';
import { useAppDispatch } from '../redux/hooks';
import { updateAppointment } from '../redux/appointment/appointmentSlice';
import { ApiTypes } from '../types/models';
import { stopEventPropagation } from '../utils/stopEventPropagation';

interface ISlot {
  date: string;
  time: string;
  bookingInfo?: object;
}

interface ICardProps {
  id: string;
  day?: number;
  soonest?: boolean;
  slot: ISlot;
  destination: string;
  address: string;
  distance: string;
  price: number;
  styles?: object;
  availability: any;
  onModalOpen?: () => void;
  setDate?: (date: Date) => void;
  setCenterID?: (ID: string) => void;
  logo?: string | undefined;
  setSoonestDate?: (soonestDate: string) => void;
  redirect: boolean;
  apiType?: ApiTypes;
  onSlotClickWithMapViewActive?: () => void;
  isMapViewActive?: boolean;
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.Destination': {
              paddingTop: '3px',
              fontSize: '14px',
              fontWeight: 800,
              lineHeight: 1.2,
              color: '#212121',
              [theme.breakpoints.up('lg')]: {
                paddingTop: 0,
                fontSize: '16px',
                lineHeight: 1.5,
                letterSpacing: '0.2px',
              },
            },
            '&.Address': {
              fontSize: '12px',
              lineHeight: 1.5,
              color: '#607D8B',
              letterSpacing: '0.4px',
              textTransform: 'capitalize',

              maxHeight: '36px', // with lineHeight 18px 2 lines max
              overflow: 'hidden',

              [theme.breakpoints.up('lg')]: {
                fontSize: '14px',
                lineHeight: 1.4,
                letterSpacing: '0.3px',

                maxHeight: '39.2', // with lineHeight 19,6px 2 lines max
              },
            },
            '&.Distance': {
              marginLeft: 'auto',
              fontSize: '14px',
              lineHeight: 1.4,
              color: '#607D8B',
              letterSpacing: '0.3px',
            },
            '&.Date': {
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#263238',
              textTransform: 'capitalize',
            },
            '&.Price': {
              paddingLeft: '9px',
              fontWeight: 800,
              fontSize: '16px',
              lineHeight: 1.5,
              letterSpacing: '0.2px',
              color: '#263238',
            },
            '&.Availabilities': {
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#FFFFFF',
              textAlign: 'center',
            },
            '&.Frame': {
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#FFFFFF',
              textAlign: 'center',
              textTransform: 'capitalize',
            },
            '&.Empty': {
              color: '#90A4AE',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.action': {
              fontFamily: 'inherit',
              fontWeight: 800,
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#00838F',
              textTransform: 'none',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            display: 'flex',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
            '&:not(:last-of-type)': {
              marginRight: '8px',
            },
          },
        },
      },
    },
  });
};

// @ts-ignore
const Logo = styled(Box)(({ theme, src }) => ({
  width: '36px',
  height: '36px',
  flexShrink: 0,
  backgroundImage: `url(${src ? src : ''})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  // backgroundColor in case there is no media
  backgroundColor: '#FFFFFF',
  borderRadius: '12px',
  [theme.breakpoints.up('lg')]: {
    width: '40px',
    height: '40px',
  },
}));

const Availability = styled(Box)({
  width: '96px',
  padding: '6px',
  background: '#00838F',
  backdropFilter: 'blur(2px)',
  border: '1px solid #B0BEC5',
  borderRadius: '6px',
  cursor: 'pointer',
  '&.redirect': {
    background: '#B2EBF2',
    borderColor: '#B2EBF2',
  },
  '&.unavailable': {
    background: '#F4F8FB',
    borderColor: '#B0BEC5',
    cursor: 'auto',
  },
});

const Soonest = styled(Box)(({ theme }) => ({
  // margin: '0 auto 10px auto',
  margin: '0 auto',
  width: '205px',
  height: '46px',
  flexShrink: 0,
  padding: '2px',
  textAlign: 'center',
  background: '#00838F',
  border: '1px solid #FFFFFF',
  borderRadius: '6px',
  cursor: 'pointer',
  '&.redirect': {
    background: '#B2EBF2',
    borderColor: '#B2EBF2',
  },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('lg')]: {
    margin: 0,
    width: '300px',
    height: 'auto',
    padding: '8px',
    alignSelf: 'start',
  },
}));

const Available = styled(Typography)({
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#FFFFFF',
  textAlign: 'center',
  '&.redirect': {
    color: '#00838F',
  },
  '&.unavailable': {
    color: '#90A4AE',
  },
  '&.redirect.soonest': {
    fontWeight: 600,
  },
});

const Frame = styled(Available)({
  fontWeight: 600,
  fontSize: '12px',
  '&.soonest': {
    textTransform: 'capitalize',
  },
  '&.redirect.soonest': {
    fontWeight: 800,
  },
});

const MetaArea = styled(Box)({
  // marginLeft: 'auto',
  // textAlign: 'right',
  position: 'absolute',
  right: '12px',
});

const Card = (props: ICardProps): JSX.Element => {
  const {
    id,
    slot,
    destination,
    address,
    distance,
    price,
    styles,
    availability,
    onModalOpen,
    setDate,
    soonest,
    setCenterID,
    logo,
    setSoonestDate,
    redirect,
    apiType,
    onSlotClickWithMapViewActive,
    isMapViewActive,
  } = props;
  const shouldUIBeCut = false;

  const { date } = slot;

  const parsed = parse(date, 'yyyy-MM-dd', new Date());
  const month = format(parsed, 'LLLL');
  const dayOfWeek = format(parsed, 'EEEE');
  const dayOfMonth = format(parsed, 'dd');

  const query = `${format(parsed, 'EEEE', {
    locale: it,
  })} ${format(parsed, 'dd/MM/yy')}`;

  const year = format(parsed, 'yyyy');

  const formatted = `${dayOfWeek} ${dayOfMonth}, ${month}`;
  const formattedForDesktop = `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;

  const dispatch = useAppDispatch();

  const onCardClick = (
    ID: string,
    destination: string,
    address: string,
    price: number,
  ) => {
    const payload = {
      centerID: ID,
      center: destination,
      address,
      price: `${price.toFixed(2)}€`,
    };
    dispatch(updateAppointment(payload));

    if (soonest) {
      if (setSoonestDate) {
        setSoonestDate(query);
      }

      if (setDate) {
        setDate(parsed);
      }
    }

    if (onModalOpen) {
      onModalOpen();
    }
  };

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const automatic = apiType === ApiTypes.AUTOMATIC;

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Box
        sx={{
          p: isDesktop ? '16px' : '12px',
          pr: isDesktop ? '26px' : '12px',
          position: 'relative',
          background: '#F4F8FB;',
          borderRadius: '20px',
          ...styles,
          ...(isDesktop
            ? {
                minHeight: shouldUIBeCut ? '110px' : '130px',
                position: 'relative',
              }
            : {}),
          cursor: isMapViewActive ? 'pointer' : 'auto',
        }}
        onClick={() => {
          onSlotClickWithMapViewActive?.();
        }}
      >
        <Box
          sx={{
            display: isDesktop ? 'flex' : 'block',
            ...(isDesktop ? { justifyContent: 'space-between' } : {}),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              marginBottom: isDesktop ? '' : '10px',
              marginRight: isDesktop ? '15px' : '',
            }}
          >
            <CentersItemMediaWrapper className={'with-mr'}>
              <Feature on={!shouldUIBeCut}>
                {/* @ts-ignore */}
                <Logo src={logo} />
              </Feature>
              {automatic && !shouldUIBeCut && <Lightning />}
            </CentersItemMediaWrapper>
            <Box sx={{ width: { xs: '100%' } }}>
              <Typography
                sx={{ maxWidth: { xs: '75%', lg: '100%' } }}
                className="Destination"
              >
                {destination}
              </Typography>
              <Typography
                sx={
                  !isDesktop && !soonest && automatic
                    ? { minHeight: '36px' }
                    : {}
                }
                className="Address"
              >
                {address}
              </Typography>
              {isDesktop && (
                <>
                  <Typography className="Distance">{distance}</Typography>
                  {/* {automatic && <Lightning />} */}
                </>
              )}
            </Box>
            {!isDesktop && (
              <MetaArea>
                <Typography className="Distance">{distance}</Typography>
                {/* {automatic && <Lightning />} */}
              </MetaArea>
            )}
          </Box>
          {!soonest && !shouldUIBeCut && (
            <Stack
              direction="row"
              spacing="8px"
              sx={{
                justifyContent: 'center',
                alignItems: 'start',
                marginLeft: { sx: '9px' },
              }}
            >
              {['Mattina', 'Pomeriggio', 'Sera'].map((frame, idx) => {
                const availabilities: any = availability
                  ? Object.values(availability)
                  : [];
                const quantity: any = availabilities[idx];
                const unavailable = quantity === 0;

                return (
                  <Availability
                    key={idx}
                    className={classNames({ redirect, unavailable })}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      stopEventPropagation(e);

                      if (unavailable) return;

                      onCardClick(id, destination, address, price);
                      if (setCenterID) {
                        setCenterID(id);
                      }
                    }}
                  >
                    <Available
                      className={classNames({ redirect, unavailable })}
                    >
                      {unavailable ? 'Non disponibile' : 'Disponibilità'}
                    </Available>
                    <Frame className={classNames({ redirect, unavailable })}>
                      {frame}
                    </Frame>
                  </Availability>
                );
              })}
            </Stack>
          )}
          {(soonest || shouldUIBeCut) && (
            <Soonest
              className={classNames({ redirect })}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                stopEventPropagation(e);
                onCardClick(id, destination, address, price);
                if (setCenterID) {
                  setCenterID(id);
                }
              }}
            >
              <Box>
                <Available className={classNames('soonest', { redirect })}>
                  {redirect ? 'Disponibilità' : 'Prima disponibilità'}
                </Available>
                <Frame className={classNames('soonest', { redirect })}>
                  {isDesktop ? formattedForDesktop : formatted}
                </Frame>
              </Box>
            </Soonest>
          )}
        </Box>
        <Box
          sx={{
            marginTop: !isDesktop ? '6px' : '',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              ml: 'auto',
              ...(isDesktop
                ? { position: 'absolute', right: '22px', bottom: '10px' }
                : {}),
            }}
            className={classNames('action')}
            endIcon={<ArrowRight />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              stopEventPropagation(e);
              onCardClick(id, destination, address, price);
              if (setCenterID) {
                setCenterID(id);
              }
            }}
          >
            {soonest ? 'Vedi orari' : 'Vedi disponibilità'}
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Card;
