import { createTheme } from '@mui/material/styles';

const fontFamily = "'Manrope', sans-serif";

const reset = createTheme({
  typography: {
    fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            boxSizing: 'borderBox',
          },
          '*, *::before, *::after,': {
            boxSizing: 'inherit',
          },
          body: {
            margin: 0,
          },
          img: {
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#263238',
        },
        h1: {
          fontWeight: 800,
          fontSize: '24px',
          lineHeight: 1.3,
        },
        h2: {},
        h3: {
          fontWeight: 800,
          fontSize: '13px',
          lineHeight: '20px',
          letterSpacing: '1px',
          textTransform: 'uppercase',
          color: '#304659',
        },
        subtitle1: {
          marginBottom: '6px',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: 1.4,
          letterSpacing: '0.3px',
        },
        subtitle2: {
          fontWeight: 800,
          fontSize: '14px',
          lineHeight: 1.2,
        },
        body1: {
          fontSize: '14px',
          lineHeight: 1.4,
          letterSpacing: '0.3px',
        },
        caption: {
          display: 'block',
          fontSize: '16px',
          lineHeight: 1.5,
          letterSpacing: '0.3px',
          color: '#607D8B',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#B0BEC5',
        },
      },
    },
  },
});

export default reset;
