import { ClickAwayListener, useMediaQuery } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';

import paths from '../routes/paths';
import {
  Tooltip,
  TooltipAddress,
  TooltipLink,
  TooltipName,
} from './Map.styled';

// Center coords as a prop

interface IMapProps {
  testingCenters: any[];
  center: { lat: number; lng: number };
  mapContainerStyle?: any;
  onMarkerClick?: React.Dispatch<React.SetStateAction<number>>;
  isFinished?: boolean;
  zoom?: number;
  withTooltip?: boolean;
  idx?: number;
  isMedicalServiceInCity?: boolean;
}

const options = {
  disableDefaultUI: true,
  clickableIcons: false,
  fullScreenControl: false,
  disableAutoPan: true,
  disableDoubleClickZoom: true,
  styles: [{ elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }],
};

interface ITooltip {
  position: { top: number; left: number };
  chainLabel: string;
  name: string;
  address: string;
  label: string;
}

const TOOLTIP_WIDTH = 378;
const Map = (props: IMapProps) => {
  const {
    testingCenters,
    center,
    onMarkerClick,
    mapContainerStyle = {
      width: '100vw',
      height: '100vh',
      maxHeight: '375px',
    },
    isFinished,
    zoom,
    withTooltip,
    idx,
    isMedicalServiceInCity,
  } = props;
  const map = useRef<undefined | google.maps.Map>(undefined);
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const isMobile = useMediaQuery('(max-width: 600px)');

  const getNewBounds = useCallback(() => {
    const bounds = new google.maps.LatLngBounds();

    testingCenters.forEach((data: any) => {
      const nested = !data.hasOwnProperty('coordinates');

      const location = new window.google.maps.LatLng(
        nested ? data.center.coordinates[0] : data.coordinates[0],
        nested ? data.center.coordinates[1] : data.coordinates[1],
      );
      bounds.extend(location);
    });
    if (!map.current) {
      return;
    }
    map.current.fitBounds(bounds);
  }, [testingCenters]);

  useEffect(() => {
    getNewBounds();
  }, [isFinished]);

  const onLoad = useCallback(
    (inst: google.maps.Map) => {
      const bounds = new google.maps.LatLngBounds();

      const nested = !testingCenters[0].hasOwnProperty('coordinates');

      testingCenters.forEach(data => {
        const nested = !data.hasOwnProperty('coordinates');

        const location = new window.google.maps.LatLng(
          nested ? data.center.coordinates[0] : data.coordinates[0],
          nested ? data.center.coordinates[1] : data.coordinates[1],
        );
        bounds.extend(location);
      });
      if (testingCenters.length > 1) {
        inst.fitBounds(bounds);
      } else {
        inst.setZoom(zoom || 15);
        inst.setCenter({
          lat: nested
            ? testingCenters[0].center.coordinates[0]
            : testingCenters[0].coordinates[0],
          lng: nested
            ? testingCenters[1].center.coordinates[0]
            : testingCenters[0].coordinates[1],
        });
      }
      map.current = inst;
    },
    [testingCenters, zoom],
  );
  const onUnmount = useCallback(() => (map.current = undefined), []);

  const [position] = useState<{ lat: number; lng: number }>(center);
  const [tooltip, setTooltip] = useState<null | ITooltip>(null);

  function openTooltip(
    e: google.maps.MapMouseEvent,
    center: Omit<ITooltip, 'position'>,
  ) {
    if (!withTooltip) return;
    document.body.style.overflow = 'hidden';
    // @ts-ignore
    let { x, y } = e.domEvent;
    // @ts-ignore
    if (window.innerWidth - e.domEvent.clientX < TOOLTIP_WIDTH) {
      x = x - TOOLTIP_WIDTH;
    }
    setTooltip({
      position: { top: y, left: x },
      chainLabel: center.chainLabel,
      name: center.name,
      address: center.address,
      label: center.label,
    });
  }
  function closeTooltip() {
    document.body.style.overflow = 'visible';
    setTooltip(null);
  }

  return (
    <>
      <GoogleMap
        zoom={zoom}
        center={position}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerClassName="googleMapContainer"
        mapContainerStyle={{
          ...(isMedicalServiceInCity && isMobile
            ? {
                marginLeft: '-24px',
              }
            : {}),
          width: '100vw',
          maxWidth: !isDesktop
            ? '100%'
            : isMedicalServiceInCity
            ? '845px'
            : '740px',
          height: '100vh',
          maxHeight: isDesktop ? '470px' : '375px',
          ...mapContainerStyle,
        }}
        options={options}
      >
        {testingCenters.map((center: any, index: number) => {
          const nested = !center.hasOwnProperty('coordinates');

          return (
            <Marker
              key={index}
              position={{
                lat: nested
                  ? center.center.coordinates[0]
                  : center.coordinates[0],
                lng: nested
                  ? center.center.coordinates[1]
                  : center.coordinates[1],
              }}
              icon={
                index === idx
                  ? { url: '/svgs/marker-focused.svg' }
                  : { url: '/svgs/marker.svg' }
              }
              onClick={e => {
                openTooltip(e, center);
                onMarkerClick?.(index);
              }}
              zIndex={index === idx ? 2 : 1}
            />
          );
        })}
      </GoogleMap>
      {withTooltip && !!tooltip && (
        <ClickAwayListener onClickAway={closeTooltip}>
          <Tooltip
            style={{
              position: 'fixed',
              top: tooltip.position.top,
              left: tooltip.position.left,
            }}
          >
            <TooltipName>{tooltip.name}</TooltipName>
            <TooltipAddress>
              {'Indirizzo'}: {tooltip.address}
            </TooltipAddress>
            <TooltipLink
              href={generatePath(paths().testingCenter, {
                testingChain: tooltip.chainLabel,
                testingCenter: tooltip.label,
              })}
            >
              {'Vedi dettagli centro'} {'>'}
            </TooltipLink>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Map;
