import { IBooking, IUserGql } from '../../types';

export enum ViewsTypes {
  Info,
  Bookings,
}

export interface IPatient {
  __typename: string;
  id: string;
  firstName: string;
  lastName: string;
  fiscalCode: string;
  email: string;
  phone: string;
  birthDate: string;
  birthPlace: string;
  gender: string;
  nationality: string;
  creationDate: string;
  user: null | IUserGql;
}

export interface FindPatientByIdResult {
  findPatientById: IPatient;
}

export interface FindPatientBookingsResult {
  findPatientBookings: IBooking[];
}
