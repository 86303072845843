import React from 'react';

export enum ECriteria {
  ChainType = 'CHAIN_TYPE',
}

export interface IProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  criteria?: ECriteria;
}
