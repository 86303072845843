import React, { useState } from 'react';
import {
  Modal,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { IMaskMixin, IMask } from 'react-imask';
import classNames from 'classnames';
import {
  useTests,
  IFindTestsQueryInstance,
  useCenters,
  IFindCentersQueryInstance,
  getParsedBookingDate,
  getParsedBookingTime,
  getFormattedBookingDate,
  getFormattedBookingTime,
  useBookingUpdate,
} from './BookingDetailsUpdate.helpers';
import { IProps, EBookingDateControls } from './BookingDetailsUpdate.types';
import {
  Control,
  ModalContent,
  ModalTitle,
  ModalCloseControl,
  BookingDetailsAutocomplete,
  ClearInputValueButton,
  BookingDetailsOptionsWrapper,
  BookingDetailsOptionsPopper,
  BookingDateControl,
  BookingDetailsOptionButton,
  BookingDetailsOptionText,
  BookingDetailsOptionIcon,
  BookingDetailsOptionsDivider,
} from '../BookingDetails.styled';
import notify from 'utils/notify';
import { ReactComponent as Pulse } from 'icons/adornments/pulse.svg';
import { ReactComponent as Marker } from 'icons/adornments/marker.svg';
import { ReactComponent as Pointer } from 'icons/stack-pointer.svg';

const MaskedBookingDateInput = IMaskMixin<
  IMask.AnyMaskedOptions,
  false,
  string,
  HTMLInputElement,
  { control: EBookingDateControls; clearValue: () => void }
>(({ inputRef, ...props }) => {
  return (
    <BookingDateControl
      {...props}
      inputRef={inputRef}
      className={classNames('without-autocomplete', {
        'mb-36': props.control === EBookingDateControls.Date,
      })}
    />
  );
});

const BookingDetailsUpdate: React.FC<IProps> = ({
  bookingID,
  presetTest,
  presetCenter,
  presetDate,
  presetTime,
  refetch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const [test, setTest] = useState<IFindTestsQueryInstance>(() => presetTest);
  const { loadTests, testsOptionsLoading, tests } = useTests();

  const [center, setCenter] = useState<IFindCentersQueryInstance>(
    () => presetCenter,
  );
  const { centers } = useCenters();

  const [date, setDate] = useState(() => getParsedBookingDate(presetDate));
  const [time, setTime] = useState(() => getParsedBookingTime(presetTime));

  const { updateBooking, isBookingUpdating, resendBookingConfirmation } =
    useBookingUpdate();

  const handleApplyChanges = () => {
    const formattedDate = getFormattedBookingDate(
      date,
      time.length ? time : presetTime,
    );
    const formattedTime = getFormattedBookingTime(time);
    const variables = {
      id: bookingID,
      testId: test.id,
      centerId: center.id,
      ...(!!formattedDate ? { reservationDate: formattedDate } : {}),
      ...(!!formattedTime ? { time: formattedTime } : {}),
    };
    updateBooking({
      variables,
      onCompleted: () => {
        handleModalClose();
        refetch({ variables: { id: bookingID } });
        resendBookingConfirmation({
          variables: { id: bookingID, edited: true },
        });
      },
      onError: () => {
        notify('Failed to update reservation details', 'error');
      },
    });
  };

  return (
    <>
      <Control onClick={handleModalOpen}>Modifica prenotazione</Control>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <ModalContent>
          <ModalCloseControl onClick={handleModalClose} />
          <ModalTitle>Modifica prenotazione</ModalTitle>
          <BookingDetailsAutocomplete
            fullWidth
            options={tests}
            getOptionLabel={option => option.name}
            filterOptions={x => x}
            value={test}
            onChange={(
              e: React.SyntheticEvent,
              newValue: IFindTestsQueryInstance | null,
            ) => {
              if (!newValue) {
                return;
              }
              setTest(newValue);
            }}
            onInputChange={(e: React.SyntheticEvent, searchValue: string) => {
              loadTests({ variables: { search: searchValue } });
            }}
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Pulse />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            clearIcon={<ClearInputValueButton disableRipple />}
            PaperComponent={BookingDetailsOptionsWrapper}
            PopperComponent={BookingDetailsOptionsPopper}
            renderOption={(props, option) => {
              return (
                <li key={option.id}>
                  {/* @ts-ignore */}
                  <BookingDetailsOptionButton {...props}>
                    <BookingDetailsOptionText>
                      {option.name}
                    </BookingDetailsOptionText>
                    <BookingDetailsOptionIcon>
                      <Pointer />
                    </BookingDetailsOptionIcon>
                  </BookingDetailsOptionButton>
                  <BookingDetailsOptionsDivider />
                </li>
              );
            }}
            noOptionsText={
              <BookingDetailsOptionText>
                {testsOptionsLoading ? 'Loading' : 'No options'}
              </BookingDetailsOptionText>
            }
          />
          <BookingDetailsAutocomplete
            fullWidth
            options={centers}
            getOptionLabel={option => option.name}
            value={center}
            onChange={(
              e: React.SyntheticEvent,
              newValue: IFindCentersQueryInstance | null,
            ) => {
              if (!newValue) {
                return;
              }
              setCenter(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Marker />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            clearIcon={<ClearInputValueButton disableRipple />}
            PaperComponent={BookingDetailsOptionsWrapper}
            PopperComponent={BookingDetailsOptionsPopper}
            renderOption={(props, option) => {
              return (
                <li key={option.id}>
                  {/* @ts-ignore */}
                  <BookingDetailsOptionButton {...props}>
                    <BookingDetailsOptionText>
                      {option.name}
                    </BookingDetailsOptionText>
                    <BookingDetailsOptionIcon>
                      <Pointer />
                    </BookingDetailsOptionIcon>
                  </BookingDetailsOptionButton>
                  <BookingDetailsOptionsDivider />
                </li>
              );
            }}
            noOptionsText={
              <BookingDetailsOptionText>No options</BookingDetailsOptionText>
            }
          />
          <MaskedBookingDateInput
            mask={'##/##/####'}
            definitions={{ '#': /\d/ }}
            value={date}
            onAccept={value => {
              setDate(value);
            }}
            control={EBookingDateControls.Date}
            clearValue={() => {
              setDate('');
            }}
          />

          <MaskedBookingDateInput
            mask={'##:##-##:##'}
            definitions={{ '#': /\d/ }}
            value={time}
            onAccept={value => {
              setTime(value);
            }}
            control={EBookingDateControls.Time}
            clearValue={() => {
              setTime('');
            }}
          />

          <Control className={'save-changes-btn'} onClick={handleApplyChanges}>
            {isBookingUpdating ? (
              <CircularProgress color={'inherit'} size={25} />
            ) : (
              'Salva'
            )}
          </Control>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BookingDetailsUpdate;
