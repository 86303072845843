import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { PRIMA_POSSIBILE_DATE } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { ZendeskService } from '../../utils/zendeskService';
import {
  MuiBox,
  MuiTypography,
  MuiList,
  MuiListItem,
  MuiButton,
} from './Unavailable.styled';

const CentersUnavailable = ({
  searchSoonest,
}: {
  searchSoonest?: () => void;
}): JSX.Element => {
  const zendesk = new ZendeskService();
  const [, date] = useAppSelector(state => state.query.date).split(' ');
  const isSoonestDate = PRIMA_POSSIBILE_DATE.includes(date);

  const [isChatEnabled, setIsChatEnabled] = useState(false);

  useEffect(() => {
    setIsChatEnabled(
      Boolean(document.querySelector('[data-product="web_widget"]')),
    );
  }, [setIsChatEnabled]);

  function openChat() {
    if (!isChatEnabled) {
      return window.open(
        'https://onecheckhelp.zendesk.com/hc/it/articles/6889234859165-Come-contattare-OneCheck-',
        '_blank',
      );
    }
    zendesk.openChat();
  }

  return (
    <MuiBox>
      <MuiTypography className={classNames('no-centers')}>
        {'Sembrano non esserci disponibilità per la prestazione cercata.'}{' '}
        {date}
      </MuiTypography>
      <MuiList>
        {isSoonestDate ? null : (
          <MuiListItem
            className={classNames({ 'not-soonest': !isSoonestDate })}
          >
            <MuiButton onClick={searchSoonest}>
              {'Cerca per prima disponibilità'}
            </MuiButton>
          </MuiListItem>
        )}
        <MuiListItem>
          <MuiButton className={classNames('full-width')} onClick={openChat}>
            {'Contattaci via chat'}
          </MuiButton>
        </MuiListItem>
      </MuiList>
    </MuiBox>
  );
};

export default CentersUnavailable;
