import { OperationVariables, ApolloQueryResult } from '@apollo/client';
import {
  IFindTestsQueryInstance,
  IFindCentersQueryInstance,
} from './BookingDetailsUpdate.helpers';
import { FindBookingByIdResult } from '../BookingDetails.types';

export interface IProps {
  bookingID: string;
  presetTest: IFindTestsQueryInstance;
  presetCenter: IFindCentersQueryInstance;
  presetDate: string;
  presetTime: string;
  refetch: (
    variables?: Partial<OperationVariables | undefined>,
  ) => Promise<ApolloQueryResult<FindBookingByIdResult>>;
}

export enum EBookingDateControls {
  Date = 'Date',
  Time = 'Time',
}
