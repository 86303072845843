import { useQuery } from '@apollo/client';
import { Container, styled } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Registry as Entries } from '../../components/DataGrid';
import Styled from '../../components/Styled';
import { FIND_PATIENTS } from './Registry.query';
import { FindPatientsResult } from './Registry.types';

const Wrapper = styled(Container)(({ theme }) => ({
  width: '100%',
  padding: '34px',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 'unset',
  },
}));

const Registry = () => {
  const { loading, data } = useQuery<FindPatientsResult>(FIND_PATIENTS);

  return (
    <Wrapper>
      <Helmet>
        <title>Anagrafica pazienti</title>
      </Helmet>
      <Styled.Title>Anagrafica pazienti</Styled.Title>
      <Entries patients={data?.findPatients} loading={loading} />
    </Wrapper>
  );
};

export default Registry;
