import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  onecheck: {
    isOn: false,
  },
  timeframe: {
    morning: false,
    afternoon: false,
    evening: false,
  },
  // weekdays: {
  //   mon: false,
  //   tue: false,
  //   wed: false,
  //   thu: false,
  //   fri: false,
  // },
  centers: {},
  staff: {},
  chainsNamesWithAutomaticApiType: [],
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action) => (state = action.payload),
    setCenters: (state, action) => {
      state.centers = action.payload;
    },
    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    setChainsNamesWithAutomaticApiType: (state, action) => {
      state.chainsNamesWithAutomaticApiType = action.payload;
    },
  },
});

export const {
  setFilter,
  setCenters,
  setStaff,
  setChainsNamesWithAutomaticApiType,
} = slice.actions;

export default slice.reducer;
