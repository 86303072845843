import styled from '@emotion/styled';

export const DetailSkeletonsBox = styled('div', {
  label: 'DetailSkeletonsBox',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 30px;
  width: 100%;
`;
