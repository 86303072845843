import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { ChangeEvent, useState } from 'react';

import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';

interface ISelectProps {
  // value: string | number;
  value: any;
  onSelectChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // options: (string | number)[];
  options: any;
  styles?: object;
  className?: string;
  isDisabled?: boolean;
  label?: string;
  isInvalid?: any;
  error?: any;
  choosingPatient?: boolean;
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
            '&.Invalid > .MuiOutlinedInput-root': {
              borderColor: '#D81B60',
            },
            '&.Invalid > .MuiFormHelperText-root': {
              color: '#D81B60',
            },
            '&.Invalid > .MuiInputLabel-root': {
              color: '#D81B60',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: '1px solid #B0BEC5',
            borderRadius: '16px',
            transition: 'all 250ms linear',

            '&.Mui-focused': {
              border: '1px solid #263238',
            },
            '&.Mui-disabled': {
              background: '#F4F8FB',
            },
          },
          input: {
            padding: '16.5px 16px 16.5px 20px',
            fontFamily: 'Manrope',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            color: '#263238',

            '&.Mui-disabled': {
              color: '#B0BEC5',
            },
          },
          notchedOutline: {
            border: 'none',
            '& > legend': {
              height: 'auto',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            right: '15px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: 1.5,
            letterSpacing: '0.2px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            top: '168px',
            border: '1px solid #263238',
            borderTop: 'none',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            boxShadow: 'none',
            maxHeight: '200px !important',
            [theme.breakpoints.up('lg')]: {
              border: 'none',
              left: '191px',
              outline: '1px solid #263238',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            top: 'auto',
            bottom: 'calc(100% + 6px)',
            transform: 'none',
            transformOrigin: 'unset',
            fontWeight: 800,
            fontSize: '14px',
            lineHeight: 1.2,
            color: '#607D8B',
            '&.Mui-focused, &.Mui-disabled': {
              color: '#607D8B',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "'Manrope', sans-serif",
            color: '#607D8B',
            '&.Mui-disabled': {
              color: '#607D8B',
            },
          },
        },
      },
    },
  });
};

const Select = (props: ISelectProps): JSX.Element => {
  const {
    styles,
    className,
    value,
    onSelectChange,
    choosingPatient,
    options,
    isDisabled,
    label,
  } = props;

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const borderStyles = isSelectOpen
    ? { borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }
    : { borderRadius: '16px' };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <TextField
        sx={styles}
        select
        SelectProps={{
          IconComponent: ChevronDown,
          onOpen: () => setIsSelectOpen(true),
          onClose: () => setIsSelectOpen(false),
          MenuProps: { disablePortal: true },
        }}
        value={value}
        InputProps={{ sx: borderStyles }}
        disabled={isDisabled}
        label={label}
        className={className}
        onChange={onSelectChange}
        helperText={props.error || ' '}
      >
        {choosingPatient
          ? options.map((option: any) => (
              // TODO: pass a placeholder for Select instead
              <MenuItem
                key={option.id}
                value={option.id}
                disabled={option === 'Seleziona'}
              >
                {option.option}
              </MenuItem>
            ))
          : options.map((option: any) => (
              // TODO: pass a placeholder for Select instead
              <MenuItem
                key={option}
                value={option}
                disabled={option === 'Seleziona'}
              >
                {option}
              </MenuItem>
            ))}
      </TextField>
    </ThemeProvider>
  );
};

export default Select;
