import IconButton from '@mui/material/IconButton';
import { ReactComponent as Arrow } from 'icons/go-to.svg';
import { Props, Embedding } from './Go.types';

const Go = ({ onClick, Icon }: Props) => {
  const Embedding: Embedding = Icon ? Icon : Arrow;

  return (
    <IconButton sx={{ p: '8px 5px' }} onClick={onClick}>
      {<Embedding />}
    </IconButton>
  );
};

export default Go;
