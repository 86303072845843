import styled from '@emotion/styled';

export const InfoBox = styled('div', { label: 'InfoBox' })`
  width: 100%;
  margin-left: 27px;
  padding: 9px 18px;
  display: flex;
  align-items: center;
  border: 1px solid #b0bec5;
  border-radius: 16px;
  overflow: hidden;
`;
export const InfoIconBox = styled('div', { label: 'InfoIconBox' })`
  width: 22px;
`;
export const InfoList = styled('div', { label: 'InfoList' })`
  margin-left: 12px;
  overflow: hidden;
`;
export const InfoListItem = styled('p', { label: 'InfoListItem' })<{
  isBold?: boolean;
}>`
  font-size: 12px;
  color: #263238;
  text-transform: capitalize;
  font-weight: ${({ isBold }) => (isBold ? '700' : '400')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 150%;
`;
