import gql from 'graphql-tag';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { RESEND_BOOKING_CONFIRMATION } from '../BookingDetails.query';
import notify from '../../../utils/notify';

export interface IFindCentersQueryInstance {
  id: string;
  name: string;
}
type TFindCentersQueryResponse = {
  findCenters: IFindCentersQueryInstance[];
};

export interface IFindTestsQueryInstance extends IFindCentersQueryInstance {}
export type TFindTestsQueryInstance = {
  findTests: IFindTestsQueryInstance[];
};

const FIND_CENTERS = gql`
  query FindCenters {
    findCenters {
      id
      name
    }
  }
`;

const FIND_TESTS = gql`
  query FindTests($search: String) {
    findTests(search: $search) {
      id
      name
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation UpdateBooking(
    $id: ID!
    $testId: String
    $centerId: String
    $reservationDate: String
    $time: String
  ) {
    updateBook(
      id: $id
      book: {
        testId: $testId
        centerId: $centerId
        reservationDate: $reservationDate
        time: $time
      }
    ) {
      id
    }
  }
`;

export const useCenters = () => {
  const { data } = useQuery<TFindCentersQueryResponse>(FIND_CENTERS);
  return { centers: data?.findCenters ?? [] };
};

export const useTests = () => {
  const [loadTests, { loading, data }] =
    useLazyQuery<TFindTestsQueryInstance>(FIND_TESTS);

  return {
    loadTests: _.debounce(loadTests, 500),
    testsOptionsLoading: loading,
    tests: data?.findTests ?? [],
  };
};

export const useBookingUpdate = () => {
  const [updateBooking, { loading }] = useMutation(UPDATE_BOOKING);
  const [resendBookingConfirmation] = useMutation(RESEND_BOOKING_CONFIRMATION, {
    onCompleted: () => {
      notify('Modifica della prenotazione inviata con successo', 'success');
    },
    onError: () => {
      notify('Failed to resend confirmation', 'error');
    },
  });

  return {
    updateBooking,
    isBookingUpdating: loading,
    resendBookingConfirmation,
  };
};

export const getParsedBookingDate = (reservationDate: string) =>
  reservationDate.split('T')[0].split('-').reverse().join('/');
export const getParsedBookingTime = (reservationTime: string) =>
  reservationTime.split(' - ').join('-');
export const getFormattedBookingDate = (date: string, time: string) =>
  date.length
    ? `${date.split('/').reverse().join('-')}T${time.split('-')[0]}:00.000Z`
    : '';
export const getFormattedBookingTime = (time: string) =>
  time.split('-').join(' - ');
