import validator from 'validator';
import { IFormState } from './Confirmation.types';

const requiredFields = [
  'firstName',
  'lastName',
  'birthDate',
  'birthPlace',
  'residenceAddress',
  'gender',
  'email',
  'phone',
];

const DEFAULT_GENDER_OPTION = 'Seleziona';

export const validate = (values: Partial<IFormState>) => {
  const errors: Record<string, string> = {};

  requiredFields.forEach((field: string) => {
    if (!values[field as keyof Partial<IFormState>]) {
      errors[field] = 'Required';
    }
  });

  if (values['email'] && !validator.isEmail(values['email'])) {
    errors['email'] = 'Invalid';
  }

  if (values['gender'] && values['gender'] === DEFAULT_GENDER_OPTION) {
    errors['gender'] = 'Required';
  }

  if (!values['fiscalCode'] && !values['noFiscalCode']) {
    errors['fiscalCode'] = 'Required';
  }

  if (values['fiscalCode'] && !values['noFiscalCode']) {
    const unmasked = values['fiscalCode'].replaceAll(' ', '');
    if (unmasked.length !== 16) {
      errors['fiscalCode'] = 'Invalid';
    }
  }
  return errors;
};
