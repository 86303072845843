import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

import { Cross, MenuArrow } from '../../icons-components/Icons';

const options = (
  soonest: boolean,
  withoutDistanceSortingCriterion?: boolean,
) => [
  { label: 'Prezzo dal più basso al più alto', value: 'priceAsc' },
  { label: 'Prezzo dal più alto al più basso', value: 'priceDesc' },
  ...(!withoutDistanceSortingCriterion
    ? [{ label: 'Distanza', value: 'distanceAsc' }]
    : []),
  { label: 'Rilevanza', value: 'default' },
  ...(soonest ? [{ label: 'Prima possibile', value: 'soonest' }] : []),
];

const getMuiTheme = () => {
  return createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            marginBottom: '16px',
            fontWeight: 800,
            fontSize: '18px',
            lineHeight: 1.4,
            letterSpacing: '0.2px',
            color: '#263238',
            textAlign: 'center',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '22px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            '&+.MuiDivider-root': {
              margin: '0px 24px',
            },
            '& .MuiListItemIcon-root': {
              minWidth: 'auto',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            '&.Bold > span': {
              fontWeight: 800,
            },
          },
          primary: {
            margin: 0,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            color: '#263238',
            textAlign: 'start',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
          },
        },
      },
    },
  });
};

const Sort = (props: {
  curr: string;
  onIconButtonClick: any;
  onMenuItemClick: any;
  soonest: boolean;
  withoutDistanceSortingCriterion?: boolean;
}): JSX.Element => {
  const {
    curr,
    onIconButtonClick,
    onMenuItemClick,
    soonest,
    withoutDistanceSortingCriterion,
  } = props;

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <IconButton
        sx={{ position: 'absolute', top: '16px', left: '16px' }}
        onClick={onIconButtonClick}
      >
        <Cross />
      </IconButton>
      <Typography>{'Ordina'}</Typography>
      <Stack divider={<Divider sx={{ ml: '24px', mr: '24px' }} />}>
        {options(soonest, withoutDistanceSortingCriterion).map(
          ({ label, value }: any, index: number) => {
            return (
              <MenuItem
                key={index}
                value={value}
                onClick={() => {
                  onMenuItemClick(value);
                  onIconButtonClick();
                }}
              >
                <ListItemText className={value === curr ? 'Bold' : ''}>
                  {label}
                </ListItemText>
                <ListItemIcon>
                  <MenuArrow />
                </ListItemIcon>
              </MenuItem>
            );
          },
        )}
      </Stack>
    </ThemeProvider>
  );
};

export default Sort;
