import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { BookingCards } from '../../../components/BookingCards/BookingCards.component';
import { FIND_PATIENT_BOOKINGS } from '../Patient.query';
import { FindPatientBookingsResult } from '../Patient.types';

export const PatientBookings = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, data } = useQuery<FindPatientBookingsResult>(
    FIND_PATIENT_BOOKINGS,
    {
      variables: { patient: id },
    },
  );
  const bookings = data?.findPatientBookings;

  console.log(bookings);

  return <BookingCards loading={loading} bookings={bookings} />;
};
