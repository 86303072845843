import React from 'react';

const Dots = () => {
  return (
    <div className="col-3">
      <div className="snippet">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </div>
  );
};

export default Dots;
