import styled from '@emotion/styled';
import { GoogleMap } from '@react-google-maps/api';

export const Tooltip = styled('h6', { label: 'Tooltip' })`
  max-width: 378px;
  width: 100%;
  background-color: #f4f8fb;
  padding: 20px 30px;
`;

export const TooltipName = styled('h6', { label: 'TooltipName' })`
  font-size: 16px;
  line-height: 150%;
  color: #3b5266;
`;

export const TooltipAddress = styled('p', { label: 'TooltipAddress' })`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #3b5266;
  margin: 4px 0 12px;
`;
export const TooltipLink = styled('a', { label: 'TooltipLink' })`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #00838f;
  cursor: pointer;
`;
