import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
  FilterFn,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { ReactComponent as Checked } from 'icons/checkboxes/checked.svg';
import { ReactComponent as Unchecked } from 'icons/checkboxes/not-checked.svg';
import { ReactComponent as Columns } from 'icons/table.svg';
import React, { useState, useEffect } from 'react';
import paths from 'routes/paths';

import { ColumnHeader } from '../ColumnHeader.component';
import {
  FixedTable,
  ColumnsVisible,
  ColumnsVisibleGroup,
  ColumnsVisibleList,
  ColumnVisibleControl,
  ColumnVisibleLabel,
  TableTransparentButton,
  TableBodyCell,
  TableBodyRow,
  TableHeadCell,
  TableSetting,
  TableSettings,
} from '../DataGrid.styled';
import { DataGridSkeletons } from '../DataGridSkeletons.component';
import { columns } from './Registry.data';
import { PatientID } from './Registry.styled';
import { IPatientInfo, IPatientTableRow, IProps } from './Registry.types';
import { sortByName } from '../DataGrid.helpers';

export const PatientInfo: React.FC<IPatientInfo> = ({
  id,
  firstName,
  lastName,
}) => {
  const name = `${firstName} ${lastName}`;
  return (
    <Box>
      <PatientID to={`/${paths({ id }).patient}`}>{name}</PatientID>
    </Box>
  );
};

const stringIncludes = (string: string, filterValue: string): boolean =>
  string.trim().toLowerCase().includes(filterValue.trim().toLowerCase());

const withPatient: FilterFn<any> = (row, columnId, filterValue) => {
  const { firstName, lastName } = row.getValue('patientInfo') as IPatientInfo;
  const patientName = `${firstName} ${lastName}`;
  return stringIncludes(patientName, filterValue);
};

const Registry = ({ patients, loading }: IProps): JSX.Element => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const showColumns = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const hideColumns = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const [defaultData, setDefaultData] = useState<IPatientTableRow[]>([]);
  const [defaultColumns] = useState<typeof columns>(() => [...columns]);

  useEffect(() => {
    if (!patients) return;
    setDefaultData(
      patients.map(p => ({
        patientInfo: { id: p.id, firstName: p.firstName, lastName: p.lastName },
        fiscalCode: p.fiscalCode,
        birthDate: p.birthDate,
        birthPlace: p.birthPlace,
        gender: p.gender,
      })),
    );
  }, [patients]);

  const tableInstance = useReactTable({
    data: defaultData,
    columns: defaultColumns,
    state: { sorting },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    sortingFns: { sortByName: sortByName('patientInfo') },
    filterFns: { withPatient },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  return (
    <>
      <TableSettings>
        <TableSetting>
          <ColumnsVisible onClick={showColumns}>
            <Columns />
          </ColumnsVisible>
        </TableSetting>
        <TableSetting>
          <TableTransparentButton
            onClick={() => tableInstance.resetColumnFilters(true)}
          >
            Rimuovi tutti i filtri
          </TableTransparentButton>
        </TableSetting>
      </TableSettings>
      <ColumnsVisibleList
        open={open}
        onClose={hideColumns}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <ColumnsVisibleGroup>
          {tableInstance.getAllColumns().map(column => {
            const columnHeader = column.columnDef.header;
            const columnLabel = columnHeader as React.ReactNode;
            return (
              <ColumnVisibleLabel
                key={column.id}
                label={columnLabel}
                control={
                  <ColumnVisibleControl
                    checked={column.getIsVisible()}
                    onChange={column.getToggleVisibilityHandler()}
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                    disableRipple
                  />
                }
              />
            );
          })}
        </ColumnsVisibleGroup>
      </ColumnsVisibleList>
      {loading ? (
        <DataGridSkeletons />
      ) : (
        <TableContainer sx={{ mt: '13px' }}>
          <FixedTable>
            <TableHead>
              {tableInstance.getHeaderGroups().map(headerGroup => {
                return (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      const columnHeading = header.column.columnDef
                        .header as string;

                      const filterPlaceholder =
                        header.id === 'patientTel'
                          ? 'Numero'
                          : header.id === 'apptQuantity'
                          ? 'Numero'
                          : header.id === 'latestAppt'
                          ? 'Data'
                          : columnHeading;

                      return (
                        <TableHeadCell key={header.id} className={header.id}>
                          <ColumnHeader
                            heading={columnHeading}
                            placeholder={filterPlaceholder}
                            columnInstance={header.column}
                          />
                        </TableHeadCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody>
              {tableInstance.getRowModel().rows.map(row => {
                return (
                  <TableBodyRow key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableBodyCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableBodyCell>
                      );
                    })}
                  </TableBodyRow>
                );
              })}
            </TableBody>
          </FixedTable>
        </TableContainer>
      )}
    </>
  );
};

export default Registry;
