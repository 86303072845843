import React from 'react';
import { Column, FilterFn, SortingFn } from '@tanstack/react-table';
import { addHours, format, parse } from 'date-fns';

import { IPatient } from '../../../pages/Patient/Patient.types';
import {
  BookingStatus,
  ICenter,
  ITableBooking,
  ITest,
  IUserGql,
  TTableBookings,
  IPayment,
  PaymentValues,
} from '../../../types';
import { DropdownFilter } from '../DropdownFilter.component';

export const stringIncludes = (string: string, filterValue: string): boolean =>
  string.trim().toLowerCase().includes(filterValue.trim().toLowerCase());

export const withCode: FilterFn<any> = (row, columnId, filterValue) => {
  const code = row.getValue('code') as number;
  return code.toString().includes(filterValue);
};

export const sortByCenter: SortingFn<ITableBooking> = (rowA, rowB) => {
  const { name: nameA } = rowA.getValue('center') as ICenter;
  const { name: nameB } = rowB.getValue('center') as ICenter;

  return nameA < nameB ? 1 : -1;
};

export const sortByTest: SortingFn<ITableBooking> = (rowA, rowB) => {
  const { name: nameA } = rowA.getValue('test') as ITest;
  const { name: nameB } = rowB.getValue('test') as ITest;

  return nameA < nameB ? 1 : -1;
};

export const withPatient: FilterFn<any> = (row, columnId, filterValue) => {
  const { firstName, lastName } = row.getValue('patient') as IPatient;
  const patientName = `${firstName} ${lastName}`;
  return stringIncludes(patientName, filterValue);
};

export const withStatus: FilterFn<any> = (row, columnId, filterValue) => {
  const status = row.getValue('status');
  return filterValue.split(',').includes(status);
};

export const withEmail: FilterFn<any> = (row, columnId, filterValue) => {
  const { email } = row.getValue('user') as IUserGql;
  return stringIncludes(email, filterValue);
};

export const withAppointment: FilterFn<any> = (row, columnId, filterValue) => {
  const { name } = row.getValue('test') as ITest;
  return stringIncludes(name, filterValue);
};

export const withCenter: FilterFn<any> = (row, columnId, filterValue) => {
  const { name } = row.getValue('center') as ICenter;
  return filterValue.split(',').includes(name);
};

export function getClassnameByStatus(status: BookingStatus): string {
  switch (status) {
    case BookingStatus.Pending:
    case BookingStatus.PendingPayment: {
      return 'pending';
    }
    case BookingStatus.ConfirmedAuto:
    case BookingStatus.ConfirmedManual: {
      return 'confirmed';
    }
    case BookingStatus.CancelledUser:
    case BookingStatus.CancelledAdmin: {
      return 'cancelled';
    }
    case BookingStatus.CheckedInAuto:
    case BookingStatus.CheckedInManual: {
      return 'checkedIn';
    }
    case BookingStatus.NoShow: {
      return 'noShow';
    }
    case BookingStatus.ToCheck: {
      return 'toCheck';
    }
    default: {
      return 'error';
    }
  }
}

function concatPaymentValues(payments: IPayment[], value: PaymentValues) {
  const concat = payments.map((payment: IPayment) => {
    if (value === PaymentValues.DATE) {
      return !!payment[PaymentValues.DATE]
        ? format(
            addHours(
              parse(
                payment[PaymentValues.DATE],
                'yyyy-MM-dd HH:mm:ss',
                new Date(),
              ),
              1,
            ),
            'yyyy-MM-dd HH:mm',
          )
        : '-----';
    }
    return payment[value];
  });
  return concat.length > 0 ? concat.join(', ') : '-----';
}

export function mapTableBookingDataToCsv(data: ITableBooking[]) {
  return data.map(booking => {
    return {
      'booking ID internal': booking.code,
      status: booking.status,
      'booking date': booking.bookingDate,
      'booking ID patient': booking.bookingID,

      'test id': booking.test.id,
      'test name': booking.test.name,

      'user id': booking.user?.id,
      'user name': booking.user?.firstName,
      'user surname': booking.user?.lastName,
      'user email': booking.user?.email,
      'user phone': booking.user?.phone,

      'patient id': booking.patient.id,
      'patient name': booking.patient.firstName,
      'patient surname': booking.patient.lastName,
      'patient birth date': booking.patient.birthDate,
      'patient fiscal code': booking.patient.fiscalCode,
      'patient gender': booking.patient.gender,
      'patient birth city': booking.patient.birthPlace,

      'reservation date': booking.reservationDate.split(' - ')[0],
      'reservation time': booking.reservationDate.split(' - ')[1],

      'center id': booking.center.id,
      'center name': booking.center.name,
      'center address': booking.center.address,
      'payment amount': concatPaymentValues(
        booking.payment,
        PaymentValues.AMOUNT,
      ),
      'payment date': concatPaymentValues(booking.payment, PaymentValues.DATE),
      'payment card type': concatPaymentValues(
        booking.payment,
        PaymentValues.CARD_ISSUER,
      ),
      'payment type': concatPaymentValues(booking.payment, PaymentValues.TYPE),
      'payment commission': concatPaymentValues(
        booking.payment,
        PaymentValues.COMMISSION,
      ),
      'payment status': concatPaymentValues(
        booking.payment,
        PaymentValues.STATUS,
      ),
    };
  });
}

export function getCentersNames(entries: TTableBookings) {
  const centers = entries?.map(e => e.center.name);
  if (!centers) return [];
  return [...new Set(centers)].sort((a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    if (b.toLowerCase() > a.toLowerCase()) {
      return -1;
    }
    return 0;
  });
}
export function renderCustomFilter(
  id: string,
  column: Column<ITableBooking, unknown>,
  entries: TTableBookings,
) {
  switch (id) {
    case 'status': {
      return (
        <DropdownFilter<ITableBooking, BookingStatus>
          title="Statuses"
          options={Object.values(BookingStatus)}
          columnInstance={column}
        />
      );
    }
    case 'center': {
      return (
        <DropdownFilter
          title="Centers"
          options={getCentersNames(entries)}
          columnInstance={column}
        />
      );
    }
    default: {
      return undefined;
    }
  }
}
