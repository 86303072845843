import { getGeocode, getLatLng } from 'use-places-autocomplete';

const getCoords = (address: string) => {
  return getGeocode({ address })
    .then((details: any) => {
      const [descr] = details;
      const { lat, lng } = getLatLng(descr);
      return [lat, lng];
    })
    .catch((error: any) => error);
};

const getPlace = (ID: string) => {
  return getGeocode({ placeId: ID })
    .then((details: any) => {
      const [descr] = details;

      const { lat, lng } = getLatLng(descr);

      return {
        id: ID,
        address: descr.formatted_address,
        coords: [lat, lng],
      };
    })
    .catch((error: any) => error);
};

const places = { getCoords, getPlace };

export default places;
