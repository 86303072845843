import { useMutation } from '@apollo/client';
import { CircularProgress, Dialog, DialogTitle } from '@mui/material';
import React, { FC, useState } from 'react';

import { Control } from '../../../pages/BookingDetails/BookingDetails.styled';
import { BookingStatus } from '../../../types';
import notify from '../../../utils/notify';
import { CHANGE_BOOKING_STATUS } from './Booking.query';
import {
  BookingStatusDialogActions,
  BookingStatusDropdown,
  BookingStatusDropdownItem,
  BookingStatusDropdownOverlay,
} from './Booking.styled';

export interface BookingChangeStatusDialogState {
  bookingID: null | string;
  status: null | BookingStatus;
}
interface BookingChangeStatusDialogProps {
  state: BookingChangeStatusDialogState;
  closeDialog: () => void;
  closeDropdown: () => void;
  dropdownCoords: { x: number; y: number };
}

export const BookingChangeStatusDialog: FC<BookingChangeStatusDialogProps> = ({
  state,
  closeDialog,
  closeDropdown,
  dropdownCoords,
}) => {
  const [newStatus, setNewStatus] = useState<null | BookingStatus>(null);

  const [changeBookingStatusMutation, { loading }] = useMutation(
    CHANGE_BOOKING_STATUS,
    { onCompleted },
  );

  function onCompleted() {
    closeDialogAndClearLocalState();
    notify('Stato cambiato', 'success');
    if (state.status === BookingStatus.ToCheck) {
      setNewStatus(null);
    }
  }
  function closeDialogAndClearLocalState() {
    closeDialog();
    setNewStatus(null);
  }
  function openConfirmationDialog(status: BookingStatus) {
    setNewStatus(status);
    closeDropdown();
  }
  async function changeStatus() {
    await changeBookingStatusMutation({
      variables: { id: state.bookingID, status: newStatus },
    });
  }

  return (
    <>
      {!!dropdownCoords.x && !!dropdownCoords.y && (
        <>
          <BookingStatusDropdownOverlay onClick={closeDropdown} />
          <BookingStatusDropdown
            style={{
              top: `${dropdownCoords.y}px`,
              left: `${dropdownCoords.x}px`,
            }}
          >
            {state.status === BookingStatus.ToCheck ? (
              <>
                <BookingStatusDropdownItem
                  onClick={() =>
                    openConfirmationDialog(BookingStatus.CheckedInManual)
                  }
                >
                  CheckedIn Manual
                </BookingStatusDropdownItem>
                <BookingStatusDropdownItem
                  onClick={() => openConfirmationDialog(BookingStatus.NoShow)}
                >
                  No Show
                </BookingStatusDropdownItem>
              </>
            ) : state.status === BookingStatus.Pending ? (
              <>
                <BookingStatusDropdownItem
                  onClick={() =>
                    openConfirmationDialog(BookingStatus.ConfirmedManual)
                  }
                >
                  Confirmed Manual
                </BookingStatusDropdownItem>
              </>
            ) : null}
          </BookingStatusDropdown>
        </>
      )}
      <Dialog open={!!newStatus} onClose={closeDialogAndClearLocalState}>
        <DialogTitle>Conferma cambio status</DialogTitle>

        <BookingStatusDialogActions>
          <Control onClick={changeStatus} disabled={loading} fullWidth>
            {loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              'Conferma'
            )}
          </Control>
          <Control
            className="Inverse"
            onClick={closeDialogAndClearLocalState}
            disabled={loading}
            fullWidth
          >
            Annulla
          </Control>
        </BookingStatusDialogActions>
      </Dialog>
    </>
  );
};
