import { Box, Typography } from '@mui/material';
import React from 'react';

import { icons } from './Feedback.data';
import { IFeedbackProps } from './Feedback.types';

const Feedback = ({ status, feedback, comment }: IFeedbackProps) => {
  const Icon = icons[feedback];

  return (
    <>
      <Typography sx={{ mb: '24px' }} variant="h3">{`Feedback sondaggio ${
        status === 'pre' ? '' : 'post'
      } prenotazione`}</Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}
      >
        <Icon />
        {/*<Stack sx={{ ml: '10px' }} direction="column" spacing="6px">*/}
        {/*  <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">*/}
        {/*    {feedback}*/}
        {/*  </Typography>*/}
        {/*  {!!comment && <Typography variant="body1">{`Commenti: ${comment}`}</Typography>}*/}
        {/*</Stack>*/}
      </Box>
    </>
  );
};

export default Feedback;
