import { SortingFn } from '@tanstack/react-table';
import { IUserInfo } from './Users/Users.types';

export const sortByName =
  (key: string): SortingFn<IUserInfo> =>
  (rowA, rowB) => {
    const { firstName: firstNameA, lastName: lastNameA } = rowA.getValue(
      key,
    ) as IUserInfo;
    const { firstName: firstNameB, lastName: lastNameB } = rowB.getValue(
      key,
    ) as IUserInfo;
    const nameA = `${firstNameA} ${lastNameA}`;
    const nameB = `${firstNameB} ${lastNameB}`;

    return nameA < nameB ? 1 : -1;
  };
