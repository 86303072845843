import React, { createContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import reset from 'styles/reset';
import paths from 'routes/paths';

import { PrivateRoutes } from 'routes/PrivateRoutes';

import Aside from 'components/Aside';
import Search from 'pages/Search.copied';
import Bookings from 'pages/Bookings';
import Registry from 'pages/Registry';
import Results from 'pages/_Results';
import Recap from 'pages/Confirmation';
// import Active from 'pages/Active';
import Patient from 'pages/Patient';
// import Booking from 'pages/Booking';

import Authorization from 'pages/Authorization';
import Signin from 'components/Authorization/Signin';
import Reset from 'components/Authorization/Reset';
import Confirmation from 'components/Authorization/Confirmation';

import BookingDetails from 'pages/BookingDetails';

import { useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from 'components/Places/Places.types';
import { getSocket } from 'utils';

export const GoogleMapsAPIContext = createContext(false);

const KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries: Libraries = ['places'];

export const socket = getSocket('slots');
export const SocketContext = createContext<Socket>(socket);

function App() {
  const { isLoaded } = useJsApiLoader({
    id: 'oc-map',
    googleMapsApiKey: KEY ?? '',
    language: 'it',
    libraries,
  });

  return (
    <SocketContext.Provider value={socket}>
      <GoogleMapsAPIContext.Provider value={isLoaded}>
        <ThemeProvider theme={reset}>
          <CssBaseline>
            <Routes>
              <Route path="/" element={<Navigate to="/auth" />} />
              <Route path="/auth" element={<Authorization />}>
                <Route index element={<Signin />} />
                <Route path="reset" element={<Reset />} />
                <Route path="confirm" element={<Confirmation />} />
              </Route>
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<Aside />}>
                  <Route path={paths().search} element={<Search />} />
                  <Route path={paths().registry} element={<Registry />} />
                  <Route path={paths().booking} element={<Bookings />} />
                  <Route path={paths().results} element={<Results />} />
                  <Route path={paths().confirmation} element={<Recap />} />
                  <Route
                    path={paths().bookingID}
                    element={<BookingDetails />}
                  />
                  <Route path={paths().patient} element={<Patient />} />
                </Route>
              </Route>
            </Routes>
          </CssBaseline>
        </ThemeProvider>
      </GoogleMapsAPIContext.Provider>
      <ToastContainer />
    </SocketContext.Provider>
  );
}

export default App;
