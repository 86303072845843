import { ReactComponent as Booking } from 'icons/views/booking.svg';
import { ReactComponent as Patient } from 'icons/views/patient.svg';
// import { ReactComponent as Payment } from 'icons/views/payment.svg';
// import { ReactComponent as Logs } from 'icons/views/logs.svg';

export const views = [
  { label: 'Prenotazione', Icon: Booking },
  // { label: 'Utente', Icon: Patient },
  { label: 'Paziente', Icon: Patient },
  // { label: 'Pagamento', Icon: Payment },
  // { label: 'Logs', Icon: Logs },
];
