import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';

export const MuiInput = {
  // Fixme: Type props appropriately
  Primary: styled((props: any) => <TextField {...props} />)(({ theme }) => ({
    width: '100%',
    '& label': {
      top: 'auto',
      bottom: 'calc(100% + 6px)',
      transform: 'none',
      transformOrigin: 'unset',
      fontWeight: 800,
      fontSize: '14px',
      lineHeight: 1.2,
      color: '#607D8B',
      '&.Mui-focused': {
        color: '#607D8B',
      },
    },
    '& legend': {
      height: 'auto',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'inherit',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.2px',
      color: '#263238',
      border: '1px solid #B0BEC5',
      borderRadius: '16px',
      '&.Mui-focused': {
        borderColor: '#263238',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '&.with-error': {
      '& label': {
        color: '#D81B60',
      },
      '& .MuiOutlinedInput-root': {
        borderColor: '#D81B60',
      },
      '& .MuiFormHelperText-root': {
        color: '#D81B60',
      },
    },
    '&.regime': {
      marginBottom: '46px',
      '&:last-of-type': {
        marginBottom: '34px',
      },
      '& .MuiOutlinedInput-input': {
        [theme.breakpoints.up('lg')]: {
          padding: '16px 16px 16px 20px',
        },
      },
    },
  })),
};
