import { createSlice } from '@reduxjs/toolkit';

import { userOperations } from './operations';
import { IUserInitialState } from './types';

export const initialState: IUserInitialState = {
  loading: false,
  info: null,

  userByIdLoading: false,
  userById: null,

  usersLoading: false,
  users: null,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(userOperations.getUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(userOperations.getUser.fulfilled, (state, action) => {
      state.info = action.payload ?? null;
      state.loading = false;
    });
    builder.addCase(userOperations.getUser.rejected, state => {
      state.loading = false;
    });

    builder.addCase(userOperations.getUserById.pending, state => {
      state.userByIdLoading = true;
    });
    builder.addCase(userOperations.getUserById.fulfilled, (state, action) => {
      state.userById = !!action.payload ? action.payload : null;
      state.userByIdLoading = false;
    });
    builder.addCase(userOperations.getUserById.rejected, state => {
      state.userByIdLoading = false;
    });

    builder.addCase(userOperations.getUsers.pending, state => {
      state.usersLoading = true;
    });
    builder.addCase(userOperations.getUsers.fulfilled, (state, action) => {
      state.users = !!action.payload ? action.payload : null;
      state.usersLoading = false;
    });
    builder.addCase(userOperations.getUsers.rejected, state => {
      state.usersLoading = false;
    });
  },
});

export const userReducer = user.reducer;
