import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import usePlacesAutocomplete, { Suggestion } from 'use-places-autocomplete';

import MuiClickAwayListener from '@mui/material/ClickAwayListener';
import MuiBox from '@mui/material/Box';
import MuiStack from '@mui/material/Stack';
import MuiDivider from '@mui/material/Divider';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiProgress from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

import { Input } from 'components/Styled';

import { GoogleMapsAPIContext as PlacesAPIContext } from 'App';

import {
  requestOptions,
  DEBOUNCE,
  EMPTY,
  OK,
  ID,
  FIELD_NAME,
  ALT_STYLES_PAGES,
} from './data';
import { Props, AnchorEl } from './Residence.types';

const TextField = styled(Input)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    '& .MuiOutlinedInput-input': {
      padding: '16px 14px',
    },
  },
}));

const Box = styled(MuiBox)({
  position: 'absolute',
  overflow: 'hidden',
  zIndex: 2,

  background: '#ffffff',
  border: '1px solid #121A26',
  borderRadius: '20px',
});

const Divider = styled(MuiDivider)({ marginTop: 0, marginBottom: 0 });

const MenuItem = styled(MuiMenuItem)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  whiteSpace: 'normal',

  '&:first-of-type': {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },

  '&+.MuiDivider-root': {
    marginTop: 0,
    marginBottom: 0,
  },
});

const Residence: React.FC<Props> = ({
  passResidence,
  initialValue,
  hasError,
}) => {
  const loaded = useContext(PlacesAPIContext);
  const { init, value, setValue, suggestions, clearSuggestions } =
    usePlacesAutocomplete({
      requestOptions,
      debounce: DEBOUNCE,
      defaultValue: initialValue,
    });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    return setValue(value);
  };

  useEffect(() => setValue(initialValue), [initialValue, setValue]);

  useEffect(() => {
    if (!loaded) return;
    init();
  }, [loaded, init]);

  const { loading, status, data } = suggestions;

  const [anchorEl, setAnchorEl] = useState<AnchorEl>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (e: React.MouseEvent<HTMLInputElement>) => {
    const { target } = e;
    return setAnchorEl(target as HTMLInputElement);
  };
  const handleClose = () => setAnchorEl(null);

  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    const { target } = e;
    //   @ts-ignore
    if (target?.id === ID) return;
    setValue('');
    passResidence(FIELD_NAME, '');
    clearSuggestions();
    handleClose();
  };

  const handleClick = (descr: string) => {
    setValue(descr);
    passResidence(FIELD_NAME, descr);
    handleClose();
  };

  const { pathname } = useLocation();
  const styles =
    ALT_STYLES_PAGES.includes(pathname) || pathname.includes('/patient')
      ? { '& label': { bottom: 'calc(100% - 4px)' } }
      : {};

  console.log(loaded, 'loaded');

  return (
    <>
      <TextField
        sx={{ position: 'relative', ...styles }}
        className={classNames({ 'with-error': Boolean(hasError) })}
        id={ID}
        label={'Indirizzo di residenza'}
        value={value}
        onChange={onChange}
        onClick={handleOpen}
        // helperText={Boolean(hasError) ? hasError : ''}
      />

      {open && (loading || status === EMPTY || status === OK) && (
        <MuiClickAwayListener onClickAway={e => handleClickAway(e)}>
          <Box width={anchorEl?.offsetWidth}>
            {loading ? (
              <MuiStack divider={<Divider />}>
                {Array.from({ length: 5 }, (_, i) => i).map((idx: number) => (
                  <MuiProgress sx={{ height: '35px' }} />
                ))}
              </MuiStack>
            ) : status === EMPTY ? (
              <MuiStack>
                <MenuItem disabled>
                  {'nessun.esame.corrisponde.alla.ricerca'}
                </MenuItem>
              </MuiStack>
            ) : status === OK ? (
              <MuiStack divider={<Divider />}>
                {data.map((sugg: Suggestion) => {
                  const { place_id, description } = sugg;
                  return (
                    <MenuItem
                      key={place_id}
                      onClick={(e: React.MouseEvent<HTMLLIElement>) =>
                        handleClick((e.target as HTMLLIElement).innerText)
                      }
                    >
                      {description}
                    </MenuItem>
                  );
                })}
              </MuiStack>
            ) : (
              <></>
            )}
          </Box>
        </MuiClickAwayListener>
      )}
    </>
  );
};

export default Residence;
