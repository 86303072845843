import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { ReactComponent as Arrow } from 'icons/adornments/arrow.svg';
import React, { useState, useRef, useEffect } from 'react';

import schemas from '../../graphql/schemas';
import useDebounce from '../../hooks/useDebounce';
import Input from './Input';

const StyledPopover = styled(Popover)({
  background: 'transparent',
  '& > .MuiPaper-root': {
    boxSizing: 'border-box',
    width: '100%',
    maxHeight: '200px',
    border: '1px solid #B0BEC5',
    borderRadius: '16px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
});

const StyledDivider = styled(Divider)({
  margin: '0px !important',
});

const StyledText = styled(ListItemText)({
  '& > .MuiTypography-root': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0.2,
  },
});

const StyledIcon = styled(ListItemIcon)({
  minWidth: 'auto !important',
});

const Cities = ({
  initial,
  styles,
  setBirthplace,
  desktop,
  isInvalid,
  error,
}: {
  initial?: string;
  styles?: any;
  setBirthplace?: any;
  desktop?: boolean;
  isInvalid?: boolean;
  error?: any;
}) => {
  const [value, setValue] = useState(initial || '');

  useEffect(() => {
    // if (!initial) return;

    // @ts-ignore
    setValue(initial);
  }, [initial]);

  const [skip, setSkip] = useState(true);

  const [name, setName] = useState('');
  const debounced = useDebounce(value, 250);
  const [isSearchEnabled, setIsSearchEnabled] = useState(() => !initial);

  const anchorElRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);

  const [findCities, { data, called, loading }] = useLazyQuery(
    schemas.FIND_CITIES,
    {
      variables: { name },
      fetchPolicy: 'network-only',
      onCompleted: () => setAnchorEl(anchorElRef?.current),
    },
  );

  useEffect(() => {
    if (!debounced || debounced === '' || !isSearchEnabled) return;
    setName(debounced);
  }, [debounced, isSearchEnabled]);

  useEffect(() => {
    console.log(name.trim().length, 'length');

    if (name.trim().length < 3) return;
    findCities();
  }, [name, findCities, initial, skip]);

  // const cities = ['Stato estero', ...data?.findCities];
  const cities =
    data?.findCities && data?.findCities.length !== 0
      ? [{ id: 'Stato estero', name: 'Stato estero' }, ...data?.findCities]
      : data?.findCities && data?.findCities.length === 0
      ? [{ id: 'Stato estero', name: 'Stato estero' }]
      : undefined;

  console.log(cities, 'cities');

  const handleClose = (reason?: string) => {
    if (reason && reason === 'backdropClick') {
      setValue('');
      setBirthplace('birthPlace', '');
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box ref={anchorElRef} sx={styles?.wrapper}>
      <Input
        label="Luogo di nascita"
        value={value}
        onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
          if (!isSearchEnabled) {
            setIsSearchEnabled(true);
          }
        }}
        className={isInvalid ? 'Invalid' : ''}
        helperText={isInvalid ? error : ''}
      />
      <StyledPopover
        sx={{
          '& > .MuiPaper-root': {
            height:
              cities && cities?.length === 0
                ? '50px'
                : cities
                ? `${cities?.length * 50}px`
                : 'auto',
            // maxWidth: desktop ? '320px' : 'initial',
            // maxWidth: desktop ? '320px' : '480px',
            maxHeight: '320px',
            maxWidth: '320px',
          },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={(_, reason) => handleClose(reason)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack divider={<StyledDivider />}>
          {cities?.length === 0 ? (
            <MenuItem disabled>
              <StyledText>Try another query</StyledText>
              <StyledIcon>
                <Arrow />
              </StyledIcon>
            </MenuItem>
          ) : (
            cities?.map((city: any, idx: number) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  setValue(city.name);
                  setIsSearchEnabled(false);
                  setBirthplace('birthPlace', city.name);
                  // if (city.name !== "Stato estero") {
                  //   setBirthplace("foreigner", false);
                  // }
                  handleClose();
                }}
              >
                <StyledText>{city.name}</StyledText>
                <StyledIcon>
                  <Arrow />
                </StyledIcon>
              </MenuItem>
            ))
          )}
        </Stack>
      </StyledPopover>
    </Box>
  );
};

export default Cities;
