// import { parse, startOfToday, isEqual } from 'date-fns';
import { useState, useContext, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import { SocketContext } from '../App';
import events from '../api/constants';
import { ISeoCitySlot, Finished } from '../types/models';

import limit from '../utils/limit';

import { useAppSelector } from '../redux/hooks';
import { getQueryPlaceAddress } from '../redux/query/selectors';

const useSocket = (
  centerID: string | null,
  testID: string,
  coords: [number, number],
  date?: string | null,
) => {
  const [shouldSlotsRefetch, setShouldSlotsRefetch] = useState(false);
  const isQueryWithinPublicSystem = false;
  const withinPublicSystemQueryPayload = null;
  const isQueryWithinPublicSystemWithoutReceipt = false;
  const withinPublicSystemWithoutReceiptQueryPayload = null;

  const locationName = useAppSelector(getQueryPlaceAddress);
  // const userId = useAppSelector(authSelectors.getUsersID);
  const userId = null;

  // FIXME: fix data type (prev. ISeoCitySlot)
  const [data, setData] = useState<any[]>([]);
  const empty = data.length === 0;

  const [isLoading, setIsLoading] = useState(true);
  const [isFinished, setIsFinished] = useState(true);

  const [abort, setAbort] = useState(false);

  const sockets: string[] = [];

  const socket = useContext<Socket>(SocketContext);

  const [uid, setUid] = useState(() => uuidv4());
  const changeUIDonDateChange = () => setUid(uuidv4());

  useEffect(() => {
    if (!date) return;
    const timeoutID = setTimeout(() => {
      if (
        empty &&
        !isQueryWithinPublicSystem &&
        !isQueryWithinPublicSystemWithoutReceipt
      )
        setAbort(true);
    }, 10000);
    return () => {
      clearTimeout(timeoutID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const [prolonged, setProlonged] = useState(false);

  // FIXME: invalid usage of a statement with "empty" var
  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (!empty) return setIsFinished(true);
      setProlonged(true);
      setTimeout(() => {
        if (empty) return setAbort(true);
      }, 30000);
      // 180s
    }, 180000);
    return () => {
      clearTimeout(timeoutID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!prolonged || empty) return;
    setIsFinished(true);
  }, [prolonged, empty]);

  const handleAvails = (data: {
    slot: ISeoCitySlot;
    finished?: Finished;
    reqId?: string;
  }) => {
    setIsLoading(false);

    if (data.hasOwnProperty('finished')) {
      return setIsFinished(true);
    }

    const { slot } = data;

    const { slots } = slot;
    const [avail] = slots;

    // if (date && avail.date !== date) {
    //   setIsLoading(true);
    //   setIsFinished(false);
    //   return;
    // }

    if (sockets.includes('slots')) {
      const idx = sockets.findIndex((el: string) => el === 'slots');
      sockets.splice(idx, 1);
    }

    // FIXME: should be removed when implemented on backend
    // if (!date && isEqual(startOfToday(), parse(avail.date, 'yyyy-MM-dd', new Date()))) {
    //   setIsLoading(true);
    //   setIsFinished(false);
    //   return;
    // }
    //

    if (((date && avail.date === date) || !date) && uid === data.reqId) {
      const limited = limit(slot);
      if (limited.slots.length > 0) {
        return setData((prev: ISeoCitySlot[]) => [...prev, limited]);
      }
    }
  };

  const handlePublicSystemAvails = () => {};

  // const handlePublicSystemAvails = (slot: IPublicSystemSlot) => {
  //   console.log(slot, 'I_PUBLIC_SYSTEM_SLOT');
  //   setIsLoading(false);

  //   if (slot.hasOwnProperty('finished')) {
  //     // setShouldSlotsRefetch(false);
  //     return setIsFinished(true);
  //   }

  //   if (sockets.includes('slots')) {
  //     const idx = sockets.findIndex((el: string) => el === 'slots');
  //     sockets.splice(idx, 1);
  //   }

  //   // return setData(prev => [...prev, normalizePublicSystemSlot(slot)]);
  //   return setData(prev => {
  //     console.log(
  //       !!prev.find(
  //         (publicSystemSlot: any) => slot.center.code === publicSystemSlot.code,
  //       ),
  //       'OCCURENCE',
  //     );
  //     return !!prev.find(
  //       (publicSystemSlot: any) => slot.center.code === publicSystemSlot.code,
  //     )
  //       ? prev
  //       : [...prev, normalizePublicSystemSlot(slot)];
  //   });
  // };

  const offPrevious = () => {
    socket.off(
      events.listen,
      isQueryWithinPublicSystem ? handlePublicSystemAvails : handleAvails,
    );
  };

  useEffect(() => {
    setShouldSlotsRefetch(false);
    console.log(abort && empty, 'abort && empty');

    if (abort && empty) {
      setIsLoading(false);
      setIsFinished(true);
      // socket.off(events.listen, handleAvails);
      offPrevious();
      return;
    }

    socket.on(
      events.listen,
      isQueryWithinPublicSystem || isQueryWithinPublicSystemWithoutReceipt
        ? handlePublicSystemAvails
        : handleAvails,
    );

    // return () => {
    //   socket.off(events.listen, handleAvails);
    // };

    return offPrevious;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, date, abort]);

  useEffect(() => {
    if ((centerID || sockets.includes('slots')) && !shouldSlotsRefetch) return;
    setIsLoading(true);
    setIsFinished(false);
    setData([]);
    setAbort(false);
    socket.emit(
      isQueryWithinPublicSystem
        ? 'find.public-slots.with-receipt'
        : isQueryWithinPublicSystemWithoutReceipt
        ? 'find.public-slots'
        : events.emit,
      isQueryWithinPublicSystem
        ? {
            // fiscalCode: withinPublicSystemQueryPayload?.fiscalCode,
            // patientNumber: withinPublicSystemQueryPayload?.patientNumber,
            // receipt: withinPublicSystemQueryPayload?.receipt,
            // regionId: withinPublicSystemQueryPayload?.regionId,
          }
        : isQueryWithinPublicSystemWithoutReceipt
        ? {
            // test: withinPublicSystemWithoutReceiptQueryPayload?.test,
            // regionId: withinPublicSystemWithoutReceiptQueryPayload?.regionId,
          }
        : {
            //   location: [45.67507441667757, 9.23519795596961],
            //   test: '634e434932f8e28ba8deac2e',
            //   date: '2022-10-20',
            location: coords,
            test: testID,
            ...(!!date ? { date } : { soonest: true }),
            reqId: uid,
            ...(!!locationName ? { locationName } : {}),
            ...(!!userId ? { userId } : {}),
          },
    );

    sockets.push('slots');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, testID, coords, date, shouldSlotsRefetch]);

  // useEffect(() => {
  //   if (!centerID || sockets.includes('avails')) return;
  //   socket.emit(
  //     events.slots,
  //     {
  //       location: coords,
  //       test: testID,
  //       center: centerID,
  //       date,
  //     },
  //     ({ slot: data }: any) => {
  //       if (sockets.includes('avails')) {
  //         const idx = sockets.findIndex((el: string) => el === 'avails');
  //         sockets.splice(idx, 1);
  //       }
  //       console.log(data, 'slot');
  //     },
  //   );
  //   sockets.push('avails');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socket, centerID, testID, coords, date]);

  return {
    data,
    setData,
    isLoading,
    isFinished,
    setIsLoading,
    offPrevious,
    changeUIDonDateChange,
    setShouldSlotsRefetch,
  };
};

export default useSocket;
