import { useMutation } from '@apollo/client';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Go from '../../components/Go';
import Views from '../../components/Views';
import notify from '../../utils/notify';
import { views } from './BookingDetails.data';
import { useBooking } from './BookingDetails.helpers';
import { RESEND_BOOKING_CONFIRMATION } from './BookingDetails.query';
import { Control, Flexed } from './BookingDetails.styled';
import { ViewsTypes } from './BookingDetails.types';
import { Booking } from './TabsContent/Booking.component';
import { Patient } from './TabsContent/Patient.component';
import { Payments } from './TabsContent/Payment.component';
import { User } from './TabsContent/User.component';
import { Logs } from './TabsContent/Logs.component';

const BookingDetails = () => {
  const navigate = useNavigate();
  const { loading: bookingLoading, booking, id } = useBooking();

  const [view, setView] = useState(ViewsTypes.Booking);

  const [resendBookingConfirmation, { loading }] = useMutation(
    RESEND_BOOKING_CONFIRMATION,
    {
      variables: { id },
      onCompleted: onCancellationFulfilled,
    },
  );

  function onCancellationFulfilled() {
    notify('Conferma della prenotazione reinviata con successo', 'success');
  }
  const canResendInvitation = useMemo(() => {
    if (booking?.reservationDate) {
      return new Date().getTime() < new Date(booking.reservationDate).getTime();
    }
    return false;
  }, [booking]);

  useEffect(() => {
    document.body.scrollIntoView();
  }, [view]);

  return (
    <Container sx={{ padding: '28px 34px', overflow: 'hidden' }}>
      <Flexed sx={{ pb: '28px' }}>
        <Go onClick={() => navigate(-1)} />
        <Typography variant="h1" sx={{ ml: '25px' }}>
          {`Prenotazione ${id}`}
        </Typography>
        <Control
          sx={{ ml: 'auto' }}
          onClick={
            canResendInvitation ? () => resendBookingConfirmation() : undefined
          }
          disabled={!canResendInvitation || loading}
        >
          {loading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Invia prenotazione al paziente'
          )}
        </Control>
      </Flexed>
      {bookingLoading ? null : (
        <Views views={views} initial={ViewsTypes.Booking} liftView={setView} />
      )}
      <Box sx={{ mt: '36px' }}>
        {view === ViewsTypes.Booking && <Booking />}
        {/* {view === ViewsTypes.User && booking?.user && (
          <User userId={booking.user.id} />
        )} */}
        {view === ViewsTypes.Patient && <Patient />}
        {/* {view === ViewsTypes.Payment && <Payments />} */}
        {/* {view === ViewsTypes.Logs && <Logs />} */}
      </Box>
    </Container>
  );
};

export default BookingDetails;
