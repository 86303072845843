import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Map, Init } from './types';

const initialState: Map = {
  loaded: false,
};

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Init>) => {
      state.loaded = action.payload;
    },
  },
});

export const { init } = map.actions;

export default map.reducer;
