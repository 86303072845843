import { gql } from '@apollo/client';

export const PAYMENT_FRAGMENT_GQL = gql`
  fragment PaymentFragment on Payment {
    id
    date
    status
    type
    amount
    axerveId
    cardType
    cardIssuer
    commission
  }
`;

export const PATIENT_FRAGMENT_GQL = gql`
  fragment PatientFragment on Patient {
    id
    firstName
    lastName
    fiscalCode
    email
    phone
    birthDate
    birthPlace
    residenceAddress
    gender
    nationality
    creationDate
  }
`;

export const USER_FRAGMENT_GQL = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    phone
  }
`;

export const CHAIN_FRAGMENT_GQL = gql`
  fragment ChainFragment on Chain {
    id
    name
    label
    logo
    logoBig
    enabled
    type
  }
`;

export const TEST_FRAGMENT_GQL = gql`
  fragment TestFragment on Test {
    id
    name
    enabled
    answers {
      title
      service
    }
    code
    category1 {
      id
      name
    }
    category {
      id
      name
    }
    question
    prescription
    label
  }
`;
