import styled from '@emotion/styled';

const ITEM_BOX_DEFAULT_HEIGHT = 112;
const ITEM_BOX_MARGIN_BOTTOM = 12;
const VISIBLE_ITEM_BOXES = 7;

export const CentersBox = styled('div', { label: 'CentersBox' })`
  // max-height: calc((${ITEM_BOX_DEFAULT_HEIGHT}px + ${ITEM_BOX_MARGIN_BOTTOM}px) * ${VISIBLE_ITEM_BOXES});
  // height: 100%;
  // overflow-y: auto;
  // padding-right: 10px;

  @media (max-width: 1199px) {
    margin-top: 20px;
    // max-height: 80vh;
    padding-right: 0;
  }

  // overscroll-behavior-y: auto;
  &.res-page {
    padding-right: 0;
  }
`;

export const CentersItemMediaWrapper = styled('div', {
  label: 'CentersItemMediaWrapper',
})`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.with-mr {
    margin-right: 12px;
  }

  @media (min-width: 1200px) {
    &.with-mr > svg {
      margin-top: 6px;
    }
  }

  @media (max-width: 480px) {
    & > svg {
      position: absolute;
      top: calc(100% + 6px);
    }
  }
`;
