import { Typography } from '@mui/material';
import React from 'react';

import { IBooking } from '../../types';
import { groupBookings } from '../../utils/groupBookings';
import { BookingCard } from './BookingCard.component';
import { BookingCardsBox, BookingCardsTitle } from './BookingCards.styled';
import { BookingCardsSkeletons } from './BookingCardsSkeletons.component';

interface BookingCardsProps {
  loading: boolean;
  bookings: IBooking[] | undefined;
}
export const BookingCards: React.FC<BookingCardsProps> = ({
  loading,
  bookings,
}) => {
  const groupedBooking = groupBookings(bookings);

  if (loading) return <BookingCardsSkeletons />;

  if (
    (!bookings?.length && !loading) ||
    (groupedBooking &&
      !groupedBooking.past.length &&
      !groupedBooking.future.length)
  )
    return (
      <Typography variant="h3" align="center">
        Prenotazioni non trovate
      </Typography>
    );

  return (
    <BookingCardsBox>
      {groupedBooking && !!groupedBooking.future.length && (
        <>
          <BookingCardsTitle>Prossime</BookingCardsTitle>
          {groupedBooking &&
            groupedBooking.future.map(booking => (
              <BookingCard booking={booking} />
            ))}
        </>
      )}

      {groupedBooking && !!groupedBooking.past.length && (
        <>
          <BookingCardsTitle>Passate</BookingCardsTitle>
          {groupedBooking &&
            groupedBooking.past.map(booking => (
              <BookingCard booking={booking} isPast />
            ))}
        </>
      )}
    </BookingCardsBox>
  );
};
