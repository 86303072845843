import Typography from '@mui/material/Typography';
import Reusable from 'components/Authorization/Reusable';
import Styled from 'components/Styled';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { IForm } from './Confirmation.types';

const Confirmation = (): JSX.Element => {
  const location = useLocation();
  console.log(location, 'location');

  const onSubmit = (vals: IForm) => {
    console.log(vals, 'vals');
  };

  return (
    <Reusable.Wrapper>
      <Typography sx={{ mb: '8px' }} variant="h1">
        Modifica password
      </Typography>
      <Typography sx={{ mb: '50px' }} variant="caption">
        Crea una nuova password efficace
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, dirtyFields }) => {
          const hasPristineFields = Object.values(dirtyFields).length < 2;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="password"
                render={({ input }) => {
                  return (
                    <Styled.Input
                      sx={{ mb: '60px' }}
                      type="password"
                      label="Password"
                      InputLabelProps={{ shrink: true, focused: false }}
                      helperText="La password deve contenere almeno..."
                      {...input}
                    />
                  );
                }}
              />
              <Field
                name="confirmed"
                render={({ input }) => {
                  return (
                    <Styled.Input
                      sx={{ mb: '16px' }}
                      type="password"
                      label="Conferma Password"
                      InputLabelProps={{ shrink: true, focused: false }}
                      {...input}
                    />
                  );
                }}
              />
              <Styled.Button type="submit" disabled={hasPristineFields}>
                Aggiorna password
              </Styled.Button>
            </form>
          );
        }}
      />
    </Reusable.Wrapper>
  );
};

export default Confirmation;
