// https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/

export class ZendeskService {
  private readonly _zendeskInstance: any;
  constructor() {
    this._zendeskInstance = (
      window as Window & typeof globalThis & { zE: any }
    ).zE;
  }

  openChat() {
    this._zendeskInstance('messenger', 'open');
  }
  closeChat() {
    this._zendeskInstance('messenger', 'close');
  }
}
