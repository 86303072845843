import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import usePlacesAutocomplete, {
  getGeocode,
  GeocodeResult,
} from 'use-places-autocomplete';

import { GoogleMapsAPIContext } from '../App';
import {
  Cleaner,
  Cross,
  Magnifier,
  MenuArrow,
  Near,
} from '../icons-components/Icons';
import places from '../utils/places';

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

interface ICityProps {
  loaded?: boolean;
  onClose: () => void;
  // onTextFieldChange: (value: string) => void;
  onTextFieldChange: any;
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 0,

            '&.CloseIcon': {
              position: 'absolute',
              top: '16px',
              left: '24px',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.SearchTitle': {
              fontWeight: 800,
              fontSize: '16px',
              lineHeight: '150%',
              textAlign: 'center',
            },
            '&.ListTitle': {
              marginBottom: '16px',
              fontWeight: 800,
              fontSize: '16px',
              lineHeight: '150%',
              [theme.breakpoints.up('lg')]: {
                paddingLeft: '17px',
                marginBottom: '10px',
              },
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: '100%',
            [theme.breakpoints.up('lg')]: {
              marginBottom: '11px',
              width: '297px',
              '&.Reduced': {
                width: '220px',
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'Manrope',
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            color: '#263238',
            caret: '#263238',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            paddingLeft: '16px',
            paddingRight: '16px',
            border: '1px solid #263238',
            borderRadius: '16px',
            zIndex: 10,
          },
          input: {
            padding: '16px 12px 16px 12px',
            [theme.breakpoints.up('lg')]: {
              paddingTop: '21px',
              paddingBottom: '22px',
            },
          },
          notchedOutline: {
            border: 'none',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Near': {
              marginBottom: '32px',
              fontFamily: 'inherit',
              fontStyle: 'normal',
              fontWeight: 800,
              fontSize: '12px',
              lineHeight: 1.5,
              letterSpacing: '0.4px',
              color: '#00838F',
              textTransform: 'none',

              [theme.breakpoints.up('lg')]: {
                marginBottom: '16px',
                marginLeft: '17px',
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: 0,
            whiteSpace: 'normal',
            [theme.breakpoints.up('lg')]: {
              padding: '20px 17px',
              '&:last-of-type': {
                paddingBottom: '17px',
                borderBottomLeftRadius: '20px',
                borderBottomRightRadius: '20px',
              },
              '&+.MuiDivider-root': {
                marginTop: 0,
                marginBottom: 0,
              },
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: 0.2,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            '&.ArrowIcon': {
              minWidth: '20px',
            },
          },
        },
      },
    },
  });
};

// const citiesData = ['Milano', 'Roma', 'Bologna', 'Torino', 'Milano Marittima'];
interface ICitiesData {
  id: string;
  address: string;
  coords: [number, number];
}
export const citiesData: ICitiesData[] = [
  {
    id: 'ChIJ53USP0nBhkcRjQ50xhPN_zw',
    address: 'Milano',
    coords: [45.4642035, 9.189982],
  },
  {
    id: 'ChIJu46S-ZZhLxMROG5lkwZ3D7k',
    address: 'Roma',
    coords: [41.9027835, 12.4963655],
  },
];

// TODO: suggestions loading, get specific city, get its coords

const City = (props: ICityProps): JSX.Element => {
  const { onClose, onTextFieldChange } = props;
  const {
    init,
    // ready,
    value,
    setValue,
    suggestions: { data },
  } = usePlacesAutocomplete({
    requestOptions: {
      types: [
        'route',
        'street_address',
        'locality',
        'administrative_area_level_3',
      ],
      componentRestrictions: { country: 'it' },
    },
  });
  const loaded = useContext(GoogleMapsAPIContext);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    init();
    if (inputRef) {
      inputRef?.current?.focus();
    }
  }, [init, loaded]);

  const isFieldEmpty = value.trim().length === 0;
  // const hints = citiesData.filter((request: string) =>
  //   request.toLowerCase().includes(value.toLowerCase()),
  // );

  const isDesktop = useMediaQuery('(min-width:1200px)');

  // const showDetails = (param: any) => {
  //   return getDetails({ placeId: param }).then(console.log).catch(console.log);
  // };
  const suggsRefs = useRef<HTMLElement[]>([]);

  const handleClick = (suggestion: any) => {
    const {
      place_id,
      description: address,
      structured_formatting: { main_text, secondary_text },
    } = suggestion;

    places.getCoords(address).then((coords: [number, number]) => {
      const payload = {
        id: place_id,
        address: `${main_text} ${secondary_text}`,
        coords,
      };
      onTextFieldChange(payload);
    });
  };

  const nearMe = () => {
    if (!navigator.geolocation) return;

    return navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const {
          coords: { latitude: lat, longitude: lng },
        } = position;

        getGeocode({ location: { lat, lng } })
          .then((res: GeocodeResult[]) => {
            const [coincidence = { place_id: '', formatted_address: '' }] = res;
            const { place_id, formatted_address } = coincidence;
            const payload = {
              id: place_id,
              address: formatted_address,
              coords: [lat, lng],
            };

            onTextFieldChange(payload);
            onClose();
          })
          .catch((error: GeocodeResult) => {});
      },
      (error: GeolocationPositionError) => {},
      {
        enableHighAccuracy: true,
        timeout: 5000,
      },
    );
  };

  const { pathname } = useLocation();
  const altStyles = pathname === '/personal';

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Container className="bare">
        {!isDesktop && (
          <Box sx={{ paddingTop: '16px' }}>
            <IconButton className="CloseIcon" onClick={onClose}>
              <Cross />
            </IconButton>
            <Typography className="SearchTitle">Cerca città</Typography>
          </Box>
        )}
        <Box sx={isDesktop ? { maxWidth: '370px' } : { mt: '28px' }}>
          <TextField
            sx={{
              '& > .MuiOutlinedInput-root': {
                background: '#FFFFFF',
              },
            }}
            className={altStyles ? 'Reduced' : ''}
            type="text"
            inputRef={inputRef}
            onFocus={(event: any) => {
              const length = event?.currentTarget.value.length;
              event?.currentTarget.setSelectionRange(length, length);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Magnifier />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={isFieldEmpty ? { display: 'none' } : {}}
                    onClick={() => setValue('')}
                  >
                    <Cleaner />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'ArrowDown' && value.length > 0) {
                return suggsRefs.current[0].focus();
              }

              if (event.key !== 'Enter') {
                return;
              }

              const main_text = data[0]?.structured_formatting.main_text;
              const secondary_text =
                data[0]?.structured_formatting.secondary_text;
              const hint = secondary_text
                ? `${main_text}, ${secondary_text}`
                : main_text;

              if (value.trim().length === 0) {
                setValue(citiesData[0].address);
                onTextFieldChange(citiesData[0]);
                onClose();
              } else {
                setValue(hint);
                onTextFieldChange(hint);
                onClose();
              }
            }}
          />
          <Box
            sx={{
              background: '#FFFFFF',
              ...(isDesktop
                ? {
                    width: '360px',
                    maxHeight: '330px',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '0px',
                    },
                    border: '1px solid #121A26',
                    borderRadius: '20px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }
                : {}),
            }}
          >
            {isFieldEmpty ? (
              <Box sx={isDesktop ? { pt: '20px' } : { mt: '24px', pb: '16px' }}>
                <Button className="Near" startIcon={<Near />} onClick={nearMe}>
                  Cerca vicino a me
                </Button>
                <Typography className="ListTitle">Le più cercate</Typography>
                <Stack divider={<Divider />}>
                  {citiesData.map((request: any, idx: number) => (
                    <MenuItem
                      key={idx}
                      onClick={() => {
                        setValue(request.address);
                        onTextFieldChange(request);
                        onClose();
                      }}
                    >
                      <ListItemText>{request.address}</ListItemText>
                      <ListItemIcon className="ArrowIcon">
                        <MenuArrow />
                      </ListItemIcon>
                    </MenuItem>
                  ))}
                </Stack>
              </Box>
            ) : (
              <Box
                sx={
                  isDesktop ? {} : { marginTop: '12px', paddingBottom: '16px' }
                }
              >
                <Stack divider={<Divider />}>
                  {data.length === 0 && (
                    <MenuItem disabled>
                      <ListItemText>
                        Nessun esame corrisponde alla ricerca
                      </ListItemText>
                    </MenuItem>
                  )}
                  {data.map((suggestion: any, idx: number) => {
                    console.log(suggestion, 'suggestion');
                    const {
                      place_id,
                      structured_formatting: { main_text, secondary_text },
                    } = suggestion;
                    const hint = secondary_text
                      ? `${main_text}, ${secondary_text}`
                      : main_text;
                    return (
                      <MenuItem
                        ref={(element: any) =>
                          (suggsRefs.current[idx] = element)
                        }
                        onKeyDown={(event: any) => {
                          if (event.key === 'ArrowDown') {
                            if (idx === data.length - 1) {
                              suggsRefs.current[idx].blur();
                              inputRef.current?.focus();
                              return;
                            }
                            suggsRefs.current[idx + 1].focus();
                            return;
                          }
                          if (event.key === 'ArrowUp') {
                            if (idx === 0) {
                              suggsRefs.current[idx].blur();
                              inputRef.current?.focus();
                              inputRef.current?.setSelectionRange(
                                inputRef.current?.value * 2,
                                inputRef.current?.value * 2,
                              );
                              return;
                            }
                            suggsRefs.current[idx - 1].focus();
                            return;
                          }
                          return;
                        }}
                        key={place_id}
                        onClick={() => {
                          setValue(hint);
                          onTextFieldChange(hint);
                          onClose();
                          handleClick(suggestion);
                          // showDetails(place_id);
                        }}
                      >
                        <ListItemText>{hint}</ListItemText>
                        <ListItemIcon className="ArrowIcon">
                          <MenuArrow />
                        </ListItemIcon>
                      </MenuItem>
                    );
                  })}
                </Stack>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default City;
