import React from 'react';
import Typography from '@mui/material/Typography';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch } from '../../../redux/hooks';
import { authOperations } from '../../../redux/slices/auth/operations';
import Styled from '../../Styled';
import Reusable from '../Reusable';
import { IForm } from './Signin.types';

const Signin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function login(values: IForm) {
    const { payload: result } = await dispatch(
      authOperations.login({
        username: values.email,
        password: values.password,
      }),
    );
    if (result) navigate('/search');
  }

  return (
    <Reusable.Wrapper>
      <Typography sx={{ mb: '8px' }} variant="h1">
        Login
      </Typography>
      <Typography sx={{ mb: '47px' }} variant="caption">
        Inserisci le credenziali per accedere
      </Typography>
      <Form
        onSubmit={login}
        render={({ handleSubmit, dirtyFields }) => {
          const hasPristineFields = Object.values(dirtyFields).length < 2;

          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                render={({ input }) => {
                  return (
                    <Styled.Input
                      sx={{ mb: '40px' }}
                      label="Indirizzo mail"
                      InputLabelProps={{ shrink: true, focused: false }}
                      {...input}
                    />
                  );
                }}
              />
              <Field
                name="password"
                render={({ input }) => {
                  return (
                    <Styled.Input
                      sx={{ mb: '8px' }}
                      type="password"
                      label="Password"
                      InputLabelProps={{ shrink: true, focused: false }}
                      {...input}
                    />
                  );
                }}
              />
              <Styled.RRDLink
                className={classNames('retreive-password')}
                to="reset"
              >
                Password dimenticata?
              </Styled.RRDLink>
              <Styled.Button type="submit" disabled={hasPristineFields}>
                Accedi
              </Styled.Button>
            </form>
          );
        }}
      />
    </Reusable.Wrapper>
  );
};

export default Signin;
