import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { useMediaQuery } from '@mui/material';

const Progress = (props: { quantity?: number }) => {
  const { quantity } = props;
  const desktop = useMediaQuery('(min-width:1200px)');

  return (
    <>
      <Stack direction="column" spacing="12px" width={'100%'}>
        {Array.from({ length: quantity || 10 }, (_, i) => i).map(
          (val: number) => (
            <Box
              sx={{
                position: 'relative',
                width: 'auto',
                height: desktop ? '120px' : '150px',
                padding: '16px 16px 8px',
                background: '#F4F8FB',
                borderRadius: '20px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={desktop ? 40 : 36}
                  height={desktop ? 40 : 36}
                  sx={{ borderRadius: '12px' }}
                />
                <Box sx={{ ml: '12px' }}>
                  <Skeleton
                    sx={{
                      marginBottom: '2px',
                      fontSize: '24px',
                    }}
                    width={150}
                  />
                  <Skeleton
                    sx={{ marginBottom: '2px', fontSize: '20px' }}
                    width={170}
                  />
                  {/* {desktop && <Skeleton sx={{ fontSize: '20px' }} width={36} />} */}
                </Box>
                {!desktop && (
                  <Skeleton sx={{ ml: 'auto', fontSize: '20px' }} width={36} />
                )}
              </Box>
              <Stack
                direction="row"
                justifyContent={desktop ? 'start' : 'center'}
                spacing="8px"
                sx={
                  desktop
                    ? {
                        position: 'absolute',
                        bottom: '50%',
                        right: '16px',
                      }
                    : {
                        mt: '12px',
                        mb: '12px',
                      }
                }
              >
                {Array.from({ length: 3 }, (_, i) => i).map((val: number) => (
                  <Skeleton
                    key={val}
                    variant="rectangular"
                    width={96}
                    height={44}
                    sx={{ borderRadius: '6px' }}
                  />
                ))}
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Skeleton
                  sx={{
                    fontSize: '24px',
                    ...(desktop ? { position: 'absolute', bottom: '8px' } : {}),
                  }}
                  width={110}
                />
                <Skeleton
                  sx={{
                    fontSize: '24px',
                    ...(desktop
                      ? {
                          position: 'absolute',
                          bottom: '8px',
                          right: '16px',
                        }
                      : {}),
                  }}
                  width={84}
                />
              </Box>
            </Box>
          ),
        )}
      </Stack>
    </>
  );
};

export default Progress;
