import styled from '@emotion/styled';
import { DialogActions } from '@mui/material';

export const BookingStatusDropdown = styled('div', {
  label: 'BookingStatusDropdown',
})`
  position: fixed;
  z-index: 100;
  background: #ffffff;
  border: 1px solid #121a26;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
`;
export const BookingStatusDropdownOverlay = styled('div', {
  label: 'BookingStatusDropdownOverlay',
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
`;
export const BookingStatusDropdownItem = styled('p', {
  label: 'BookingStatusDropdownItem',
})`
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #e0e0e0;
  }
`;
export const BookingStatusDialogActions = styled(DialogActions, {
  label: 'BookingStatusDialogActions',
})`
  flex-direction: column;
  gap: 10px;
`;
