import React from 'react';

export type TIcons = Record<string, React.ComponentType>;

type TStatus = 'pre' | 'post';

export enum EFeedback {
  Bad,
  MightBeBetter,
  Good,
  Awesome,
}

export interface IFeedbackProps {
  status: TStatus;
  feedback: EFeedback;
  comment?: string;
}
