import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '../../../api';
import { IUser, IUserById } from './types';

const getUser = createAsyncThunk('user/getUser', async () => {
  try {
    const response = await api.get<IUser>('/user');
    return response.data;
  } catch (e) {}
});

const getUserById = createAsyncThunk('user/getUserById', async (id: string) => {
  try {
    const response = await api.get<IUserById>(`/user/${id}`);
    return response.data;
  } catch (e) {}
});

const getUsers = createAsyncThunk('user/getUsers', async () => {
  try {
    const response = await api.get<IUser[]>('/user/multi');
    return response.data;
  } catch (e) {}
});

export const userOperations = { getUser, getUserById, getUsers };
