export enum ApiTypes {
  INITIAL = '',
  MANUAL = 'manual',
  REDIRECT = 'redirect',
  AUTOMATIC = 'automatic',
}

export type Center = {
  id: string;
  name: string;
  address: string;
  test: string;
  label: string;
  coordinates: [number, number];
  apiType?: ApiTypes;
  apiUrl?: string;
  centerName?: string;
  logo?: string;
  chainLabel: string;
};

export type Slot = {
  date: string;
  time: string;
  price: number;
  doctor: string;
};

export type Finished = {
  finished: boolean;
};

export type ISeoCitySlot = {
  center: Center;
  slots: Slot[];
  price: number;
  distance: string;
};

export interface Framed extends ISeoCitySlot {
  frame: {
    morning: number;
    afternoon: number;
    evening: number;
  };
}

export type Availabilities = ISeoCitySlot[];

// Filter types

// redux types

export type Frame = {
  morning: boolean;
  afternoon: boolean;
  evening: boolean;
};

export type Dynamic = Record<string, boolean>;

export type Filter = {
  timeframe: Frame;
  centers: Dynamic;
};

export interface ISeoTest {
  id: string;
  status: string;
  date_created: string;
  date_updated: string;
  name: string;
  shortDescription: string;
  longDescription: string;
  info: string;
  label: string;
}

export interface ISeoCenterTest {
  id: string;
  name: string;
  label: string;
  price: number;
}

export interface IPatient {
  id: string;
  firstName: string;
  lastName: string;
  fiscalCode: string;
  birthDate: string;
  birthPlace: string;
  gender: string;
  self: boolean | null;
}

export type TFindPatients = {
  findPatients: IPatient[] | undefined;
};

export enum EQueryRegime {
  Private = 'Privato',
  PrivateHealthInsurance = 'Assicurazione sanitaria privata',
  NationalHealthInsurance = 'Servizio Sanitario Nazionale',
  NationalHealthInsuranceWithoutReceipt = 'NationalHealthInsuranceWithoutReceipt',
}
export type TQueryRegime = EQueryRegime | null;

export type TPublicSystemTimeSlot = Pick<Slot, 'date' | 'time'> & {
  centerDataAccordingToPS: any;
  publicTest: any;
};

export interface IPublicSystemSlot {
  center: {
    code: string;
    name: string;
    coordinates: [number, number];
    address: string;
    chain: string;
  };
  slots: TPublicSystemTimeSlot[];
  finished?: boolean;
  publicTest: any;
}

export type TWithinPublicSystemTimeSlots = TPublicSystemTimeSlot[] | null;

export type TSlotTreatedAsPublicSystemOne = Slot & { center: Center };
export type TSlotsTreatedAsPublicSystemOnes =
  | TSlotTreatedAsPublicSystemOne[]
  | null;
