import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ReactComponent as Magnifier } from 'icons/tabs/magnifier.svg';
import { ReactComponent as HealthBook } from 'icons/tabs/health-book.svg';
import { ReactComponent as ContactsBook } from 'icons/tabs/contacts-book.svg';
import { ReactComponent as MagnifierSelected } from 'icons/tabs/magnifier-selected.svg';
import { ReactComponent as HealthBookSelected } from 'icons/tabs/health-book-selected.svg';
import { ReactComponent as ContactsBookSelected } from 'icons/tabs/contacts-book-selected.svg';
import paths from 'routes/paths';
import useRouteMatch from 'hooks/useRouteMatch';

const related = {
  search: [
    `/${paths().search}`,
    `/${paths().results}`,
    `/${paths().confirmation}`,
  ],
  bookings: [
    `/${paths().booking}`,
    `/${paths().active}`,
    `/${paths().bookingID}`,
  ],
  registry: [`/${paths().registry}`, `/${paths().patient}`],
};

const TabsNavigation = styled(Tabs)({
  flexShrink: 0,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const TabNavigationItem = styled(Tab)({
  minHeight: 'auto',
  overflow: 'unset',
  padding: '16px 24px',
  alignItems: 'start',
  justifyContent: 'start',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.2px',
  color: '#263238',
  textTransform: 'none',
  '&.Mui-selected': {
    fontWeight: 800,
    color: '#263238',
  },
  '& > .MuiTab-iconWrapper': {
    marginRight: '26px',
  },
}) as any;

const Navigation = () => {
  const routeMatch = useRouteMatch([
    `/${paths().search}`,
    `/${paths().booking}`,
    `/${paths().registry}`,
    `/${paths().results}`,
    `/${paths().confirmation}`,
    `/${paths().active}`,
    `/${paths().patient}`,
    `/${paths().bookingID}`,
  ]);
  const currentTab = routeMatch?.pattern?.path;
  const tabMatch = (
    patterns: readonly string[],
    activeTab: string | undefined,
  ) => patterns.find((val: string) => val === activeTab);

  return (
    <TabsNavigation
      variant="fullWidth"
      orientation="vertical"
      value={currentTab}
    >
      <TabNavigationItem
        label="Nuova ricerca"
        icon={
          currentTab && related.search.includes(currentTab) ? (
            <MagnifierSelected />
          ) : (
            <Magnifier />
          )
        }
        iconPosition="start"
        value={(() => tabMatch(related.search, currentTab))()}
        component={Link}
        to={`/${paths().search}`}
      />
      <TabNavigationItem
        label="Lista prenotazioni"
        icon={
          currentTab && related.bookings.includes(currentTab) ? (
            <HealthBookSelected />
          ) : (
            <HealthBook />
          )
        }
        iconPosition="start"
        value={(() => tabMatch(related.bookings, currentTab))()}
        component={Link}
        to={`/${paths().booking}`}
      />
      <TabNavigationItem
        label="Anagrafica pazienti"
        icon={
          currentTab && related.registry.includes(currentTab) ? (
            <ContactsBookSelected />
          ) : (
            <ContactsBook />
          )
        }
        iconPosition="start"
        value={(() => tabMatch(related.registry, currentTab))()}
        component={Link}
        to={`/${paths().registry}`}
      />
    </TabsNavigation>
  );
};

export default Navigation;
