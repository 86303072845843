import React, { ChangeEvent, FocusEvent, useState } from 'react';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import countries from '../../countries.json';
import { ReactComponent as ChevronIntlTelInput } from '../../icons/chevron-intl-tel-input.svg';

interface IIntlTelInputProps {
  dial?: string;
  value: string;
  onInputChange: (inputValue: string) => void;
  onInputBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onDialCodePass: (code: string) => void;
  styles?: object;
  className?: string;
  helperText?: string;
}

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme(theme, {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: 'none',
          },
          notchedOutline: {
            border: 'none',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            alignItems: 'baseline',
            fontFamily: 'inherit',
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& > p': {
              color: '#263238',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            lineHeight: 1.5,
            letterSpacing: '0.3px',
            color: '#607D8B',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            height: '56px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            boxShadow: 'none',
            border: '1px solid #B0BEC5',
            borderRadius: '16px',
            '&:focus-within': {
              borderColor: '#263238',
            },

            '&.with-error': {
              borderColor: '#D81B60',

              '& .MuiInputLabel-root': {
                color: '#D81B60',
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '16px 36px 16px 0px !important',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            height: '100%',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            position: 'absolute',
            top: '-22px',
            left: '2px',
            fontFamily: 'inherit',
            fontWeight: 800,
            fontSize: '14.25px',
            lineHeight: 1.2,
            letterSpacing: '0.2px',
            color: '#607D8B',
            '&.Mui-focused': {
              color: '#607D8B',
            },
          },
        },
      },
    },
  });
};

const IntlTelInput = (props: IIntlTelInputProps): JSX.Element => {
  const {
    dial,
    value,
    onInputChange,
    onInputBlur,
    onDialCodePass,
    className,
    styles,
    helperText,
  } = props;

  const [code, setCode] = useState(dial || '+39');
  const handleCodeChange = (event: { target: { value: string } }) => {
    setCode(event.target.value);
    onDialCodePass(event.target.value);
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <Paper sx={{ pl: '16px', ...styles }} className={className}>
        <Select
          value={code}
          onChange={handleCodeChange}
          IconComponent={ChevronIntlTelInput}
          MenuProps={{
            PaperProps: { sx: { height: '150px' } },
            disablePortal: true,
          }}
          renderValue={(value: string) => {
            const country = countries.find(
              (country: any) => country.dialCode === value,
            );

            return (
              <img
                style={{ width: '24px', height: '24px' }}
                src={country?.flag}
                alt={`${country?.name}-flag`}
              />
            );
          }}
        >
          {countries.map((country: any) => (
            <MenuItem
              sx={{ display: 'flex', alignItems: 'center' }}
              key={country.isoCode}
              value={country.dialCode}
            >
              <img
                style={{ width: '24px', height: '24px' }}
                src={country.flag}
                alt={`${country.name}-flag`}
              />
              <Typography sx={{ ml: '5px' }}>{country.name}</Typography>
            </MenuItem>
          ))}
        </Select>
        <Divider
          sx={{ height: '24px', marginRight: '12px' }}
          orientation="vertical"
        />
        <InputLabel htmlFor="tel">{'Telefono'}</InputLabel>
        <InputBase
          sx={{ width: '75%' }}
          id="tel"
          startAdornment={
            <InputAdornment position="start">{code}</InputAdornment>
          }
          value={value}
          onChange={e => {
            onInputChange(e.target.value.replace(/[^0-9]/, ''));
          }}
          onBlur={onInputBlur}
          className={className}
        />
      </Paper>
      {helperText && (
        <FormHelperText
          sx={{
            marginLeft: '14px',
            color: '#D81B60',
            fontFamily: "'Manrope', sans-serif",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </ThemeProvider>
  );
};

export default IntlTelInput;
