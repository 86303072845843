import {
  createColumnHelper,
  FilterFns,
  SortingFn,
} from '@tanstack/react-table';

import { Content } from '../DataGrid.styled';
import { PatientInfo } from './Registry.component';
import { IPatientTableRow } from './Registry.types';
import { IUserInfo } from '../Users/Users.types';
import { sortByName } from '../DataGrid.helpers';

const columnHelper = createColumnHelper<IPatientTableRow>();

export const columns = [
  columnHelper.accessor('patientInfo', {
    header: 'Paziente',
    cell: props => <PatientInfo {...props.getValue()} />,
    filterFn: 'withPatient' as keyof FilterFns,
    sortingFn: sortByName('patientInfo') as keyof SortingFn<IUserInfo>,
  }),

  columnHelper.accessor('fiscalCode', {
    header: 'Codice fiscale',
    cell: props => <Content>{props.getValue()}</Content>,
    filterFn: 'includesString',
  }),

  columnHelper.accessor('birthDate', {
    header: 'Data di nascita',
    cell: props => <Content>{props.getValue()}</Content>,
    filterFn: 'includesString',
  }),

  columnHelper.accessor('birthPlace', {
    header: 'Città di nascita',
    cell: props => <Content>{props.getValue()}</Content>,
    filterFn: 'includesString',
  }),

  columnHelper.accessor('gender', {
    header: 'Genere',
    cell: props => <Content>{props.getValue()}</Content>,
    filterFn: 'includesString',
  }),
];
