import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppointmentState {
  date: string;
  time: string;
  price: string;
  staff: string;
  centerID: string;
  center: string;
  address: string;
  centerCoords: string;
}

interface UpdateAppointmentState extends Partial<AppointmentState> {}

const initialState: AppointmentState = {
  date: '',
  time: '',
  price: '',
  staff: '',
  center: '',
  centerID: '',
  address: '',
  centerCoords: '',
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    updateAppointment: (state, action: PayloadAction<UpdateAppointmentState>) =>
      (state = { ...state, ...action.payload }),
  },
});

export const { updateAppointment } = appointmentSlice.actions;

export default appointmentSlice.reducer;
