import React from 'react';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import Styled from 'components/Styled';
import { ReactComponent as Logo } from 'icons/one-check.svg';

import { Section } from './Authorization.styled';

const Authorization = () => {
  return (
    <>
      <Section component="header">
        <Link to="/auth">
          <Logo />
        </Link>
        <Styled.Link href="#" underline="hover">
          Assistenza
        </Styled.Link>
      </Section>
      <Outlet />
    </>
  );
};

export default Authorization;
