import React, { ChangeEvent, FocusEvent, ComponentType } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface IInputProps {
  label: string;
  value?: string;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onInputBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onInputFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  styles?: object;
  className?: string;
  isFocused?: boolean;
  type?: 'text' | 'password';
  Icon?: ComponentType;
  placeholder?: string;
  InputComponent?: any;
  disabled?: boolean;
  onInputClick?: () => void;
  helperText?: string;
}

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

const theme = createTheme();
const getMuiTheme = () => {
  return createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100%',
            '&.Nome input': {
              textTransform: 'capitalize',
            },
            '&.Cognome input': {
              textTransform: 'capitalize',
            },
            '&.Invalid > .MuiOutlinedInput-root': {
              borderColor: '#D81B60',
            },
            '&.Invalid > .MuiFormHelperText-root': {
              color: '#D81B60',
            },
            '&.Invalid > .MuiInputLabel-root': {
              color: '#D81B60',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: 1.5,
            letterSpacing: '0.2px',
            color: '#263238',
            [theme.breakpoints.up('lg')]: {
              fontSize: '14px',
            },
          },
          input: {
            '&.Mui-disabled': {
              color: '#263238',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: '1px solid #B0BEC5',
            borderRadius: '16px',
            '&.Mui-focused': {
              border: '1px solid #263238',
            },
          },
          input: {
            padding: '16.5px 16px 16.5px 20px',
            '&.Mui-disabled': {
              textFillColor: '#263238',
            },
            [theme.breakpoints.up('lg')]: {
              paddingTop: '17px',
              paddingBottom: '17px',

              '&::placeholder': {
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: 1.5,
                letterSpacing: '0.3px',
                color: '#78909C',
              },
            },
          },
          notchedOutline: {
            border: 'none',
            '& > legend': {
              height: 'auto',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            top: 'auto',
            bottom: 'calc(100% + 6px)',
            transform: 'none',
            transformOrigin: 'unset',
            fontWeight: 800,
            fontSize: '14px',
            lineHeight: 1.2,
            color: '#607D8B',
            '&.Mui-focused, &.Mui-disabled': {
              color: '#607D8B',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: "'Manrope', sans-serif",
            color: '#607D8B',
            '&.Mui-disabled': {
              color: '#607D8B',
            },
          },
        },
      },
    },
  });
};

const Input = (props: IInputProps): JSX.Element => {
  const {
    label,
    value,
    onInputChange,
    onInputBlur,
    styles,
    className,
    isFocused,
    type,
    Icon,
    placeholder,
    InputComponent,
    disabled,
    onInputClick,
    onInputFocus,
    helperText,
  } = props;

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <TextField
        type={type || 'text'}
        sx={styles}
        className={className}
        autoFocus={isFocused || false}
        label={label}
        value={value}
        onChange={onInputChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: Icon ? (
            <InputAdornment position="start">
              <Icon />
            </InputAdornment>
          ) : null,
          inputComponent: InputComponent as any,
        }}
        placeholder={placeholder}
        onClick={onInputClick}
        disabled={disabled}
        helperText={helperText || ' '}
      />
    </ThemeProvider>
  );
};

export default Input;
