import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

export const Positioned = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '&:focus-visible': {
    outline: 'none',
  },
});

export const ModalWrapper = styled(Box)({
  boxSizing: 'border-box',
  width: '340px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  background: '#FFFFFF',
  borderRadius: '20px',
  '&.block': {
    display: 'block',
  },
  '&.padding-increased': {
    paddingBottom: '26px',
  },
});

export const CallText = styled(Typography)({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: 1.4,
  letterSpacing: '0.2px',
  color: '#323F4B',
});

export const CaptionText = styled(Typography)({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: 1.5,
  letterSpacing: '0.4px',
  color: '#607D8B',
});
