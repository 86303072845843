import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';

import {
  Cleaner,
  DateIcon,
  Magnifier,
  MapPinColor,
  Pulse,
} from '../icons-components/Icons';
import Test from './Test';
import City from './City';
import { DatePicker } from './DatePickers';
import { MuiTextField } from './Styled';

interface IExpandedState {
  content: null | string;
  isExpanded: boolean;
}

const Search = (props: any): JSX.Element => {
  const [expanded, setExpanded] = useState<IExpandedState>({
    content: null,
    isExpanded: false,
  });

  const {
    test,
    onTestChange,
    passTestID,
    passAnswers,
    passSuggested,
    passPrescription,
    city,
    onCityChange,
    date,
    onDateChange,
    onQuerySubmit,
  } = props;

  const canSubmit = !!test && !!city && !!date;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Paper sx={{ width: '100%' }}>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <MuiTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Pulse />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="SharedCleanerIconButton"
                      sx={test.length === 0 ? { display: 'none' } : {}}
                      onClick={() => onTestChange('')}
                    >
                      <Cleaner />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              placeholder="Visita, esame o trattamento"
              value={test}
              onClick={() => setExpanded({ content: 'test', isExpanded: true })}
              className={expanded.content === 'test' ? 'Invisible' : ''}
            />
            {expanded.isExpanded && expanded.content === 'test' && (
              <ClickAwayListener
                onClickAway={() =>
                  setExpanded({ isExpanded: false, content: null })
                }
              >
                <Box sx={{ position: 'absolute', top: '-1px', left: '-1px' }}>
                  <Test
                    onClose={() =>
                      setExpanded({ content: null, isExpanded: false })
                    }
                    onTextFieldChange={onTestChange}
                    passTestID={passTestID}
                    passAnswers={passAnswers}
                    passSuggested={passSuggested}
                    passPrescription={passPrescription}
                  />
                </Box>
              </ClickAwayListener>
            )}
          </Box>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <MuiTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MapPinColor />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="SharedCleanerIconButton"
                      sx={!!city ? {} : { display: 'none' }}
                      onClick={() => onCityChange('')}
                    >
                      <Cleaner />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              placeholder="Città"
              value={city}
              onClick={() => setExpanded({ content: 'city', isExpanded: true })}
              className={expanded.content === 'city' ? 'Invisible' : ''}
            />
            {expanded.isExpanded && expanded.content === 'city' && (
              <ClickAwayListener
                onClickAway={() =>
                  setExpanded({ isExpanded: false, content: null })
                }
              >
                <Box sx={{ position: 'absolute', top: '-1px', left: '-1px' }}>
                  <City
                    onClose={() =>
                      setExpanded({ content: null, isExpanded: false })
                    }
                    onTextFieldChange={onCityChange}
                  />
                </Box>
              </ClickAwayListener>
            )}
          </Box>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <MuiTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="SharedCleanerIconButton"
                      sx={date.length === 0 ? { display: 'none' } : {}}
                      onClick={() => onDateChange('')}
                    >
                      <Cleaner />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              placeholder="Data"
              value={date}
              onClick={() => setExpanded({ content: 'date', isExpanded: true })}
            />
            {expanded.isExpanded && expanded.content === 'date' && (
              <ClickAwayListener
                onClickAway={() =>
                  setExpanded({ isExpanded: false, content: null })
                }
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 'calc(100% + 11px)',
                    zIndex: 1,
                  }}
                >
                  <DatePicker
                    selected={date}
                    onClose={() =>
                      setExpanded({ content: null, isExpanded: false })
                    }
                    onTextFieldChange={onDateChange}
                  />
                </Box>
              </ClickAwayListener>
            )}
          </Box>
        </Paper>
        <IconButton
          sx={{
            '&.Mui-disabled': {
              background: '#90A4AE',
              borderColor: '#90A4AE',
            },
          }}
          className="DesktopSearchIconButton"
          onClick={onQuerySubmit}
          disabled={!canSubmit}
        >
          <Magnifier />
        </IconButton>
      </Box>
    </>
  );
};

export default Search;
