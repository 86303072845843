import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { Part, Slot, ShowMore } from 'pages/Slots';
import { ReactComponent as Chevron } from 'icons/adornments/chevron.svg';

const usePartialSlotsRender = ({
  label,
  slots,
  onClick,
  cost,
}: {
  label: string;
  slots: any[];
  onClick: any;
  cost: number;
}) => {
  const [displayed, hidden] = slots;
  const total = displayed.length + hidden.length;

  const mayRender = total > 3;
  const [rendered, setRendered] = useState(false);
  const showMoreVisible = mayRender && !rendered;

  const Partial = () => {
    return displayed.length === 0 ? null : (
      <Part label={label}>
        <Stack spacing="12px">
          {displayed.map((slot: any) => (
            <Slot {...{ ...slot, cost, onClick }} />
          ))}
          {showMoreVisible && (
            <ShowMore endIcon={<Chevron />} onClick={() => setRendered(true)}>
              {`Vedi tutti ${total} slot del ${label}`}
            </ShowMore>
          )}
          {rendered &&
            hidden.map((slot: any) => <Slot {...{ ...slot, cost, onClick }} />)}
        </Stack>
      </Part>
    );
  };
  return { Partial };
};

export default usePartialSlotsRender;
