import React, { useState } from 'react';
import { useIMask } from 'react-imask';
import {
  parse,
  startOfToday,
  isValid,
  isBefore,
  startOfTomorrow,
  isAfter,
  addMonths,
  format,
  startOfYesterday,
} from 'date-fns';
import classNames from 'classnames';

import { MuiInput } from '../../MuiStyledComponents';
import { isWeekend } from '../utils';
import { publicHolidays, soonestAvailable } from '../data';
import {
  IProps,
  IValidity,
  EError,
  EDatePickerType,
} from './DatePickerInput.types';

const formatDefaultValue = (defaultValue?: string, type?: EDatePickerType) => {
  if (!defaultValue) return null;
  if (type === EDatePickerType.Birthdate) {
    return format(
      parse(defaultValue, 'dd/MM/yyyy', startOfToday()),
      'dd/MM/yyyy',
    );
  } else {
    if (defaultValue === soonestAvailable) return null;
    return format(
      parse(defaultValue, 'EEEE dd/MM/yy', startOfToday()),
      'dd/MM/yyyy',
    );
  }
};

const DatePickerInput: React.FC<IProps> = ({
  defaultValue,
  passFormattedDate,
  onDatePickerClose,
  type,
}) => {
  const [validity, setValidity] = useState<IValidity>({
    valid: true,
    error: null,
  });

  const checkValidity = (value: string, type?: EDatePickerType) => {
    const parsedDate = parse(value, 'dd/MM/yyyy', startOfToday());

    console.log({ parsedDate, isValid: isValid(parsedDate) }, 'isValid');

    if (!isValid(parsedDate)) {
      setValidity({ valid: false, error: EError.InvalidDate });
      return false;
    }

    if (type === EDatePickerType.Birthdate) {
      if (isAfter(parsedDate, startOfYesterday())) {
        setValidity({ valid: false, error: EError.InvalidBirthdate });
        return false;
      }
    } else {
      if (isBefore(parsedDate, startOfTomorrow())) {
        setValidity({ valid: false, error: EError.BeforeTomorrow });
        return false;
      } else if (isAfter(parsedDate, addMonths(startOfToday(), 6))) {
        setValidity({ valid: false, error: EError.FarOff });
        return false;
      } else if (isWeekend(parsedDate, publicHolidays)) {
        setValidity({ valid: false, error: EError.Weekend });
        return false;
      }
    }

    return true;
  };

  const { ref } = useIMask(
    { mask: '##/##/####', definitions: { '#': /\d/ } },
    {
      onComplete(value: string) {
        const valid = checkValidity(value, type);
        if (!valid) return;
        const parsedDate = parse(value, 'dd/MM/yyyy', startOfToday());
        const formattedDate = format(
          parsedDate,
          type === EDatePickerType.Birthdate ? 'dd/MM/yyyy' : 'EEEE dd/MM/yy',
        );
        if (type === EDatePickerType.Birthdate) {
          // as a form mutator
          // @ts-ignore
          passFormattedDate?.('birthDate', formattedDate);
        } else {
          passFormattedDate?.(formattedDate);
        }
        onDatePickerClose();
      },
    },
  );

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Backspace') return;
    setValidity({ valid: true, error: null });
  };

  return (
    <MuiInput.Primary
      defaultValue={formatDefaultValue(defaultValue, type) ?? ''}
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-input': { WebkitUserSelect: 'none' },
      }}
      className={classNames({ 'with-error': !validity.valid })}
      inputRef={ref}
      placeholder={'DD/MM/YYYY'}
      inputProps={{ inputMode: 'numeric' }}
      onKeyUp={onKeyUp}
      helperText={validity.error}
      autoComplete={'off'}
    />
  );
};

export default DatePickerInput;
