import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import React from 'react';
import classNames from 'classnames';

import { Lightning } from '../../icons-components/Icons';

import { IProps, ECriteria } from './Toggle.types';

const FormControlLabel = styled(MuiFormControlLabel)({
  margin: 0,
  gap: '8px',
  '& > .MuiFormControlLabel-label': {
    fontFamily: "'Manrope', sans-serif",
    fontSize: '12px',
    lineHeight: 1.5,
    letterSpacing: '0.4px',
    color: '#607D8B',
  },
  '&.chain-type-switch > .MuiFormControlLabel-label': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    color: '#7986CB',
    '& > svg': {
      flexShrink: 0,
      marginRight: '4px',
    },
  },
});

const Switch = styled(MuiSwitch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#00838F',
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#00838F',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#CFD8DC',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  '.MuiFormControlLabel-root.chain-type-switch > &': {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: '#7986CB',
        },
      },
    },
  },
}));

const Toggle = ({ label, checked, onChange, criteria }: IProps) => {
  const chainTypeSwitch = criteria === ECriteria.ChainType;
  return (
    <FormControlLabel
      className={classNames({ 'chain-type-switch': chainTypeSwitch })}
      control={<Switch checked={checked} onChange={onChange} />}
      label={
        chainTypeSwitch ? (
          <>
            <Lightning />
            {label}
          </>
        ) : (
          label
        )
      }
    />
  );
};

export default Toggle;
