import { parse, isEqual, isSunday } from 'date-fns';

import { dateFormats } from './data';

export const isPublicHoliday = (publicHolidays: string[], date: Date) =>
  Boolean(
    publicHolidays
      .map((publicHoliday: string) =>
        parse(publicHoliday, dateFormats.publicHoliday, new Date()),
      )
      .find((publicHoliday: Date) => isEqual(publicHoliday, date)),
  );

export const isWeekend = (date: Date, publicHolidays: string[]) =>
  isSunday(date) || isPublicHoliday(publicHolidays, date);
