import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import tests from './tests/slice';
import appointment from './appointment/appointmentSlice';
import map from './map/slice';
import filter from './filter/slice';
import queryReducer from './query/querySlice';
import patient from './patient';
import entry from './entry/entry';

import { authReducer } from './slices/auth/slice';
import { userReducer } from './slices/user/slice';

export const rootReducer = combineReducers({
  tests,
  query: queryReducer,
  appointment,
  map,
  filter,
  patient,
  auth: authReducer,
  entry,
  user: userReducer,
});
export const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['query', 'entry', 'appointment'],
};
export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
